<img src="https://www.srdc.com.tr/wp-content/uploads/2014/12/srdc-wp.png" id="imgSRDC">

<div id="updatePassForm">
    <form class="ui form" xmlns="">
        <div class="field">
            <div *ngIf="differentPassword" class="field"><span style="color: red"><i class="exclamation icon reqIcon"></i>Yeni şifrenizi onaylayamadınız.</span></div>
            <label>Yeni Şifrenizi Giriniz<span *ngIf="(!password.new || !password.verify)"><i class="exclamation icon reqIcon"></i></span></label>
            <div class="field">
                <div class="field">
                    <input type="password" [(ngModel)]="password.new" name="newPassword" placeholder="Yeni şifre">
                </div>
                <div class="field">
                    <input type="password" [(ngModel)]="password.verify" name="newPasswordVerify" placeholder="Yeni şifre (tekrar)">
                </div>
                <button id="updatePassButton" (click)="onSubmit()" class="ui right floated button">Guncelle</button>

            </div>
        </div>
    </form>
</div>
