import {Component, OnInit} from '@angular/core';
import {first} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../shared/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {EmployeeService} from '../shared/services/employee.service';
import {AlertUtil} from '../util/AlertUtil';


@Component({
    selector: 'app-login',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    submitted = false;
    wrongEmail = false;
    _destroyed$ = new Subject();

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private employeeService: EmployeeService
    ) {
    }

    ngOnInit() {
        this.forgotPasswordForm = this.formBuilder.group({
            workEmail: ['', Validators.required],
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.forgotPasswordForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            return;
        }
        this.employeeService.forgotPassword(this.f.workEmail).pipe(
            first()
        ).subscribe(response => {
            if (response.success) {
                AlertUtil.showInfo(this.f.workEmail.value + ' adresine şifre yenileme maili gönderildi', 'Mail Gönderildi');
                this.router.navigate(['/login']);
            }
        });
    }

}
