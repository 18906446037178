import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthenticationService} from './services/authentication.service';
import {AlertUtil} from '../util/AlertUtil';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.error && err.error.message && err.error.name) { // Developer defined error
                AlertUtil.showError(err.error.message, err.error.name);
            } else if (err instanceof HttpErrorResponse) {
                AlertUtil.showError(err.message, err.name);
            } else { // Unknown error
                AlertUtil.showError(undefined, undefined, AlertUtil.TIME_SHORT);
            }
            if (err.status === 401 && !this.authenticationService.passwordChanging()) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
