import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PayrollService = /** @class */ (function () {
    function PayrollService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/payroll';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    PayrollService.prototype.getSalaryPaymentPayrolls = function (query) {
        var URL = this.URL + '/salary-payment';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    PayrollService.prototype.getPayrollDates = function () {
        var URL = this.URL + '/payroll-dates';
        return this.http.get(URL, { headers: this.headers });
    };
    PayrollService.prototype.besWordDocument = function (talimat) {
        var URL = this.URL + '/bes';
        return this.http.post(URL, talimat, { headers: this.headers, responseType: 'blob' });
    };
    PayrollService.prototype.getPeriodicReportPayrolls = function () {
        var URL = this.URL + '/periodic-report';
        return this.http.get(URL, { headers: this.headers });
    };
    PayrollService.prototype.getCostStatementPayrolls = function (query) {
        var URL = this.URL + '/cost';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    PayrollService.prototype.getPayrollsWithID = function (id) {
        var URL = this.URL + '/' + id;
        return this.http.get(URL, { headers: this.headers });
    };
    PayrollService.prototype.getPreviousPayroll = function (date) {
        var URL = this.URL + '/previous/' + date;
        return this.http.get(URL, { headers: this.headers });
    };
    PayrollService.prototype.addPayroll = function (payroll) {
        var URL = this.URL + '/add';
        return this.http.post(URL, payroll, { headers: this.headers });
    };
    PayrollService.prototype.updatePayroll = function (payroll) {
        var URL = this.URL + '/' + payroll.id;
        return this.http.put(URL, payroll, { headers: this.headers });
    };
    PayrollService.prototype.deletePayroll = function (id) {
        var URL = this.URL + '/' + id;
        return this.http.delete(URL, { headers: this.headers });
    };
    PayrollService.prototype.getActivityTrackingPayrolls = function (query) {
        return this.http.get(this.URL, { headers: this.headers, params: query });
    };
    PayrollService.prototype.getHomePayrolls = function (query) {
        var URL = this.URL + '/home';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    PayrollService.ngInjectableDef = i0.defineInjectable({ factory: function PayrollService_Factory() { return new PayrollService(i0.inject(i1.HttpClient)); }, token: PayrollService, providedIn: "root" });
    return PayrollService;
}());
export { PayrollService };
