import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthenticationService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/employee';
    private changingPassword = false;
    private URL = this.serverApi + this.serverPart + '/authenticate';

    login(citizenID: string, password: string) {
        this.changingPassword = false;
        return this.http.post<any>(this.URL, {citizenID: citizenID, password: password})
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }
                return user;
            }));
    }

    verifyPassword(citizenID: string, password: string) {
        this.changingPassword = true;
        return this.http.post<any>(this.URL, {citizenID: citizenID, password: password})
            .pipe(map(user => {
                return user;
            }));
    }

    verifyToken(token: string) {
        const URL = this.URL + '/tokenValidation/' + token;
        return this.http.get<any>(URL);
    }

    resetPassword(token: string, password: string) {
        const URL = this.URL + '/tokenValidation/';
        return this.http.post<any>(URL, {token: token, pass: password});
    }

    passwordChanging() {
        return this.changingPassword;
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentUserID');
        localStorage.removeItem('currentUserName');
        localStorage.removeItem('currentUserObjectID');
        localStorage.removeItem('permissions');
    }

}
