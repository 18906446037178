import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserAuthorizationService = /** @class */ (function () {
    function UserAuthorizationService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/user-authorization';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    UserAuthorizationService.prototype.getAllAuthorizations = function () {
        return this.http.get(this.URL, { headers: this.headers });
    };
    UserAuthorizationService.prototype.getEmployeePermissions = function (citizenID) {
        var URL = this.URL + '/employee/' + citizenID;
        return this.http.get(URL, { headers: this.headers });
    };
    UserAuthorizationService.prototype.updateAuthorization = function (auth) {
        var URL = this.URL + '/' + auth.component;
        return this.http.put(URL, auth, { headers: this.headers });
    };
    UserAuthorizationService.ngInjectableDef = i0.defineInjectable({ factory: function UserAuthorizationService_Factory() { return new UserAuthorizationService(i0.inject(i1.HttpClient)); }, token: UserAuthorizationService, providedIn: "root" });
    return UserAuthorizationService;
}());
export { UserAuthorizationService };
