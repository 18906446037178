import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InhouseReportService = /** @class */ (function () {
    function InhouseReportService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/monthlyCostTableForAccountantByEmployee';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    InhouseReportService.prototype.getAllReports = function (query) {
        var URL = this.URL + '/inhouseReport';
        if (query) {
            return this.http.get(URL, {
                headers: this.headers,
                params: { year: query.year, month: query.month }
            });
        }
        else {
            return this.http.get(URL, { headers: this.headers });
        }
    };
    InhouseReportService.prototype.getOpenPeriod = function () {
        var URL = this.URL + '/currentPeriod';
        return this.http.get(URL, { headers: this.headers });
    };
    InhouseReportService.prototype.getAllPeriods = function (query) {
        var URL = this.URL + '/all-periods';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InhouseReportService.prototype.getPeriodWithYear = function (year) {
        var URL = this.URL + '/' + year;
        return this.http.get(URL, { headers: this.headers });
    };
    InhouseReportService.prototype.getPeriodWithDate = function (year, month) {
        var URL = this.URL + '/' + year + '/' + month;
        return this.http.get(URL, { headers: this.headers });
    };
    InhouseReportService.prototype.updatePeriod = function (invoice, index, oldInvoice, payerEmployeeID) {
        var URL = this.URL + '/costAdd';
        return this.http.put(URL, { invoice: invoice, isUpdate: index, oldInvoice: oldInvoice, payerEmployeeID: payerEmployeeID }, { headers: this.headers });
    };
    InhouseReportService.prototype.deletePayment = function (id) {
        var URL = this.URL + '/paymentDelete/' + id;
        return this.http.delete(URL, { headers: this.headers });
    };
    InhouseReportService.ngInjectableDef = i0.defineInjectable({ factory: function InhouseReportService_Factory() { return new InhouseReportService(i0.inject(i1.HttpClient)); }, token: InhouseReportService, providedIn: "root" });
    return InhouseReportService;
}());
export { InhouseReportService };
