<span style="font-weight: bold">TCMB kurları:</span>
<table class="ui very basic collapsing celled table" style="margin: 5px auto auto auto">
    <tbody>
    <tr *ngIf="loading">
        <div class="ui active centered inline loader"></div>
    </tr>
    <tr *ngIf="!loading">
        <ng-container *ngFor="let item of currentExchangeRatesGUI">
            <td>
                <h4 class="ui header">
                    <div class="content">
                        <i class="sign icon"
                           [ngClass]="{
                  'euro': item.currency === 'EURO',
                  'usd': item.currency === 'USD'}"></i>
                    </div>
                </h4>
                {{utilsService.formatDouble(item.rate, 4)}}
            </td>
        </ng-container>
    </tr>
    </tbody>
</table>

