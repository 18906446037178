import {Injectable} from '@angular/core';
import {verify} from 'jsonwebtoken';
import {AuthenticationService} from './authentication.service';
import {EmployeeService} from './employee.service';
import {environment} from '../../../environments/environment';
import {MenuControllerService} from './menu-controller.service';
import {UtilsService} from './utils.service';
import {UserAuthorizationService} from './user-authorization.service';

@Injectable()
export class AuthorizationService {
    currentUserName = '';
    currentUserType = '';

    constructor(private authenticationService: AuthenticationService, private employeeService: EmployeeService,
                private menuControllerService: MenuControllerService, private utilsService: UtilsService,
                private userAuthorizationService: UserAuthorizationService) {
    }

    public isAuthenticated(component: string): Promise<boolean> {
        const token = localStorage.getItem('currentUser');
        if (!token) {

            return new Promise((resolve) => {
                resolve(false);
            });
        } else {

            const jsonWithToken = JSON.parse(token);
            try {
                const decoded = verify(jsonWithToken.token, environment.auth.secret);
                const currentUserID = decoded['id'];
                const currentUserType = decoded['type'];
                const permissions = localStorage.getItem('permissions');
                localStorage.setItem('currentUserID', currentUserID);
                this.menuControllerService.setCurrentUserType(currentUserType);
                this.menuControllerService.setMenuByAuthorization();
                this.currentUserType = currentUserType;
                this.currentUserName = localStorage.getItem('currentUserName');

                if (!this.currentUserName) {

                    this.employeeService.getCurrentUserSummary().subscribe(
                        employee => {
                            this.currentUserName = this.utilsService.getPersonFullName(employee.person);
                            localStorage.setItem('currentUserName', this.currentUserName);
                            localStorage.setItem('currentUserObjectID', employee._id);
                        }
                    );
                }
                if (!permissions) {

                    return this.userAuthorizationService.getEmployeePermissions(currentUserID)
                        .toPromise()
                        .then(perms => {
                            localStorage.setItem('permissions', perms.token);
                            const decodedPerms = verify(perms.token, environment.auth.secret);
                            return decodedPerms[component];
                        }
                    );
                } else {

                    const decodedPermissions = verify(permissions, environment.auth.secret);
                    return new Promise((resolve) => {
                        resolve(decodedPermissions[component]);
                    });
                }
            } catch (err) {

                this.authenticationService.logout();
                return new Promise((resolve) => {
                    resolve(false);
                });
            }
        }
    }

}
