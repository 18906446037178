import {Directive, ElementRef, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';

declare const $: any;

@Directive({
    selector: '[appModal]',
    exportAs: 'modal'
})
export class ModalDirective implements OnInit, OnDestroy {

    constructor(private el: ElementRef, private _viewContainerRef: ViewContainerRef) {
    }

    ngOnInit(): void {
        (<any>$(this.el.nativeElement)).modal();
    }

    show() {
        (<any>$(this.el.nativeElement))
            .modal('setting', 'closable', false)
            .modal('show');
    }

    settings(setting: string, value: any) {
        (<any>$(this.el.nativeElement)).modal('setting', setting, value);
    }

    refresh() {
        setTimeout(() => {
            (<any>$(this.el.nativeElement)).modal('refresh');
        }, 0);
    }

    hide() {
        setTimeout(() => {
            (<any>$(this.el.nativeElement)).modal('hide');
        }, 0);
    }

    close() {
        (<any>$(this.el.nativeElement)).modal('hide');
    }

    ngOnDestroy() {
        this.close();
    }

}
