import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaymentService = /** @class */ (function () {
    function PaymentService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/payment';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    PaymentService.prototype.getStatePayments = function () {
        var URL = this.URL + '/state';
        return this.http.get(URL, { headers: this.headers });
    };
    PaymentService.prototype.getCostItemPayments = function () {
        var URL = this.URL + '/cost-item';
        return this.http.get(URL, { headers: this.headers });
    };
    PaymentService.prototype.getAdvanceClosurePayments = function (paymentIds) {
        var URL = this.URL + '/advance-closure';
        return this.http.get(URL, { headers: this.headers, params: { id: paymentIds.map(function (i) { return i.toString(10); }) } });
    };
    PaymentService.prototype.getEarliestIncomePayments = function () {
        var URL = this.URL + '/earliest-income';
        return this.http.get(URL, { headers: this.headers });
    };
    PaymentService.prototype.getHomeIncomePayments = function (query) {
        var URL = this.URL + '/income';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    PaymentService.prototype.getEarliestOutcomePayments = function () {
        var URL = this.URL + '/earliest-outcome';
        return this.http.get(URL, { headers: this.headers });
    };
    PaymentService.prototype.getHomeOutcomePayments = function (query) {
        var URL = this.URL + '/outcome';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    PaymentService.prototype.getPaymentWithID = function (id) {
        var URL = this.URL + '/' + id;
        return this.http.get(URL, { headers: this.headers });
    };
    PaymentService.prototype.getMaxPaymentID = function () {
        var URL = this.URL + '/getMaxId';
        return this.http.post(URL, { headers: this.headers });
    };
    PaymentService.prototype.addPayment = function (payment, query) {
        var URL = this.URL + '/add';
        return this.http.post(URL, payment, { headers: this.headers, params: query });
    };
    PaymentService.prototype.updatePayment = function (payment) {
        var URL = this.URL + '/' + payment.id;
        return this.http.put(URL, payment, { headers: this.headers });
    };
    PaymentService.prototype.deletePayment = function (id) {
        var URL = this.URL + '/' + id;
        return this.http.delete(URL, { headers: this.headers });
    };
    PaymentService.prototype.getPaymentsWithQuery = function (query) {
        return this.http.get(this.URL, { headers: this.headers, params: query });
    };
    PaymentService.prototype.pay = function (talimat) {
        var URL = this.URL + '/pay';
        return this.http.post(URL, talimat, { headers: this.headers, responseType: 'blob' });
    };
    PaymentService.prototype.getABProjectIncomes = function (query) {
        var URL = this.URL + '/ab-project-income';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    PaymentService.prototype.getABProjectIncomesSize = function (query) {
        var URL = this.URL + '/ab-project-income/size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    PaymentService.prototype.addIncome = function (data) {
        var URL = this.URL + '/addFonIncome';
        return this.http.post(URL, data, { headers: this.headers });
    };
    PaymentService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.inject(i1.HttpClient)); }, token: PaymentService, providedIn: "root" });
    return PaymentService;
}());
export { PaymentService };
