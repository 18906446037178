/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bank-account-balance.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./bank-account-balance.component";
import * as i4 from "../shared/services/organization.service";
import * as i5 from "../shared/services/exchange-rate.service";
import * as i6 from "../shared/services/utils.service";
var styles_BankAccountBalanceComponent = [i0.styles];
var RenderType_BankAccountBalanceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BankAccountBalanceComponent, data: { "animation": [{ type: 7, name: "simpleFadeAnimation", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, background: "#fbfbfb" }, offset: null }, { type: 4, styles: null, timings: 750 }], options: null }], options: {} }] } });
export { RenderType_BankAccountBalanceComponent as RenderType_BankAccountBalanceComponent };
function View_BankAccountBalanceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "ui active centered inline loader"]], null, null, null, null, null))], null, null); }
function View_BankAccountBalanceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h4", [["class", "ui header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "i", [["class", "sign icon"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "lira": 0, "euro": 1, "usd": 2 })], function (_ck, _v) { var currVal_2 = "sign icon"; var currVal_3 = _ck(_v, 9, 0, (_v.context.$implicit.currency === "TL"), (_v.context.$implicit.currency === "EURO"), (_v.context.$implicit.currency === "USD")); _ck(_v, 8, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.currency; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.utilsService.formatDouble(_v.context.$implicit.amount, 2); _ck(_v, 6, 0, currVal_1); }); }
function View_BankAccountBalanceComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], [[24, "@simpleFadeAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "i", [["class", "sign icon"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "lira": 0, "euro": 1, "usd": 2 })], function (_ck, _v) { var currVal_2 = "sign icon"; var currVal_3 = _ck(_v, 5, 0, (_v.context.$implicit.currency === "TL"), (_v.context.$implicit.currency === "EURO"), (_v.context.$implicit.currency === "USD")); _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "in"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.utilsService.formatDouble(_v.context.$implicit.amount, 2); _ck(_v, 2, 0, currVal_1); }); }
function View_BankAccountBalanceComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "ui header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" TOPLAM "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BankAccountBalanceComponent_5)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(6, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 6, 0, _co.accountToShow); _ck(_v, 5, 0, currVal_0); }, null); }
function View_BankAccountBalanceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BankAccountBalanceComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BankAccountBalanceComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bankAccountBalancesGUI; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.accountToShow; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BankAccountBalanceComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["style", "font-weight: bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵted(-1, null, [" tarihi itibariyle banka hesaplar\u0131ndaki toplam tutarlar:\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "table", [["class", "ui very basic collapsing celled table"], ["style", "margin: 5px auto auto auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BankAccountBalanceComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BankAccountBalanceComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.loading; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), _co.maxDate, "dd/MM/yyyy")); _ck(_v, 2, 0, currVal_0); }); }
export function View_BankAccountBalanceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bank-account-balance", [], null, null, null, View_BankAccountBalanceComponent_0, RenderType_BankAccountBalanceComponent)), i1.ɵdid(1, 245760, null, 0, i3.BankAccountBalanceComponent, [i4.OrganizationService, i5.ExchangeRateService, i6.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BankAccountBalanceComponentNgFactory = i1.ɵccf("app-bank-account-balance", i3.BankAccountBalanceComponent, View_BankAccountBalanceComponent_Host_0, {}, {}, []);
export { BankAccountBalanceComponentNgFactory as BankAccountBalanceComponentNgFactory };
