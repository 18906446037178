import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ExchangeRateService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/exchange-rate';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    getRate(date: string, currency: string) {
        const query = {date: date, currency: currency};
        return this.http.get<{ exchangeRate: number }>(this.URL, {headers: this.headers, params: query});
    }

}

