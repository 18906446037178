import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MonthlyCostTableForAccountantByEmployee} from '../../../models/monthlyCostTableForAccountantByEmployee';
import {environment} from '../../../environments/environment';
import {Invoice} from '../../../models/invoice';

@Injectable({
    providedIn: 'root'
})
export class InhouseReportService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/monthlyCostTableForAccountantByEmployee';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getAllReports(query?: any) {
        const URL = this.URL + '/inhouseReport';
        if (query) {
            return this.http.get<MonthlyCostTableForAccountantByEmployee[]>(URL, {
                headers: this.headers,
                params: {year: query.year, month: query.month}
            });
        } else {
            return this.http.get<MonthlyCostTableForAccountantByEmployee[]>(URL, {headers: this.headers});
        }
    }

    public getOpenPeriod() {
        const URL = this.URL + '/currentPeriod';
        return this.http.get<MonthlyCostTableForAccountantByEmployee>(URL, {headers: this.headers});
    }

    public getAllPeriods(query: any) {
        const URL = this.URL + '/all-periods';
        return this.http.get<MonthlyCostTableForAccountantByEmployee[]>(URL, {headers: this.headers, params: query});
    }

    public getPeriodWithYear(year: number) {
        const URL = this.URL + '/' + year;
        return this.http.get<MonthlyCostTableForAccountantByEmployee[]>(URL, {headers: this.headers});
    }

    public getPeriodWithDate(year: number, month: number) {
        const URL = this.URL + '/' + year + '/' + month;
        return this.http.get<MonthlyCostTableForAccountantByEmployee>(URL, {headers: this.headers});
    }

    public updatePeriod(invoice: Invoice, index: number, oldInvoice: Invoice, payerEmployeeID: string) {
        const URL = this.URL + '/costAdd';
        return this.http.put(URL, {invoice: invoice, isUpdate: index, oldInvoice: oldInvoice, payerEmployeeID: payerEmployeeID}, {headers: this.headers});
    }

    public deletePayment(id: number) {
        const URL = this.URL + '/paymentDelete/' + id;
        return this.http.delete(URL, {headers: this.headers});
    }

}



