import {Project} from './project';
import {Employee} from './employee';
import {Organization} from './organization';
import {Amount} from './amount';
import {Invoice} from './invoice';
import {Proposal} from './proposal';

export class Payment {
    id: number;
    date: Date;
    exchangeRate: number;
    payerOrganization: Organization;
    payerEmployee: Employee;
    amount: Amount;
    payeeOrganization: Organization;
    payeeEmployee: Employee;
    context: Payment.Context;
    paymentType: Payment.PaymentType;
    isIncoming: boolean;
    project: Project;
    invoiceId: Invoice;
    proposal: Proposal;
    isProject: boolean;

    constructor() {
        this.id = 1;
        this.date = new Date();
        this.exchangeRate = 5;
        this.amount = new Amount();
        this.isIncoming = true;
    }
}

export namespace Payment {
    export enum Context {
        PROJECT_ADVANCE = 'Project Advance',
        COST_STATEMENT = 'Cost Statement',
        INVOICE = 'Invoice',
        PERIODIC_REPORT = 'Periodic Report',
        PRIZE = 'Prize',
        TRAVEL = 'Travel',
        OTHER = 'Other'
    }

    export enum PaymentType {
        SALARY = 'Salary',
        BES = 'BES',
        TRAVEL = 'Travel',
        TRAVEL_KAPAMA = 'Travel Kapama',
        INVOICE = 'Invoice',
        KDV = 'KDV',
        MUHTASAR = 'Muhtasar',
        SGK5510 = 'SGK5510',
        SGK5746 = 'SGK5746',
        GECICI_VERGI = 'Gecici Vergi',
        KURUMLAR_VERGISI = 'Kurumlar Vergisi',
        PROJECT_PAYMENT = 'Project Payment',
        REIMBURSEMENT = 'Reimbursement',
        PRIZE = 'Prize',
        OTHER = 'Other'
    }
}
