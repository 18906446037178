import {Amount} from './amount';

export class TimeSeries {
    _id: string;
    date: Date;
    amount: Amount;
    description: string;

    constructor() {
        this.date = new Date();
        this.amount = new Amount();
    }
}
