<!-- LINE CHART MODAL -->
<div appModal #lineChartModal=modal class="ui fullscreen modal">
    <div class="header">
        <b>{{lineChartHeader}}</b>
        <i (click)="lineChartModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <div style="overflow: hidden;">
            <div *ngIf="isOptionable" style="margin: 0 0 2%; width: 50%; float: left;">
                <select (change)="filterData($event.target.value, -1)" class="ui fluid dropdown">
                    <option *ngFor="let item of chartDetailingOptions; index as i" [value]="i" [selected]="item === selectedOption">{{item}}</option>
                </select>
            </div>
        </div>
        <div>
            <dx-chart
                id="chart"
                [dataSource]="lineChartData">
                <dxi-series
                    valueField="amount"
                    [name]="lineChartName"
                    [color]="getLineColor()">
                </dxi-series>
                <dxo-common-series-settings #seriesSettings
                                            argumentField="date"
                                            type="line">
                </dxo-common-series-settings>
                <dxo-margin [bottom]="20"></dxo-margin>
                <dxo-argument-axis
                    [valueMarginsEnabled]="false"
                    discreteAxisDivisionMode="crossLabels">
                    <dxo-grid [visible]="true"></dxo-grid>
                </dxo-argument-axis>
                <dxo-legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    itemTextPosition="bottom">
                </dxo-legend>
                <dxo-label
                    [visible]="true"
                    [customizeText]="getLineChartLabel">
                    <dxo-connector [visible]="false"></dxo-connector>
                </dxo-label>
                <dxo-tooltip
                    [enabled]="true"
                    [zIndex]="10000"
                    [customizeTooltip]="customizeTooltip">
                </dxo-tooltip>
            </dx-chart>
        </div>
    </div>
</div>

<!-- INVOICE DETAIL MODAL -->
<div appModal #incomeOutcomeDetailModal=modal class="ui modal">
    <div class="header">
        <b>{{chartType}}</b>
        <i (click)="incomeOutcomeDetailModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <table *ngIf="detailModal === 'income' && detailedIncomeData.length" class="ui celled table">
            <thead>
            <tr>
                <th>{{getFirstColumn(detailedIncomeData[0].paymentType)}}</th>
                <th>Tarih</th>
                <th>Tutar</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of detailedIncomeData">
                <td>{{getDescription(item)}}</td>
                <td>{{item.date | date: 'dd/MM/yyyy'}}</td>
                <td>{{utilsService.formatDouble(item.amount.value,2)}} {{item.amount.currency}}</td>
            </tr>
            </tbody>
        </table>
        <table *ngIf="detailModal === 'outcome' && detailedOutcomeData" class="ui celled table">
            <thead>
            <tr>
                <th style="width: 40%">{{getFirstColumn(detailedOutcomeData[0].paymentType)}}</th>
                <th *ngIf="detailedOutcomeData[0].paymentType === 'Travel'">Tür</th>
                <th>Tarih</th>
                <th>Tutar</th>
                <th *ngIf="detailedOutcomeData[0].paymentType === 'Inhouse'">Çalışan</th>
                <th *ngIf="detailedOutcomeData[0].paymentType === 'Other'">Ödenen</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of detailedOutcomeData">
                <td>{{getDescription(item)}}</td>
                <td *ngIf="item.paymentType === 'Travel'">{{item.advanceType}}</td>
                <td *ngIf="item.paymentType !== 'Travel' && item.paymentType !== 'Salary'">{{item.date | date: 'dd/MM/yyyy'}}</td>
                <td *ngIf="item.paymentType === 'Travel'">{{item.travelDates[0] | date: 'dd/MM/yyyy'}} - {{item.travelDates[1] | date: 'dd/MM/yyyy'}}</td>
                <td *ngIf="item.paymentType === 'Salary'">{{item.month}} {{item.year}}</td>
                <td>{{utilsService.formatDouble(item.amount.value,2)}} {{item.amount.currency}}</td>
                <td *ngIf="item.paymentType === 'Inhouse'">{{utilsService.getPersonFullName(item.payerEmployee.person)}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- INCOME OUTCOME DEMONSTRATION -->
<div class="container padded" style="margin-top: 61px;">
    <div class="row">
        <div class="ui grid one column padded">
            <div class="column" style="padding-bottom: 0">
                <div class='parent'>
                    <div *ngIf="authorizationService.currentUserType === 'admin'" class='child inline-block-child' style="margin-left: 35%; text-align: center">
                        <app-bank-account-balance></app-bank-account-balance>
                    </div>
                    <div class='child inline-block-child' style="float: right; margin-right: 1%; text-align: center">
                        <app-current-exchange-rates></app-current-exchange-rates>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="ui fluid card">
                    <div class="content">
                        <h2 class="ui header">
                            {{getPieChartTitle(true)}}
                        </h2>
                    </div>
                    <div class="content">
                        <div class="ui menu">
                            <a class="item" (click)="viewIsSet(true, 'month')" [ngClass]="{'active': viewIncome === 'month'}" >
                                Aylık
                            </a>
                            <a class="item" (click)="viewIsSet(true, 'year')" [ngClass]="{'active': viewIncome === 'year'}" >
                                Yıllık
                            </a>
                            <a class="item" (click)="viewIsSet(true, 'all')" [ngClass]="{'active': viewIncome === 'all'}" >
                                Tüm Zamanlar
                            </a>
                            <div class="right menu">
                                <div class="item">
                                    <div class="inline fields">
                                        <div class="field">
                                            <div class="ui radio checkbox">
                                                <input [(ngModel)]="currencyIncome" [ngModelOptions]="{standalone: true}" (change)="generateData(true)" name="currencyIncome" value='EURO' type="radio">
                                                <label>EURO</label>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="ui radio checkbox">
                                                <input [(ngModel)]="currencyIncome" [ngModelOptions]="{standalone: true}" (change)="generateData(true)" name="currencyIncome"  value='TL' type="radio">
                                                <label>TL</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="viewIncome !== 'all'" class="item">
                                    <div class="content">
                                        <div *ngIf="viewIncome == 'month'" class="field">
                                            <select (change)="loadIncomes()" [(ngModel)]="incomeMonthStart" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                <option *ngFor="let month of TRMonths" [ngValue]="month.value" name="incomeMonthStart">
                                                    {{month.month}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="field">
                                            <select (change)="loadIncomes()" [(ngModel)]="incomeYearStart" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                <option *ngFor="let year of yearsOfIncomes" [ngValue]=year name="incomeYearStart">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="content icon">
                                        <i class="minus icon" style="margin-left:0.25rem"></i>
                                    </div>
                                    <div class="content">
                                        <div *ngIf="viewIncome == 'month'" class="field">
                                            <select (change)="loadIncomes()" [(ngModel)]="incomeMonthEnd" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                <option *ngFor="let month of TRMonths" [ngValue]="month.value" name="incomeMonthEnd">
                                                    {{month.month}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="field">
                                            <select (change)="loadIncomes()" [(ngModel)]="incomeYearEnd" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                <option *ngFor="let year of yearsOfIncomes" [ngValue]=year name="incomeYearEnd">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="incomePieData.length && requestCameIncome">
                            <dx-pie-chart
                                id="incomePie"
                                [title]="getTotal(true)"
                                palette="bright"
                                [dataSource]="incomePieData"
                                [resolveLabelOverlapping]="'shift'"
                                (onPointClick)="incomePointClickHandler($event)"
                                (onLegendClick)="incomeLegendClickHandler($event)">
                                <dxo-legend
                                    orientation="vertical"
                                    itemTextPosition="right"
                                    horizontalAlignment="right"
                                    verticalAlignment="top"
                                    [columnCount]="1">
                                    <dxo-font [size]="16"></dxo-font>
                                </dxo-legend>
                                <dxo-export [enabled]="false"></dxo-export>
                                <dxi-series
                                    argumentField="type"
                                    valueField="amount">
                                    <dxo-label
                                        [visible]="true"
                                        position="columns"
                                        [customizeText]="customizePieChartLabel">
                                        <dxo-font [size]="18"></dxo-font>
                                        <dxo-connector
                                            [visible]="true"
                                            [width]="1"></dxo-connector>
                                    </dxo-label>
                                </dxi-series>
                            </dx-pie-chart>
                        </div>
                        <div *ngIf="!incomePieData.length && requestCameIncome" class="ui info message">
                            <div class="ui header">
                                Seçtiğiniz tarihlerde gelir bilgisi bulunmamaktadır.
                            </div>
                        </div>
                        <div *ngIf="!requestCameIncome" class="ui info message">
                            <div class="content">
                                <div class="ui text active centered inline loader">
                                    Yükleniyor...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column" style="padding-top: 0;">
                <div class="ui fluid card">
                    <div class="content">
                        <h2 class="ui header">
                            {{getPieChartTitle(false)}}
                        </h2>
                    </div>
                    <div class="content">
                        <div class="ui menu">
                            <a class="item" (click)="viewIsSet(false, 'month')" [ngClass]="{'active': viewOutcome === 'month'}" >
                                Aylık
                            </a>
                            <a class="item" (click)="viewIsSet(false, 'year')" [ngClass]="{'active': viewOutcome === 'year'}" >
                                Yıllık
                            </a>
                            <a class="item" (click)="viewIsSet(false, 'all')" [ngClass]="{'active': viewOutcome === 'all'}" >
                                Tüm Zamanlar
                            </a>
                            <div class="right menu">
                                <div class="item">
                                    <div class="inline fields">
                                        <div class="field">
                                            <div class="ui radio checkbox">
                                                <input [(ngModel)]="currencyOutcome" [ngModelOptions]="{standalone: true}" (change)="generateData(false)" name="currencyOutcome" value='EURO' type="radio">
                                                <label>EURO</label>
                                            </div>
                                        </div>
                                        <div class="field">
                                            <div class="ui radio checkbox">
                                                <input [(ngModel)]="currencyOutcome" [ngModelOptions]="{standalone: true}" (change)="generateData(false)" name="currencyOutcome"  value='TL' type="radio">
                                                <label>TL</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="viewOutcome !== 'all'" class="item">
                                    <div class="content">
                                        <div *ngIf="viewOutcome == 'month'" class="field">
                                            <select (change)="loadOutcomes()" [(ngModel)]="outcomeMonthStart" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                <option *ngFor="let month of TRMonths" [ngValue]="month.value" name="outcomeMonthStart">
                                                    {{month.month}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="field">
                                            <select (change)="loadOutcomes()" [(ngModel)]="outcomeYearStart" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                <option *ngFor="let year of yearsOfOutcomes" [ngValue]=year name="outcomeYearStart">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="content icon">
                                        <i class="minus icon" style="margin-left:0.25rem"></i>
                                    </div>
                                    <div class="content">
                                        <div *ngIf="viewOutcome == 'month'" class="field">
                                            <select (change)="loadOutcomes()" [(ngModel)]="outcomeMonthEnd" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                <option *ngFor="let month of TRMonths" [ngValue]="month.value" name="outcomeMonthEnd">
                                                    {{month.month}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="field">
                                            <select (change)="loadOutcomes()" [(ngModel)]="outcomeYearEnd" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                <option *ngFor="let year of yearsOfOutcomes" [ngValue]=year name="outcomeYearEnd">{{year}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="outcomePieData.length && requestCameOutcome">
                            <dx-pie-chart
                                id="outcomePie"
                                [title]="getTotal(false)"
                                palette="bright"
                                [dataSource]="outcomePieData"
                                [resolveLabelOverlapping]="'shift'"
                                (onPointClick)="outcomePointClickHandler($event)"
                                (onLegendClick)="outcomeLegendClickHandler($event)">
                                <dxo-legend
                                    orientation="vertical"
                                    itemTextPosition="right"
                                    horizontalAlignment="right"
                                    verticalAlignment="top"
                                    [columnCount]="1">
                                    <dxo-font [size]="16"></dxo-font>
                                </dxo-legend>
                                <dxo-export [enabled]="false"></dxo-export>
                                <dxi-series
                                    argumentField="type"
                                    valueField="amount">
                                    <dxo-label
                                        [visible]="true"
                                        position="columns"
                                        [customizeText]="customizePieChartLabel">
                                        <dxo-font [size]="18"></dxo-font>
                                        <dxo-connector
                                            [visible]="true"
                                            [width]="1"></dxo-connector>
                                    </dxo-label>
                                </dxi-series>
                            </dx-pie-chart>
                        </div>
                        <div *ngIf="!outcomePieData.length && requestCameOutcome" class="ui info message">
                            <div class="ui header">
                                Seçtiğiniz tarihlerde gider bilgisi bulunmamaktadır.
                            </div>
                        </div>
                        <div *ngIf="!requestCameOutcome" class="ui info message">
                            <div class="content">
                                <div class="ui text active centered inline loader">
                                    Yükleniyor...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
