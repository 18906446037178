import {Paginator} from './paginator';
import {Subscription} from 'rxjs';

type FunctionType = () => Subscription;

export class PaginatorBuilder implements Partial<Paginator> {
    private _pageSizeOptions: number[] = [5, 10, 15];
    private _selectedPageSize; // equals to one of the pageSizeOptions variables
    private _selectedPage = 1; // Initially select the first page
    private _totalSize = 1;
    private _refreshFunction: FunctionType = () => undefined; // Should be called when elements are needed to be changed.
    private _resizeFunction: FunctionType = () => undefined; // Should be called when <totalSize> is changed.

    constructor() {
    }

    withPageSizeOptions(options: number[]): this {
        this._pageSizeOptions = options;
        return this;
    }

    withSelectedPageSize(selectedPageSize: number): this {
        this._selectedPageSize = selectedPageSize;
        return this;
    }

    withSelectedPage(selectedPage: number): this {
        this._selectedPage = selectedPage;
        return this;
    }

    withRefreshFunction(callback: FunctionType): this {
        this._refreshFunction = callback;
        return this;
    }

    withResizeFunction(callback: FunctionType): this {
        this._resizeFunction = callback;
        return this;
    }

    build(): Paginator {
        if (!this.selectedPageSize) {
            this._selectedPageSize = this._pageSizeOptions[0];
        }
        return new Paginator(this);
    }

    get pageSizeOptions(): number[] {
        return this._pageSizeOptions;
    }

    get selectedPageSize(): number {
        return this._selectedPageSize;
    }

    get selectedPage(): number {
        return this._selectedPage;
    }

    get totalSize(): number {
        return this._totalSize;
    }

    get refreshFunction(): FunctionType {
        return this._refreshFunction;
    }

    get resizeFunction(): FunctionType {
        return this._resizeFunction;
    }
}
