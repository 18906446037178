
export const environment = {
    production: true,
    auth: {
        secret: 'gZC[8Et3&qIo)jM'
    },
    apiUrl: 'api',
    apiTCMB: 'https://evds2.tcmb.gov.tr/service/evds/series=TP.DK.USD.A-TP.DK.EUR.A-TP.DK.CHF.A-TP.DK.GBP.A-TP.DK.JPY.A',
    apiKeyTCMB: 'vTw0NjLj3d',
    SRDCOrganizationID: 1,
    besOrganizationId: 13, // Anadolu Hayat Emeklilik
    TRMonths: [
        {month: 'Ocak', value: 0},
        {month: 'Şubat', value: 1},
        {month: 'Mart', value: 2},
        {month: 'Nisan', value: 3},
        {month: 'Mayıs', value: 4},
        {month: 'Haziran', value: 5},
        {month: 'Temmuz', value: 6},
        {month: 'Ağustos', value: 7},
        {month: 'Eylül', value: 8},
        {month: 'Ekim', value: 9},
        {month: 'Kasım', value: 10},
        {month: 'Aralık', value: 11}
    ],
    currencySymbols: {
        'EURO': '€',
        'DOLLAR': '$',
        'TL': '₺'
    }
};
