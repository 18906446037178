import { Pipe, PipeTransform } from '@angular/core';
import {VacationDaysEntry} from '../../../models/vacationDaysEntry';

@Pipe({
    name: 'vacationDateFilter'
})
export class VacationDateFilterPipe implements PipeTransform {

    transform(dayEntries: VacationDaysEntry[], year: number): VacationDaysEntry[] {
        // VacataionDayEntry.startDate and endDate are date but in the component they are set to strings ??
        // That is why we should convert them again.
        return dayEntries.filter(entry => new Date(entry.startDate).getFullYear() === year || new Date(entry.endDate).getFullYear() === year);
    }

}
