/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./current-exchange-rates.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./current-exchange-rates.component";
import * as i4 from "../shared/services/exchange-rate.service";
import * as i5 from "../shared/services/utils.service";
var styles_CurrentExchangeRatesComponent = [i0.styles];
var RenderType_CurrentExchangeRatesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CurrentExchangeRatesComponent, data: { "animation": [{ type: 7, name: "simpleFadeAnimation", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, background: "#fbfbfb" }, offset: null }, { type: 4, styles: null, timings: 750 }], options: null }], options: {} }] } });
export { RenderType_CurrentExchangeRatesComponent as RenderType_CurrentExchangeRatesComponent };
function View_CurrentExchangeRatesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "ui active centered inline loader"]], null, null, null, null, null))], null, null); }
function View_CurrentExchangeRatesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "h4", [["class", "ui header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "i", [["class", "sign icon"]], null, null, null, null, null)), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "euro": 0, "usd": 1 }), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "sign icon"; var currVal_1 = _ck(_v, 6, 0, (_v.context.$implicit.currency === "EURO"), (_v.context.$implicit.currency === "USD")); _ck(_v, 5, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.utilsService.formatDouble(_v.context.$implicit.rate, 4); _ck(_v, 7, 0, currVal_2); }); }
function View_CurrentExchangeRatesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CurrentExchangeRatesComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentExchangeRatesGUI; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CurrentExchangeRatesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["style", "font-weight: bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["TCMB kurlar\u0131:"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "table", [["class", "ui very basic collapsing celled table"], ["style", "margin: 5px auto auto auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CurrentExchangeRatesComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CurrentExchangeRatesComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.loading; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_CurrentExchangeRatesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-current-exchange-rates", [], null, null, null, View_CurrentExchangeRatesComponent_0, RenderType_CurrentExchangeRatesComponent)), i1.ɵdid(1, 245760, null, 0, i3.CurrentExchangeRatesComponent, [i4.ExchangeRateService, i5.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CurrentExchangeRatesComponentNgFactory = i1.ɵccf("app-current-exchange-rates", i3.CurrentExchangeRatesComponent, View_CurrentExchangeRatesComponent_Host_0, {}, {}, []);
export { CurrentExchangeRatesComponentNgFactory as CurrentExchangeRatesComponentNgFactory };
