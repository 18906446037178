import {Employee} from './employee';
import {Workpackage} from './workpackage';
import {Timesheet} from './timesheet';
import {ReportingPeriod} from './reportingPeriod';
import {Amount} from './amount';

export class Project {
    _id: string;
    workpackages: Workpackage[];
    manager: Employee;
    employees: Employee[];
    timesheets: Timesheet[];
    reportingPeriods: ReportingPeriod[];
    periodLengths: number[];
    projectName: string;
    projectId: string;
    acronym: string;
    applicationDate: Date;
    startDate: Date;
    projectDuration: number;
    endDate: Date;
    projectType: Project.Type;
    overheadRate: number;
    fundingRate: number;
    totalCostAmount: Amount;
    finalTotalContributionAmount: Amount;
    otherCostAmount: Amount;
    directCostAmount: Amount;
    personnelCostAmount: Amount;
    totalPMs: number;
    indirectCostAmount: Amount;
    directCostSpentSoFar: Amount;
    directCostToBeSpent: Amount;
    PMsSpentSoFar: number;
    PMsToBeSpent: number;
    totalPaymentReceived: Amount;
    totalCostInTheCurrentReportingPeriod: Amount;
    totalActualCostCurrentReportingPeriod: Amount;
    totalCostReportedTillNow: Amount;
    PMsSpentInTheCurrentReportingPeriod: number;
    actualCostSpentSoFar: Amount;
    iteaFields: {
        applicationDate: Date;
        acronym: string;
        projectName: string;
        duration: number;
        startDate: Date;
        endDate: Date;
        totalPersonYear: number;
        totalCostAmount: Amount;
    };
    iteaPeriodCache: Object;

    constructor() {
        this.workpackages = [];
        this.employees = [];
        this.timesheets = [];
        this.reportingPeriods = [];
        this.periodLengths = [];
        this.applicationDate = new Date();
        this.startDate = new Date();
        this.endDate = new Date();
        this.totalCostAmount = new Amount();
        this.finalTotalContributionAmount = new Amount();
        this.otherCostAmount = new Amount();
        this.directCostAmount = new Amount();
        this.indirectCostAmount = new Amount();
        this.personnelCostAmount = new Amount();
        this.directCostAmount = new Amount();
        this.personnelCostAmount = new Amount();
        this.directCostSpentSoFar = new Amount();
        this.directCostToBeSpent = new Amount();
        this.totalPaymentReceived = new Amount();
        this.totalCostInTheCurrentReportingPeriod = new Amount();
        this.totalActualCostCurrentReportingPeriod = new Amount();
        this.totalCostReportedTillNow = new Amount();
        this.actualCostSpentSoFar = new Amount();
        this.iteaFields = {
            applicationDate: new Date(),
            acronym: '',
            projectName: '',
            duration: undefined,
            startDate: new Date(),
            endDate: new Date(),
            totalPersonYear: undefined,
            totalCostAmount: new Amount()
        };
        this.iteaPeriodCache = {};
    }
}

export namespace Project {
    export enum Type {
        HORIZON_EUROPE_RIA = 'Horizon Europe RIA',
        HORIZON_EUROPE_IA = 'Horizon Europe IA',
        H2020_RIA = 'H2020 RIA',
        H2020_IA = 'H2020 IA',
        ITEA = 'ITEA',
        INVOICED = 'Invoiced'
    }
}
