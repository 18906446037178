import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ExchangeRateService = /** @class */ (function () {
    function ExchangeRateService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/exchange-rate';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    ExchangeRateService.prototype.getRate = function (date, currency) {
        var query = { date: date, currency: currency };
        return this.http.get(this.URL, { headers: this.headers, params: query });
    };
    ExchangeRateService.ngInjectableDef = i0.defineInjectable({ factory: function ExchangeRateService_Factory() { return new ExchangeRateService(i0.inject(i1.HttpClient)); }, token: ExchangeRateService, providedIn: "root" });
    return ExchangeRateService;
}());
export { ExchangeRateService };
