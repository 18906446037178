import { UtilsService } from './utils.service';
import { Employee } from '../../../models/employee';
import { ComponentUtil } from '../../util/ComponentUtil';
import * as i0 from "@angular/core";
var MenuControllerService = /** @class */ (function () {
    function MenuControllerService() {
        this.sideBarNumber = -1;
        this.selectedTab = 0;
        this.profilePageView = 'overview';
        this.defaultAdminMenuItems = [
            {
                label: ComponentUtil.MAIN_PARTS['income'],
                items: [
                    {
                        component: 'invoiceIncome'
                    },
                    {
                        component: 'prizeManagement'
                    },
                    {
                        component: 'fonIncome'
                    },
                    {
                        component: 'otherIncomes'
                    }
                ]
            },
            {
                label: ComponentUtil.MAIN_PARTS['outcome'],
                items: [
                    {
                        component: 'invoicePayment'
                    },
                    {
                        component: 'inHousePayment'
                    },
                    {
                        component: 'statePayment'
                    }
                ]
            },
            {
                label: ComponentUtil.MAIN_PARTS['management'],
                items: [
                    {
                        component: 'salaryPayment'
                    },
                    {
                        component: 'projectManagement'
                    },
                    {
                        component: 'travelRequestManagement'
                    },
                    {
                        component: 'proposalManagement'
                    },
                    {
                        component: 'bankAccountManagement'
                    },
                    {
                        component: 'timesheetManagement'
                    },
                    {
                        component: 'activityTrackingReport'
                    },
                    {
                        component: 'leaveExcuseManagement'
                    },
                    {
                        component: 'courseSchedule'
                    },
                    {
                        component: 'estimationPreparation'
                    }
                ]
            },
            {
                label: ComponentUtil.MAIN_PARTS['system'],
                items: [
                    {
                        component: 'employeeManagement'
                    },
                    {
                        component: 'organizationManagement'
                    },
                    {
                        component: 'backupRestore'
                    },
                    {
                        component: 'userAuthorizationManagement'
                    },
                    {
                        component: 'notificationPreferences'
                    },
                    {
                        component: 'environmentVariableManagement'
                    }
                ]
            }
        ];
        this.profileMenuItems = [
            {
                label: 'Profil'
            },
            {
                label: 'Banka Hesapları'
            },
            {
                label: 'İzin Bilgisi'
            },
            {
                label: 'Ders Programı'
            },
            {
                label: 'Dosyalar',
                items: [
                    {
                        label: ComponentUtil.PROFILE_PAGE_FILES['cv']
                    },
                    {
                        label: ComponentUtil.PROFILE_PAGE_FILES['sgk']
                    },
                    {
                        label: ComponentUtil.PROFILE_PAGE_FILES['contracts']
                    }
                ]
            }
        ];
        this.sidebarItems = [];
        this.sidebar = null;
        this.isProfilePage = false;
        this.currentUserType = '';
    }
    MenuControllerService.prototype.setMenuByAuthorization = function () {
        this.sidebarItems = UtilsService.deepCopy(this.defaultAdminMenuItems);
        this.sidebarItems.forEach(function (part) {
            for (var _i = 0, _a = part.items; _i < _a.length; _i++) {
                var item = _a[_i];
                item.label = ComponentUtil.COMPONENT_NAMES[item.component];
                item.href = '/' + item.component;
            }
        });
        if (this.currentUserType !== Employee.UserType.ADMIN) {
            this.sidebarItems[3].label = '';
        }
    };
    MenuControllerService.prototype.setCurrentUserType = function (userType) {
        this.currentUserType = userType;
    };
    MenuControllerService.prototype.showWhichSideBarItem = function (i) {
        if (this.isProfilePage) {
            this.profilePageView = 'overview';
            if (this.sideBarNumber === i && i === 4) {
                this.sideBarNumber = -1;
            }
            else {
                this.sideBarNumber = i;
                if (i !== 4) {
                    this.selectedTab = i;
                }
            }
        }
        else {
            if (this.sideBarNumber === i) {
                this.sideBarNumber = -1;
            }
            else {
                this.sideBarNumber = i;
            }
        }
    };
    MenuControllerService.prototype.showWhichFileItem = function (subitem) {
        switch (subitem.label) {
            case 'CV':
                this.selectedTab = 4;
                break;
            case 'SGK İşe Giriş':
                this.selectedTab = 5;
                break;
            case 'Sözleşmeler':
                this.selectedTab = 6;
                break;
        }
    };
    MenuControllerService.prototype.setProfileMenu = function () {
        this.isProfilePage = true;
        if (this.sidebar) {
            this.sidebar.animate();
        }
        this.sidebarItems = this.profileMenuItems;
    };
    MenuControllerService.prototype.resetSidebarItems = function () {
        this.isProfilePage = false;
        this.sideBarNumber = -1;
        if (this.sidebar) {
            this.sidebar.animate();
        }
    };
    MenuControllerService.ngInjectableDef = i0.defineInjectable({ factory: function MenuControllerService_Factory() { return new MenuControllerService(); }, token: MenuControllerService, providedIn: "root" });
    return MenuControllerService;
}());
export { MenuControllerService };
