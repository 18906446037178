<div appSidebar [sidebarContext]="'body'" [ngClass]="{'ui left vertical inverted menu sidebar':true}">
    <a  href="/" class="ui item header padded borderless vertically centered">
        <span>
            <img class="ui image small centered padded" [attr.src]="'../assets/img/srdc.png'">
        </span>
    </a>
    <div class="ui item container center aligned" style="background-color: rgba(30,30,30,0.2) !important;">
        <div class="small-circular-image-wrapper">
            <img class="ui circular image small centered padded" [attr.src]="'../assets/img/no-pic.png'">
        </div>
        <h3>{{authorizationService.currentUserName}}</h3>
        <div *ngIf="currentUser" style="text-align: left !important;">
            <span class="item">
                <b>Kullanıcı Türü:</b> {{currentUser.userType}}
            </span>
            <span class="item">
                <b>Meslek:</b> {{currentUser.profession}}
            </span>
        </div>
    </div>

    <a *ngIf="menuControllerService.sidebarItems[0].label" class="item" (click)="menuControllerService.showWhichSideBarItem(0)" [ngClass]="{'active': menuControllerService.sideBarNumber === 0}">
        {{menuControllerService.sidebarItems[0].label}}
        <i *ngIf="menuControllerService.sidebarItems[0].items && menuControllerService.sideBarNumber === 0" class="angle up icon"></i>
        <i *ngIf="menuControllerService.sidebarItems[0].items && menuControllerService.sideBarNumber !== 0" class="angle down icon"></i>
    </a>
    <div class="menu ifActive" *ngIf="menuControllerService.sidebarItems[0].items && menuControllerService.sideBarNumber === 0">
        <ng-container *ngIf="menuControllerService.sidebarItems[0].items[0].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[0].items[0].component"
               (click)="reload(menuControllerService.sidebarItems[0].items[0].href)"
               [routerLink]="menuControllerService.sidebarItems[0].items[0].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[0].items[0].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[0].items[0].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[0].items[0])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[0].items[0].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[0].items[1].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[0].items[1].component"
               (click)="reload(menuControllerService.sidebarItems[0].items[1].href)"
               [routerLink]="menuControllerService.sidebarItems[0].items[1].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[0].items[1].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[0].items[1].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[0].items[1])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[0].items[1].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[0].items[2].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[0].items[2].component"
               (click)="reload(menuControllerService.sidebarItems[0].items[2].href)"
               [routerLink]="menuControllerService.sidebarItems[0].items[2].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[0].items[2].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[0].items[2].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[0].items[2])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[0].items[2].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[0].items[3].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[0].items[3].component"
               (click)="reload(menuControllerService.sidebarItems[0].items[3].href)"
               [routerLink]="menuControllerService.sidebarItems[0].items[3].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[0].items[3].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[0].items[3].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[0].items[3])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[0].items[3].label}}</span></a>
        </ng-container>
    </div>

    <a *ngIf="menuControllerService.sidebarItems[1].label" class="item" (click)="menuControllerService.showWhichSideBarItem(1)" [ngClass]="{'active': menuControllerService.sideBarNumber === 1}">
        {{menuControllerService.sidebarItems[1].label}}
        <i *ngIf="menuControllerService.sidebarItems[1].items && menuControllerService.sideBarNumber === 1" class="angle up icon"></i>
        <i *ngIf="menuControllerService.sidebarItems[1].items && menuControllerService.sideBarNumber !== 1" class="angle down icon"></i>
    </a>
    <div class="menu ifActive" *ngIf="menuControllerService.sidebarItems[1].items && menuControllerService.sideBarNumber === 1">
        <ng-container *ngIf="menuControllerService.sidebarItems[1].items[0].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[1].items[0].component"
               (click)="reload(menuControllerService.sidebarItems[1].items[0].href)"
               [routerLink]="menuControllerService.sidebarItems[1].items[0].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[1].items[0].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[1].items[0].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[1].items[0])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[1].items[0].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[1].items[1].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[1].items[1].component"
               (click)="reload(menuControllerService.sidebarItems[1].items[1].href)"
               [routerLink]="menuControllerService.sidebarItems[1].items[1].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[1].items[1].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[1].items[1].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[1].items[1])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[1].items[1].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[1].items[2].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[1].items[2].component"
               (click)="reload(menuControllerService.sidebarItems[1].items[2].href)"
               [routerLink]="menuControllerService.sidebarItems[1].items[2].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[1].items[2].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[1].items[2].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[1].items[2])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[1].items[2].label}}</span></a>
        </ng-container>

    </div>

    <a *ngIf="menuControllerService.sidebarItems[2].label" class="item" (click)="menuControllerService.showWhichSideBarItem(2)" [ngClass]="{'active': menuControllerService.sideBarNumber === 2}">
        {{menuControllerService.sidebarItems[2].label}}
        <i *ngIf="menuControllerService.sidebarItems[2].items && menuControllerService.sideBarNumber === 2" class="angle up icon"></i>
        <i *ngIf="menuControllerService.sidebarItems[2].items && menuControllerService.sideBarNumber !== 2" class="angle down icon"></i>
    </a>
    <div class="menu ifActive" *ngIf="menuControllerService.sidebarItems[2].items && menuControllerService.sideBarNumber === 2">
        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[0].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[0].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[0].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[0].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[0].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[0].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[0])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[0].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[1].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[1].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[1].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[1].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[1].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[1].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[1])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[1].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[2].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[2].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[2].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[2].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[2].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[2].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[2])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[2].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[3].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[3].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[3].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[3].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[3].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[3].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[3])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[3].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[4].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[4].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[4].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[4].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[4].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[4].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[4])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[4].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[5].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[5].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[5].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[5].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[5].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[5].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[5])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[5].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[6].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[6].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[6].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[6].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[6].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[6].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[6])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[6].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[7].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[7].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[7].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[7].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[7].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[7].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[7])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[7].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[8].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[8].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[8].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[8].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[8].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[8].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[8])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[8].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[2].items[9].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[2].items[9].component"
               (click)="reload(menuControllerService.sidebarItems[2].items[9].href)"
               [routerLink]="menuControllerService.sidebarItems[2].items[9].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[9].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[2].items[9].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[2].items[9])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[2].items[9].label}}</span></a>
        </ng-container>
    </div>

    <a *ngIf="menuControllerService.sidebarItems[3].label" class="item" (click)="menuControllerService.showWhichSideBarItem(3)" [ngClass]="{'active': menuControllerService.sideBarNumber === 3}">
        {{menuControllerService.sidebarItems[3].label}}
        <i *ngIf="menuControllerService.sidebarItems[3].items && menuControllerService.sideBarNumber === 3" class="angle up icon"></i>
        <i *ngIf="menuControllerService.sidebarItems[3].items && menuControllerService.sideBarNumber !== 3" class="angle down icon"></i>
    </a>
    <div class="menu ifActive" *ngIf="menuControllerService.sidebarItems[3].items && menuControllerService.sideBarNumber === 3">
        <ng-container *ngIf="menuControllerService.sidebarItems[3].items[0].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[3].items[0].component"
               (click)="reload(menuControllerService.sidebarItems[3].items[0].href)"
               [routerLink]="menuControllerService.sidebarItems[3].items[0].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[0].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[3].items[0].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[3].items[0])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[0].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[3].items[1].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[3].items[1].component"
               (click)="reload(menuControllerService.sidebarItems[3].items[1].href)"
               [routerLink]="menuControllerService.sidebarItems[3].items[1].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[1].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[3].items[1].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[3].items[1])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[1].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[3].items[2].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[3].items[2].component"
               (click)="reload(menuControllerService.sidebarItems[3].items[2].href)"
               [routerLink]="menuControllerService.sidebarItems[3].items[2].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[2].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[3].items[2].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[3].items[2])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[2].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="!menuControllerService.isProfilePage && menuControllerService.sidebarItems[3].items[3].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[3].items[3].component"
               (click)="reload(menuControllerService.sidebarItems[3].items[3].href)"
               [routerLink]="menuControllerService.sidebarItems[3].items[3].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[3].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.isProfilePage && !menuControllerService.sidebarItems[3].items[3].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[3].items[3])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[3].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="!menuControllerService.isProfilePage && menuControllerService.sidebarItems[3].items[4].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[3].items[4].component"
               (click)="reload(menuControllerService.sidebarItems[3].items[4].href)"
               [routerLink]="menuControllerService.sidebarItems[3].items[4].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[4].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.isProfilePage && !menuControllerService.sidebarItems[3].items[4].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[3].items[4])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[4].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="!menuControllerService.isProfilePage && menuControllerService.sidebarItems[3].items[5].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[3].items[5].component"
               (click)="reload(menuControllerService.sidebarItems[3].items[5].href)"
               [routerLink]="menuControllerService.sidebarItems[3].items[5].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[5].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.isProfilePage && !menuControllerService.sidebarItems[3].items[5].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[3].items[5])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[3].items[5].label}}</span></a>
        </ng-container>

    </div>

    <a *ngIf="menuControllerService.sidebarItems[4] && menuControllerService.sidebarItems[4].label" class="item" (click)="menuControllerService.showWhichSideBarItem(4)" [ngClass]="{'active': menuControllerService.sideBarNumber === 4}">
        {{menuControllerService.sidebarItems[4].label}}
        <i *ngIf="menuControllerService.sidebarItems[4].items && menuControllerService.sideBarNumber === 4" class="angle up icon"></i>
        <i *ngIf="menuControllerService.sidebarItems[4].items && menuControllerService.sideBarNumber !== 4" class="angle down icon"></i>
    </a>
    <div class="menu ifActive" *ngIf="menuControllerService.sidebarItems[4] && menuControllerService.sidebarItems[4].items && menuControllerService.sideBarNumber === 4">
        <ng-container *ngIf="menuControllerService.sidebarItems[4].items[0].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[4].items[0].component"
               (click)="reload(menuControllerService.sidebarItems[4].items[0].href)"
               [routerLink]="menuControllerService.sidebarItems[4].items[0].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[0].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[4].items[0].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[4].items[0])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[0].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[4].items[1].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[4].items[1].component"
               (click)="reload(menuControllerService.sidebarItems[4].items[1].href)"
               [routerLink]="menuControllerService.sidebarItems[4].items[1].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[1].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[4].items[1].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[4].items[1])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[1].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="menuControllerService.sidebarItems[4].items[2].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[4].items[2].component"
               (click)="reload(menuControllerService.sidebarItems[4].items[2].href)"
               [routerLink]="menuControllerService.sidebarItems[4].items[2].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[2].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.sidebarItems[4].items[2].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[4].items[2])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[2].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="!menuControllerService.isProfilePage && menuControllerService.sidebarItems[4].items[3].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[4].items[3].component"
               (click)="reload(menuControllerService.sidebarItems[4].items[3].href)"
               [routerLink]="menuControllerService.sidebarItems[4].items[3].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[3].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.isProfilePage && !menuControllerService.sidebarItems[4].items[3].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[4].items[3])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[3].label}}</span></a>
        </ng-container>

        <ng-container *ngIf="!menuControllerService.isProfilePage && menuControllerService.sidebarItems[4].items[4].href">
            <a class="active item" *appComponent="menuControllerService.sidebarItems[4].items[4].component"
               (click)="reload(menuControllerService.sidebarItems[4].items[4].href)"
               [routerLink]="menuControllerService.sidebarItems[4].items[4].href"><span style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[4].label}}</span></a>
        </ng-container>
        <ng-container *ngIf="!menuControllerService.isProfilePage && !menuControllerService.sidebarItems[4].items[4].href">
            <a (click)="menuControllerService.showWhichFileItem(menuControllerService.sidebarItems[4].items[4])" class="active item"><span
                    style="margin-left: 1em">{{menuControllerService.sidebarItems[4].items[4].label}}</span></a>
        </ng-container>

    </div>
</div>

<div class="ui custom inverted fixed menu top desktop large no-margin" >
    <div class="menu left">
        <a class="ui item" routerLink="/home" routerLinkActive="active">
            <i class="large home icon"></i> Ana Sayfa
        </a>
        <a class="ui item" routerLink="/userProfile" routerLinkActive="active"
            (click)="reload('/userProfile')">
            <i class="large user icon"></i> Profil
         </a>
    </div>
    <div class="menu right">
        <a class="item borderless" (click)="areYouSure.show()"><i class="sign out icon large"></i> Çıkış Yap</a>
    </div>
</div>

<div class="pusher">
    <div style="height: 100vh !important;">
        <div id="app" style="height: calc(100%); background: #FAFAFA; max-width: 100%; overflow-x: hidden;">
            <router-outlet></router-outlet>
            <div class="ui dimmer hidden" id="pusher-dimmer"></div>
        </div>
    </div>
</div>



<!-- ARE YOU SURE MODAL-->
<div appModal #areYouSure=modal class="ui mini modal" id="logoutModal" style="height: 21%!important;">
    <div class="header">
        <b>Çıkış Yap</b>
        <i (click)="areYouSure.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        Çıkmak istediğinizden emin misiniz?
    </div>
    <div class="actions">
        <button class="ui negative button" (click)="logout()">Çık</button>
    </div>
</div>
