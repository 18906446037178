import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LeaveExcuseManagementRoutingModule} from './leave-excuse-management-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared/shared.module';
import {LeaveExcuseManagementComponent} from './leave-excuse-management.component';
import {MatDatepickerModule} from '@angular/material';
import {PipesModule} from '../shared/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        LeaveExcuseManagementRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatDatepickerModule,
        PipesModule
    ],
    exports: [
    ],
    declarations: [LeaveExcuseManagementComponent]
})
export class LeaveExcuseManagementModule { }
