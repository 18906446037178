import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PaymentService} from '../shared/services/payment.service';
import {ModalDirective} from '../shared/directives/modal.directive';
import {TravelService} from '../shared/services/travel.service';
import {environment} from '../../environments/environment';
import {InvoiceService} from '../shared/services/invoice.service';
import {UtilsService} from '../shared/services/utils.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ExchangeRateService} from '../shared/services/exchange-rate.service';
import * as moment from 'moment';
import {AlertUtil} from '../util/AlertUtil';
import {AuthorizationService} from '../shared/services/authorization.service';
import {Payment} from '../../models/payment';
import {Employee} from '../../models/employee';
import {InvoiceReportService} from '../shared/services/invoice-report.service';
import {Paginator} from '../shared/paginator';
import {PaginatorBuilder} from '../shared/paginator-builder';
import {InhouseReportService} from '../shared/services/inhouse-report.service';
import {Project} from '../../models/project';
import {PayrollService} from '../shared/services/payroll.service';
import {EnvironmentVariableService} from '../shared/services/environment-variable.service';
import {Environment} from '../../models/environmentVariables';
import {FonService} from "../shared/services/fon.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, OnDestroy {
    @ViewChild('incomeOutcomeDetailModal') incomeOutcomeDetailModal: ModalDirective;
    @ViewChild('lineChartModal') lineChartModal: ModalDirective;

    incomePieData: {type: string, amount: number}[] = []; // data shown on pie chart
    outcomePieData: {type: string, amount: number}[] = []; // data shown on pie chart
    currentIncomePayments: {H2020_projects: Payment[], ITEA_projects: Payment[], local_projects: Payment[], invoices: Payment[], prizes: Payment[], reimbursement: Payment[], other: Payment[]}; // to display data when clicked
    currentOutcomePayments: {travels: Payment[], salary: Payment[], bes: Payment[], state: Payment[], inhouse: Payment[], invoices: Payment[], other: Payment[]}; // to display data when clicked
    detailedIncomeData = []; // selected feature's detailed data
    detailedOutcomeData = []; // selected feature's detailed data
    viewIncome = 'month';
    viewOutcome = 'month';
    currencyIncome = 'TL';
    currencyOutcome = 'TL';
    startingDate: Date = new Date();
    incomeYearStart: number;
    incomeYearEnd: number;
    incomeMonthStart: number;
    incomeMonthEnd: number;
    outcomeYearStart: number;
    outcomeYearEnd: number;
    outcomeMonthStart: number;
    outcomeMonthEnd: number;
    incomes = [];
    outcomes = [];
    yearsOfIncomes: number[] = [];
    yearsOfOutcomes: number[] = [];
    detailModal = 'income';
    lineChartHeader = '';
    lineChartData: {date: string, amount: number}[] = [];
    lineChartName = '';
    monthlyLineChart: {date: string, amount: number}[] = [];
    yearlyLineChart: {date: string, amount: number}[] = [];
    lineColor = '#ff0000';
    chartDetailingOptionIds: string[] = [];
    chartDetailingOptions: string[] = [];
    selectedOption = '';
    chartType = '';
    isIncome = true;
    isOptionable = false;
    index = 0;
    regularTypes;
    _destroyed$ = new Subject();
    requestCameIncome = false;
    requestCameOutcome = false;
    TRMonths: {month: string, value: number}[] = environment.TRMonths;
    environmentVariablesInDB: Environment;
    paginator: Paginator;
    fonIncomeTotal: Object; // {TL: number, EURO: number, DOLLAR: number}

    constructor(private paymentService: PaymentService, private travelService: TravelService, private inhouseReportService: InhouseReportService,
                private invoiceService: InvoiceService, public utilsService: UtilsService, private invoiceReportService: InvoiceReportService,
                private payrollService: PayrollService, private exchangeRateService: ExchangeRateService, public authorizationService: AuthorizationService,
                private environmentVariableService: EnvironmentVariableService, private fonService: FonService) {
        this.getLineChartLabel = this.getLineChartLabel.bind(this);
        this.customizePieChartLabel = this.customizePieChartLabel.bind(this);
        this.customizeTooltip = this.customizeTooltip.bind(this);
    }

    ngOnInit() {
        this.paginator = new PaginatorBuilder().build();
        this.clearMonthlyLineChart();
        this.setDates();
        this.getEnvironmentVariables().then(r => {
            this.loadIncomes();
            this.loadOutcomes();
        });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
    }

    getEnvironmentVariables() {
        return this.environmentVariableService.getEnvVariables()
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(response => {
                this.environmentVariablesInDB = response;
                this.regularTypes = this.environmentVariablesInDB.regularInvoiceTypes;
            });
    }

    setDates() {
        this.startingDate.setMonth(this.startingDate.getMonth());
        this.incomeYearStart = this.startingDate.getFullYear();
        this.incomeYearEnd = new Date().getFullYear();
        this.incomeMonthStart = this.startingDate.getMonth();
        this.incomeMonthEnd = new Date().getMonth();
        this.outcomeYearStart = this.startingDate.getFullYear();
        this.outcomeYearEnd = new Date().getFullYear();
        this.outcomeMonthStart = this.startingDate.getMonth();
        this.outcomeMonthEnd = new Date().getMonth();
        const thisYear = new Date().getFullYear();
        this.paymentService.getEarliestIncomePayments().pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            response => {
                let minYear = new Date(response).getFullYear();
                while (minYear <= thisYear) {
                    this.yearsOfIncomes.push(minYear++);
                }
            }
        );
        this.paymentService.getEarliestOutcomePayments().pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            response => {
                let minYear = new Date(response).getFullYear();
                while (minYear <= thisYear) {
                    this.yearsOfOutcomes.push(minYear++);
                }
            }
        );
    }

    loadIncomes() {
        this.requestCameIncome = false;
        this.clearIncomes();
        this.loadIncomePayments();
    }

    loadIncomeInvoices(): Promise<any> {
        const query = {'payeeOrganization.id': environment.SRDCOrganizationID, 'payeeEmployee': UtilsService.NOT_NULL_TOKEN};
        let tempDate;
        if (this.viewIncome === 'month') {
            tempDate = {begin: new Date(this.incomeYearStart, this.incomeMonthStart, 1),
                end: new Date(this.incomeYearEnd, this.incomeMonthEnd + 1, 1)};
        } else if (this.viewIncome === 'year') { // contains all days in year
            tempDate = {begin: new Date(this.incomeYearStart, 0, 1),
                end: new Date(this.incomeYearEnd + 1, 0, 1)};
        }
        this.paginator.onDateChanges('paidDate', {value: tempDate});
        this.paginator.addFilterQueries(query);

        return this.invoiceService.getHomeInvoices(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(response => {
                const invoices = [].concat.apply([], response);
                for (const invoice of invoices) {
                    this.incomes.push({
                        paymentType: 'Invoice',
                        date: invoice.paidDate,
                        amount: invoice.totalAmount,
                        exchangeRate: invoice.exchangeRate,
                        invoiceType: invoice.invoiceType,
                        project: invoice.project
                    });
                }
            });
    }

    loadIncomePayments() {
        const query = this.generateQuery(false, true);
        this.paymentService.getHomeIncomePayments(query).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            response => {
                this.incomes = response;
                const promises = [this.loadIncomeInvoices()];
                Promise.all(promises).then(() => {
                    this.loadFonAmount(query);
                });
            }
        );
    }
    setFonFields() {
        const date = moment(new Date()).format('YYYY-MM-DD');
        let tl = 0;
        let euro = 0;
        let euroTL = 1;
        let usdTL = 1;
        const promises = [];
        if (this.fonIncomeTotal['EURO'] !== 0) {
            const promise1 = this.exchangeRateService
                .getRate(date, 'EURO')
                .toPromise()
                .then(curr => {
                    euroTL = curr.exchangeRate;
                });
            promises.push(promise1);
        }
        if (this.fonIncomeTotal['DOLLAR'] !== 0) {
            const promise2 = this.exchangeRateService
                .getRate(date, 'USD')
                .toPromise()
                .then(curr => {
                    usdTL = curr.exchangeRate;
                });
            promises.push(promise2);
        }
        Promise.all(promises).then(() => {
            tl += this.fonIncomeTotal['TL'] + this.fonIncomeTotal['EURO'] * euroTL +   this.fonIncomeTotal['DOLLAR'] * usdTL;
            euro += this.fonIncomeTotal['EURO'] + this.fonIncomeTotal['TL'] / euroTL +   this.fonIncomeTotal['DOLLAR'] * usdTL / euroTL;
            this.fonIncomeTotal['TL'] = tl;
            this.fonIncomeTotal['EURO'] = euro;
            setTimeout(() => {
                this.generateData(true);
            });
        });
    }

    loadFonAmount(query) {
        return this.fonService.getTotalBalance(0, query).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            response => {
                this.fonIncomeTotal = response;
                this.setFonFields();
            }
        );
    }

    loadOutcomes() {
        this.requestCameOutcome = false;
        this.clearOutcomes();
        this.loadOutcomePayments();
    }

    loadOutcomeInvoices(): Promise<any> {
        const query = this.generateQuery(true);
        return this.invoiceReportService.getAllPeriods(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(response => {
                response.map((table, index) => {
                    table.invoices.forEach(invoice => {
                        const temp = {
                            paymentType: 'InvoicePayment',
                            date: invoice.paidDate,
                            amount: invoice.totalAmount,
                            exchangeRate: invoice.exchangeRate,
                            invoiceType: invoice.invoiceType,
                            type: this.regularTypes.includes(invoice.invoiceType.toString()) ? 'Düzenli' : 'Diğer'
                        };
                        temp.invoiceType = this.TRMonths[table.month - 1].month + ' Ayı ' + invoice.invoiceType;
                        this.outcomes.push(temp);
                    });
                });
            });
    }

    loadInhousePayments(): Promise<any> {
        const query = this.generateQuery(true);
        return this.inhouseReportService.getAllPeriods(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(response => {
                const arrays = response.map(table => table.invoices);
                const invoices = [].concat.apply([], arrays);
                for (const invoice of invoices) {
                    this.outcomes.push({
                        paymentType: 'Inhouse',
                        date: invoice.date,
                        amount: invoice.totalAmount,
                        exchangeRate: invoice.exchangeRate,
                        invoiceType: invoice.invoiceType,
                        payerEmployee: invoice.payerEmployee
                    });
                }
            });
    }

    loadTravels(): Promise<any> {
        const query = this.generateQuery(false, false);
        return this.travelService.getHomeTravels(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(response => {
                for (const travel of response) {
                    for (const payment of travel.advancePayments) {
                        this.outcomes.push({
                            paymentType: Payment.PaymentType.TRAVEL,
                            advanceType: payment.paymentType === Payment.PaymentType.TRAVEL ? 'Avans' : 'Avans Kapama',
                            date: payment.date,
                            amount: payment.amount,
                            exchangeRate: payment.exchangeRate,
                            isIncoming: payment.isIncoming,
                            projectName: (travel.project ? travel.project.acronym : 'Proje dahilinde değil'),
                            travelDates: [travel.startDate, travel.endDate],
                            travelDescription: travel.destination + ' ' + travel.description
                        });
                    }
                }
            }
        );
    }

    loadPayrolls(): Promise<any> {
        const query = this.generateQuery(false, false);
        return this.payrollService.getHomePayrolls(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(response => {
                    for (const payroll of response) {
                        for (const item of payroll.payrollLineItems) {
                            this.outcomes.push({
                                paymentType: Payment.PaymentType.SALARY,
                                employee: item.employee,
                                date: payroll.paidDate ? payroll.paidDate : payroll.date,
                                month: this.TRMonths[new Date(payroll.date).getMonth()].month,
                                year: new Date(payroll.date).getFullYear(),
                                amount: item.netSalaryAmount,
                                exchangeRate: payroll.ecbExchangeRate
                            });
                        }
                    }
                }
            );
    }

    loadOutcomePayments() {
        const query = this.generateQuery(false, false);
        this.paymentService.getHomeOutcomePayments(query).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            response => {
                this.outcomes = response;
                const promises = [this.loadOutcomeInvoices(), this.loadInhousePayments(), this.loadTravels(), this.loadPayrolls()];
                Promise.all(promises).then(() => {
                    setTimeout(() => {
                        this.generateData(false);
                    });
                });
            });
    }

    generateQuery(isMonthly: boolean, isIncome?: boolean) {
        let query = {};
        if (isMonthly) {
            if (this.viewOutcome === 'month') {
                query = {monthStart: this.outcomeMonthStart + 1, yearStart: this.outcomeYearStart,
                    monthEnd: this.outcomeMonthEnd + 1, yearEnd: this.outcomeYearEnd};
            } else if (this.viewOutcome === 'year') {
                query = {monthStart: 1, yearStart: this.outcomeYearStart, monthEnd: 12, yearEnd: this.outcomeYearEnd};
            } else {
                const minYear = Math.min.apply(null, this.yearsOfOutcomes);
                const maxYear = Math.max.apply(null, this.yearsOfOutcomes);
                query = {monthStart: 1, yearStart: minYear, monthEnd: 12, yearEnd: maxYear};
            }
        } else {
            const yearStart = isIncome ? this.incomeYearStart : this.outcomeYearStart;
            const yearEnd = isIncome ? this.incomeYearEnd : this.outcomeYearEnd;
            const monthStart = isIncome ? this.incomeMonthStart : this.outcomeMonthStart;
            const monthEnd = isIncome ? this.incomeMonthEnd : this.outcomeMonthEnd;
            if ((!isIncome && this.viewOutcome === 'month') || (isIncome && this.viewIncome === 'month')) {
                query = {start: new Date(yearStart, monthStart, 1),
                    end: new Date(yearEnd, monthEnd + 1, 1)};
            } else if ((!isIncome && this.viewOutcome === 'year') || (isIncome && this.viewIncome === 'year')) { // contains all days in year
                query = {start: new Date(yearStart, 0, 1),
                    end: new Date(yearEnd + 1, 0, 1)};
            }
        }
        return query;
    }

    generateData(isIncome: boolean) {
        const handleEUROtoUSD = (payment) => {
            const date = moment(payment.date).format('YYYY-MM-DD');
            return this.exchangeRateService
                .getRate(date, 'EURO')
                .toPromise()
                .then(response => {
                    const exchangeRate = payment.exchangeRate / response.exchangeRate;
                    return [payment, exchangeRate];
                });
        };
        if (isIncome) { // contains all days in month
            this.clearIncomes();
            const promises = this.incomes.map(payment => {
                if (this.currencyIncome === 'TL' && ['EURO', 'USD'].indexOf(payment.amount.currency) >= 0) {
                    return [payment, payment.exchangeRate];
                } else if (this.currencyIncome === 'EURO' && payment.amount.currency === 'TL') {
                    return [payment, 1 / payment.exchangeRate];
                } else if (this.currencyIncome === 'EURO' && payment.amount.currency === 'USD') {
                    return handleEUROtoUSD(payment);
                }
                return [payment, 1]; // No conversion between currencies needed.
            });
            Promise.all(promises).then(exchangeRates => {
                exchangeRates.forEach(pair => {
                    const payment = pair[0];
                    const exchangeRate = pair[1];
                    const paymentAmount = +(payment.amount.value * exchangeRate).toFixed(2);
                    switch (payment.paymentType) {
                        case Payment.PaymentType.PROJECT_PAYMENT:
                            switch (payment.project.projectType) {
                                case Project.Type.H2020_IA:
                                case Project.Type.H2020_RIA:
                                // TODO ayri sekilde gosterelim horizon projelerini
                                case Project.Type.HORIZON_EUROPE_RIA:
                                case Project.Type.HORIZON_EUROPE_IA:
                                    this.incomePieData[0].amount += paymentAmount;
                                    this.currentIncomePayments.H2020_projects.push(payment);
                                    break;
                                case Project.Type.ITEA:
                                    this.incomePieData[1].amount += paymentAmount;
                                    this.currentIncomePayments.ITEA_projects.push(payment);
                                    break;
                            }
                            break;
                        case Payment.PaymentType.INVOICE:
                            if (payment.project) { // Invoiced Project
                                this.incomePieData[2].amount += paymentAmount;
                                this.currentIncomePayments.local_projects.push(payment);
                            } else {
                                this.incomePieData[3].amount += paymentAmount;
                                this.currentIncomePayments.invoices.push(payment);
                            }
                            break;
                        case Payment.PaymentType.PRIZE:
                            this.incomePieData[4].amount += paymentAmount;
                            this.currentIncomePayments.prizes.push(payment);
                            break;
                        case Payment.PaymentType.REIMBURSEMENT:
                            this.incomePieData[5].amount += paymentAmount;
                            this.currentIncomePayments.reimbursement.push(payment);
                            break;
                        default:
                            if (payment.paymentType !== Payment.PaymentType.TRAVEL_KAPAMA) {
                                this.incomePieData[6].amount += paymentAmount;
                                this.currentIncomePayments.other.push(payment);
                            }
                            break;
                    }
                });
                if (this.currencyIncome === 'TL') {
                    this.incomePieData[4].amount += this.fonIncomeTotal['TL'];
                } else {
                    this.incomePieData[4].amount += this.fonIncomeTotal['EURO'];
                }
                this.incomePieData = this.incomePieData.filter(obj => obj.amount);

                this.requestCameIncome = true;
            });

        } else {
            this.clearOutcomes();
            const promises = this.outcomes.map(payment => {
                if (this.currencyOutcome === 'TL' && ['EURO', 'USD'].indexOf(payment.amount.currency) >= 0) {
                    return [payment, payment.exchangeRate];
                } else if (this.currencyOutcome === 'EURO' && payment.amount.currency === 'TL') {
                    return [payment, 1 / payment.exchangeRate];
                } else if (this.currencyOutcome === 'EURO' && payment.amount.currency === 'USD') {
                    return handleEUROtoUSD(payment);
                }
                return [payment, 1]; // No conversion between currencies needed.
            });
            Promise.all(promises).then(exchangeRates => {
                exchangeRates.forEach(pair => {
                    const payment = pair[0];
                    const exchangeRate = pair[1];
                    const paymentAmount = +(payment.amount.value * exchangeRate).toFixed(2);
                    switch (payment.paymentType) {
                        case Payment.PaymentType.TRAVEL:
                        case Payment.PaymentType.TRAVEL_KAPAMA:
                            if (payment.isIncoming) {
                                this.outcomePieData[0].amount -= paymentAmount;
                            } else {
                                this.outcomePieData[0].amount += paymentAmount;
                            }
                            this.currentOutcomePayments.travels.push(payment);
                            break;
                        case Payment.PaymentType.SALARY:
                            this.outcomePieData[1].amount += paymentAmount;
                            this.currentOutcomePayments.salary.push(payment);
                            break;
                        case Payment.PaymentType.BES:
                            this.outcomePieData[2].amount += paymentAmount;
                            this.currentOutcomePayments.bes.push(payment);
                            break;
                        case Payment.PaymentType.KDV:
                        case Payment.PaymentType.MUHTASAR:
                        case Payment.PaymentType.SGK5510:
                        case Payment.PaymentType.SGK5746:
                        case Payment.PaymentType.GECICI_VERGI:
                        case Payment.PaymentType.KURUMLAR_VERGISI:
                            this.outcomePieData[3].amount += paymentAmount;
                            this.currentOutcomePayments.state.push(payment);
                            break;
                        case 'Inhouse':
                            this.outcomePieData[4].amount += paymentAmount;
                            this.currentOutcomePayments.inhouse.push(payment);
                            break;
                        case 'InvoicePayment':
                            this.outcomePieData[5].amount += paymentAmount;
                            this.currentOutcomePayments.invoices.push(payment);
                            break;
                        default:
                            this.outcomePieData[6].amount += paymentAmount;
                            this.currentOutcomePayments.other.push(payment);
                            break;
                    }
                });
                this.outcomePieData = this.outcomePieData.filter(obj => obj.amount);
                this.requestCameOutcome = true;
            });
        }
    }

    viewIsSet(isIncome, type) { // generate data only if view options are changed
        if (isIncome && type !== this.viewIncome) {
            this.viewIncome = type;
            this.loadIncomes();
        } else if (!isIncome && type !== this.viewOutcome) {
            this.viewOutcome = type;
            this.loadOutcomes();
        }
    }

    getPieChartTitle(isIncome) {
        let result = '';
        if (isIncome) {
            if (this.viewIncome === 'year') {
                result = this.incomeYearStart + '';
                if (this.incomeYearStart !== this.incomeYearEnd) {
                    result += '-' + this.incomeYearEnd;
                }
            } else if (this.viewIncome === 'month') {
                if (this.incomeMonthStart === this.incomeMonthEnd && this.incomeYearStart === this.incomeYearEnd) {
                    result = this.TRMonths[this.incomeMonthStart].month + '/' + this.incomeYearStart;
                } else {
                    result = this.TRMonths[this.incomeMonthStart].month + '/' + this.incomeYearStart + ' - ' + this.TRMonths[this.incomeMonthEnd].month + '/' + this.incomeYearEnd;
                }
            } else {
                result = 'Tüm Zamanlar';
            }
            result += ' Gelir Dağılımı (' + this.currencyIncome + ')';
        } else {
            if (this.viewOutcome === 'year') {
                result = this.outcomeYearStart + '';
                if (this.outcomeYearStart !== this.outcomeYearEnd) {
                    result += '-' + this.outcomeYearEnd;
                }
            } else if (this.viewOutcome === 'month') {
                if (this.outcomeMonthStart === this.outcomeMonthEnd && this.outcomeYearStart === this.outcomeYearEnd) {
                    result = this.TRMonths[this.outcomeMonthStart].month + '/' + this.outcomeYearStart;
                } else {
                    result = this.TRMonths[this.outcomeMonthStart].month + '/' + this.outcomeYearStart + ' - ' + this.TRMonths[this.outcomeMonthEnd].month + '/' + this.outcomeYearEnd;
                }
            } else {
                result = 'Tüm Zamanlar';
            }
            result += ' Gider Dağılımı (' + this.currencyOutcome + ')';
        }
        return result;
    }

    getLineChartLabel(arg) {
        return this.utilsService.formatDouble(arg.value, 2);
    }

    customizePieChartLabel(arg) {
        return this.utilsService.formatDouble(arg.value, 2) + ' (' + (arg.percent * 100).toFixed(2) + '%)';
    }

    customizeTooltip(arg) {
        return {text: this.utilsService.formatDouble(arg.value, 2)};
    }

    incomePointClickHandler(e) {
        this.lineColor = e.target.getColor();
        this.showDetails(true, e.target.data.type);
    }

    outcomePointClickHandler(e) {
        this.lineColor = e.target.getColor();
        this.showDetails(false, e.target.data.type);
    }

    incomeLegendClickHandler(e) {
        for (const type of e.component._legend._data) {
            if (type.argument === e.target) {
                this.lineColor = type.states.normal.fill;
                break;
            }
        }
        this.showDetails(true, e.target);
    }

    outcomeLegendClickHandler(e) {
        for (const type of e.component._legend._data) {
            if (type.argument === e.target) {
                this.lineColor = type.states.normal.fill;
                break;
            }
        }
        this.showDetails(false, e.target);
    }

    getLineColor() {
        return this.lineColor;
    }

    filterData(index, index2) {
        if (index !== -1) {
            this.index = +index;
        }
        if (this.isIncome) {
            switch (this.chartType) {
                case 'H2020 Projeleri':
                case 'ITEA Projeleri':
                case 'Yerli Projeler':
                    if (this.chartType === 'H2020 Projeleri') {
                        this.detailedIncomeData = this.currentIncomePayments.H2020_projects;
                    } else if (this.chartType === 'ITEA Projeleri') {
                        this.detailedIncomeData = this.currentIncomePayments.ITEA_projects;
                    } else {
                        this.detailedIncomeData = this.currentIncomePayments.local_projects;
                    }
                    if (this.index !== 0) { // filter
                        this.detailedIncomeData = this.detailedIncomeData.filter(obj => obj.project.projectId === this.chartDetailingOptionIds[this.index]);
                    }
                    break;
            }
        } else {
            switch (this.chartType) {
                case 'Seyahatlar':
                    this.detailedOutcomeData = this.currentOutcomePayments.travels;
                    if (this.index !== 0) { // filter
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(obj => obj.projectName === this.chartDetailingOptions[this.index]);
                    }
                    break;
                case 'Maaşlar':
                    this.detailedOutcomeData = this.currentOutcomePayments.salary;
                    if (this.index !== 0) { // filter
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(obj => obj.employee.citizenID === this.chartDetailingOptionIds[this.index]);
                    }
                    break;
                case 'BES':
                    this.detailedOutcomeData = this.currentOutcomePayments.bes;
                    if (this.index !== 0) { // filter
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(obj => obj.payeeEmployee.citizenID === this.chartDetailingOptionIds[this.index]);
                    }
                    break;
                case 'Vergi Ödemeleri':
                    this.detailedOutcomeData = this.currentOutcomePayments.state;
                    if (this.index !== 0) { // filter
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(obj => obj.paymentType === this.chartDetailingOptions[this.index]);
                    }
                    break;
                case 'Şirket İçi Harcamalar':
                    this.detailedOutcomeData = this.currentOutcomePayments.inhouse;
                    if (this.index !== 0) { // filter
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(obj => obj.payerEmployee.citizenID === this.chartDetailingOptionIds[this.index]);
                    }
                    break;
                case 'Faturalar':
                    this.detailedOutcomeData = this.currentOutcomePayments.invoices;
                    if (this.index !== 0) { // filter
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(obj => obj.type === this.chartDetailingOptions[this.index]);
                    }
                    break;
            }
        }
        this.plot();
    }

    showDetails(isIncome, type) {
        // Non-admin users should not see details.
        if (this.authorizationService.currentUserType !== Employee.UserType.ADMIN) {
            AlertUtil.showWarning('Detayları görmek için yetkiniz bulunmamaktadır.', 'Authorization Error');
            return;
        }
        this.isOptionable = false;
        this.isIncome = isIncome;
        this.chartType = type;
        if (this.isIncome) {
            switch (this.chartType) {
                case 'H2020 Projeleri':
                case 'ITEA Projeleri':
                case 'Yerli Projeler':
                    if (this.chartType === 'H2020 Projeleri') {
                        this.detailedIncomeData = this.currentIncomePayments.H2020_projects;
                    } else if (this.chartType === 'ITEA Projeleri') {
                        this.detailedIncomeData = this.currentIncomePayments.ITEA_projects;
                    } else {
                        this.detailedIncomeData = this.currentIncomePayments.local_projects;
                    }
                    this.selectedOption = 'Tüm Projeler';
                    this.chartDetailingOptions = ['Tüm Projeler'];
                    this.chartDetailingOptionIds = ['Tüm Projeler'];
                    for (const payment of this.detailedIncomeData) {
                        if (!this.chartDetailingOptionIds.includes(payment.project.projectId)) {
                            this.chartDetailingOptionIds.push(payment.project.projectId);
                            this.chartDetailingOptions.push(payment.project.acronym);
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Faturalar':
                    this.detailedIncomeData = this.currentIncomePayments.invoices;
                    break;
                case 'Ödüller':
                    this.detailedIncomeData = this.currentIncomePayments.prizes;
                    break;
                case 'Geri Ödemeler':
                    this.detailedIncomeData = this.currentIncomePayments.reimbursement;
                    break;
                case 'Diğer':
                    this.detailedIncomeData = this.currentIncomePayments.other;
                    break;
            }
        } else {
            switch (this.chartType) {
                case 'Seyahatlar':
                    this.detailedOutcomeData = this.currentOutcomePayments.travels;
                    this.selectedOption = 'Tüm Projeler';
                    this.chartDetailingOptions = ['Tüm Projeler'];
                    for (const payment of this.detailedOutcomeData) {
                        if (!this.chartDetailingOptions.includes(payment.projectName)) {
                            this.chartDetailingOptions.push(payment.projectName);
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Maaşlar':
                    this.detailedOutcomeData = this.currentOutcomePayments.salary;
                    this.selectedOption = 'Tüm Çalışanlar';
                    this.chartDetailingOptions = ['Tüm Çalışanlar'];
                    this.chartDetailingOptionIds = ['Tüm Çalışanlar'];
                    for (const payment of this.detailedOutcomeData) {
                        if (!this.chartDetailingOptionIds.includes(payment.employee.citizenID)) {
                            this.chartDetailingOptionIds.push(payment.employee.citizenID);
                            this.chartDetailingOptions.push(this.utilsService.getPersonFullName(payment.employee.person));
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'BES':
                    this.detailedOutcomeData = this.currentOutcomePayments.bes;
                    break;
                case 'Vergi Ödemeleri':
                    this.detailedOutcomeData = this.currentOutcomePayments.state;
                    this.selectedOption = 'Tüm Ödemeler';
                    this.chartDetailingOptions = ['Tüm Ödemeler'];
                    for (const payment of this.detailedOutcomeData) {
                        if (!this.chartDetailingOptions.includes(payment.paymentType)) {
                            this.chartDetailingOptions.push(payment.paymentType);
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Şirket İçi Harcamalar':
                    this.detailedOutcomeData = this.currentOutcomePayments.inhouse;
                    this.selectedOption = 'Tüm Çalışanlar';
                    this.chartDetailingOptions = ['Tüm Çalışanlar'];
                    this.chartDetailingOptionIds = ['Tüm Çalışanlar'];
                    for (const payment of this.detailedOutcomeData) {
                        if (!this.chartDetailingOptionIds.includes(payment.payerEmployee.citizenID)) {
                            this.chartDetailingOptionIds.push(payment.payerEmployee.citizenID);
                            this.chartDetailingOptions.push(this.utilsService.getPersonFullName(payment.payerEmployee.person));
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Faturalar':
                    this.detailedOutcomeData = this.currentOutcomePayments.invoices;
                    this.selectedOption = 'Tüm Faturalar';
                    this.chartDetailingOptions = ['Tüm Faturalar'];
                    for (const payment of this.detailedOutcomeData) {
                        if (!this.chartDetailingOptions.includes(payment.type)) {
                            this.chartDetailingOptions.push(payment.type);
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Diğer':
                    this.detailedOutcomeData = this.currentOutcomePayments.other;
                    break;
            }
        }
        this.sortOptions();
        this.plot();
    }

    plot() {
        if (this.isIncome) {
            if (this.viewIncome === 'month') { // show monthly data as list
                this.detailModal = 'income';
                this.incomeOutcomeDetailModal.show();
            } else if (this.viewIncome === 'year') { // plot line chart month by month for this yearly data
                this.clearMonthlyLineChart(true);
                for (const payment of this.detailedIncomeData) {
                    let paymentAmount = payment.amount.value;
                    if (this.currencyIncome === 'TL' && payment.amount.currency !== 'TL') {
                        paymentAmount = paymentAmount * payment.exchangeRate;
                    } else if (this.currencyIncome !== 'TL' && payment.amount.currency === 'TL') {
                        paymentAmount = paymentAmount / payment.exchangeRate;
                    }
                    const yearIndex = new Date(payment.date).getFullYear() - this.incomeYearStart;
                    const line_index = yearIndex * 12 + new Date(payment.date).getMonth();
                    if (line_index >= 0 && line_index < this.monthlyLineChart.length) {
                        this.monthlyLineChart[yearIndex * 12 + new Date(payment.date).getMonth()].amount += +paymentAmount.toFixed(2);
                    }
                }
                this.lineChartData = this.monthlyLineChart;
                this.lineChartHeader = (this.incomeYearStart === this.incomeYearEnd ? this.incomeYearStart : this.incomeYearStart + '-' + this.incomeYearEnd) + ' ' + this.chartType + ' Gelir Grafiği';
                this.lineChartName = 'MEBLAĞ (' + this.currencyIncome + ')';
                this.lineChartModal.show();
            } else { // plot line chart year by year for all times data
                this.yearlyLineChart = [];
                for (const year of this.yearsOfIncomes) {
                    this.yearlyLineChart.push({date: year + '', amount: 0});
                }
                for (const payment of this.detailedIncomeData) {
                    let paymentAmount = payment.amount.value;
                    if (this.currencyIncome === 'TL' && payment.amount.currency !== 'TL') {
                        paymentAmount = paymentAmount * payment.exchangeRate;
                    } else if (this.currencyIncome !== 'TL' && payment.amount.currency === 'TL') {
                        paymentAmount = paymentAmount / payment.exchangeRate;
                    }
                    this.yearlyLineChart.map((todo, i) => {
                        if (todo.date === (new Date(payment.date).getFullYear() + '')) {
                            this.yearlyLineChart[i].amount += +paymentAmount.toFixed(2);
                        }
                    });
                }
                this.lineChartData = this.yearlyLineChart;
                this.lineChartHeader = 'Tüm Zamanlar ' + this.chartType + ' Gelir Grafiği';
                this.lineChartName = 'MEBLAĞ (' + this.currencyIncome + ')';
                this.lineChartModal.show();
            }
        } else {
            if (this.viewOutcome === 'month') { // show monthly data as list
                this.detailModal = 'outcome';
                this.incomeOutcomeDetailModal.show();
            } else if (this.viewOutcome === 'year') { // plot line chart month by month for this yearly data
                this.clearMonthlyLineChart(false);
                for (const payment of this.detailedOutcomeData) {
                    let paymentAmount = payment.amount.value;
                    if (this.currencyOutcome === 'TL' && payment.amount.currency !== 'TL') {
                        paymentAmount = paymentAmount * payment.exchangeRate;
                    } else if (this.currencyOutcome !== 'TL' && payment.amount.currency === 'TL') {
                        paymentAmount = paymentAmount / payment.exchangeRate;
                    }
                    const yearIndex = new Date(payment.date).getFullYear() - this.outcomeYearStart;
                    const line_index = yearIndex * 12 + new Date(payment.date).getMonth();
                    if (line_index >= 0 && line_index < this.monthlyLineChart.length) {
                        this.monthlyLineChart[yearIndex * 12 + new Date(payment.date).getMonth()].amount += +paymentAmount.toFixed(2);
                    }
                }
                this.lineChartData = this.monthlyLineChart;
                this.lineChartHeader = (this.outcomeYearStart === this.outcomeYearEnd ? this.outcomeYearStart : this.outcomeYearStart + '-' + this.outcomeYearEnd) + ' ' + this.chartType + ' Gider Grafiği';
                this.lineChartName = 'MEBLAĞ (' + this.currencyOutcome + ')';
                this.lineChartModal.show();
            } else { // plot line chart year by year for all times data
                this.yearlyLineChart = [];
                for (const year of this.yearsOfOutcomes) {
                    this.yearlyLineChart.push({date: year + '', amount: 0});
                }
                for (const payment of this.detailedOutcomeData) {
                    let paymentAmount = payment.amount.value;
                    if (this.currencyOutcome === 'TL' && payment.amount.currency !== 'TL') {
                        paymentAmount = paymentAmount * payment.exchangeRate;
                    } else if (this.currencyOutcome !== 'TL' && payment.amount.currency === 'TL') {
                        paymentAmount = paymentAmount / payment.exchangeRate;
                    }
                    this.yearlyLineChart.map((todo, i) => {
                        if (todo.date === (new Date(payment.date).getFullYear() + '')) {
                            this.yearlyLineChart[i].amount += +paymentAmount.toFixed(2);
                        }
                    });
                }
                this.lineChartData = this.yearlyLineChart;
                this.lineChartHeader = 'Tüm Zamanlar ' + this.chartType + ' Gider Grafiği';
                this.lineChartName = 'MEBLAĞ (' + this.currencyOutcome + ')';
                this.lineChartModal.show();
            }
        }
    }

    getDescription(item: any): string {
        switch (item.paymentType) {
            case 'Invoice':
            case 'InvoicePayment':
            case 'Inhouse':
                return item.invoiceType;
            case 'Project Payment':
                return item.project.acronym + ' Ödemesi';
            case 'Prize':
                return (item.project ? item.project.acronym + ' - ' : '') + this.utilsService.getPersonFullName(item.payeeEmployee.person);
            case 'Reimbursement':
                return item.project ? item.project.acronym : 'Proje dahilinde değil';
            case 'Other':
                return item.payerEmployee ? this.utilsService.getPersonFullName(item.payerEmployee.person) : item.payerOrganization.name;
            case 'Travel':
                return item.projectName ? item.projectName +  ' ' + item.travelDescription : item.travelDescription;
            case 'Salary':
                return this.utilsService.getPersonFullName(item.employee.person);
            case 'BES':
                return 'Bireysel Emeklilik';
            case Payment.PaymentType.KDV:
            case Payment.PaymentType.MUHTASAR:
            case Payment.PaymentType.SGK5510:
            case Payment.PaymentType.SGK5746:
            case Payment.PaymentType.GECICI_VERGI:
            case Payment.PaymentType.KURUMLAR_VERGISI:
                return item.paymentType;
        }
    }

    getFirstColumn(paymentType: string): string {
        switch (paymentType) {
            case 'Invoice':
            case 'InvoicePayment':
                return 'Fatura';
            case 'Inhouse':
                return 'Harcama';
            case 'Project Payment':
                return 'Proje';
            case 'Prize':
                return 'Ödül';
            case 'Reimbursement':
                return 'Seyahat';
            case 'Other':
                return 'Ödeyen';
            case 'Travel':
                return 'Açıklama';
            case 'Salary':
                return 'Çalışan';
            case 'BES':
                return 'Tür';
            case Payment.PaymentType.KDV:
            case Payment.PaymentType.MUHTASAR:
            case Payment.PaymentType.SGK5510:
            case Payment.PaymentType.SGK5746:
            case Payment.PaymentType.GECICI_VERGI:
            case Payment.PaymentType.KURUMLAR_VERGISI:
                return 'Vergi';
        }
    }

    getTotal(isIncome: boolean) {
        let total: number;
        if (isIncome) {
            total = this.incomePieData.reduce((a, b) => a + b.amount, 0);
        } else {
            total = this.outcomePieData.reduce((a, b) => a + b.amount, 0);
        }
        return 'Toplam: ' + this.utilsService.formatDouble(total, 2);
    }

    clearIncomes() {
        this.incomePieData = [{
            type: 'H2020 Projeleri',
            amount: 0
        }, {
            type: 'ITEA Projeleri',
            amount: 0
        }, {
            type: 'Yerli Projeler',
            amount: 0
        }, {
            type: 'Faturalar',
            amount: 0
        }, {
            type: 'Fon',
            amount: 0
        }, {
            type: 'Geri Ödemeler',
            amount: 0
        }, {
            type: 'Diğer',
            amount: 0
        }];
        this.currentIncomePayments = {
            H2020_projects: [],
            ITEA_projects: [],
            local_projects: [],
            invoices: [],
            prizes: [],
            reimbursement: [],
            other: []
        };
    }

    clearOutcomes() {
        this.outcomePieData = [{
            type: 'Seyahatlar',
            amount: 0
        }, {
            type: 'Maaşlar',
            amount: 0
        }, {
            type: 'BES',
            amount: 0
        }, {
            type: 'Vergi Ödemeleri',
            amount: 0
        }, {
            type: 'Şirket İçi Harcamalar',
            amount: 0
        }, {
            type: 'Faturalar',
            amount: 0
        }, {
            type: 'Diğer',
            amount: 0
        }];
        this.currentOutcomePayments = {
            travels: [],
            salary: [],
            bes: [],
            state: [],
            inhouse: [],
            invoices: [],
            other: []
        };
    }

    clearMonthlyLineChart(isIncome?: boolean) {
        this.monthlyLineChart = [];
        if (isIncome === undefined) {
            this.addYearToMonthlyChart();
        } else {
            if (isIncome) {
                for (let i = this.incomeYearStart; i <= this.incomeYearEnd; i++) {
                    this.addYearToMonthlyChart(this.incomeYearStart === this.incomeYearEnd ? null : i);
                }
                if (new Date().getFullYear() === this.incomeYearEnd) {
                    const years = this.incomeYearEnd - this.incomeYearStart;
                    this.monthlyLineChart.splice(years * 12 + new Date().getMonth() + 1, 11 - new Date().getMonth()); // to show only the months up today
                }
            } else {
                for (let i = this.outcomeYearStart; i <= this.outcomeYearEnd; i++) {
                    this.addYearToMonthlyChart(this.outcomeYearStart === this.outcomeYearEnd ? null : i);
                }
                if (new Date().getFullYear() === this.outcomeYearEnd) {
                    const years = this.outcomeYearEnd - this.outcomeYearStart;
                    this.monthlyLineChart.splice(years * 12 + new Date().getMonth() + 1, 11 - new Date().getMonth()); // to show only the months up today
                }
            }
        }
    }

    addYearToMonthlyChart(year?: number) {
        const yearText = year ? ' ' + year : '';
        this.monthlyLineChart.push({
            date: 'Ocak' + yearText,
            amount: 0,
        }, {
            date: 'Şubat' + yearText,
            amount: 0,
        }, {
            date: 'Mart' + yearText,
            amount: 0,
        }, {
            date: 'Nisan' + yearText,
            amount: 0,
        }, {
            date: 'Mayıs' + yearText,
            amount: 0,
        }, {
            date: 'Haziran' + yearText,
            amount: 0,
        }, {
            date: 'Temmuz' + yearText,
            amount: 0,
        }, {
            date: 'Ağustos' + yearText,
            amount: 0,
        }, {
            date: 'Eylül' + yearText,
            amount: 0,
        }, {
            date: 'Ekim' + yearText,
            amount: 0,
        }, {
            date: 'Kasım' + yearText,
            amount: 0,
        }, {
            date: 'Aralık' + yearText,
            amount: 0,
        });
    }

    sortOptions() {
        const list = [];
        for (let j = 0; j < this.chartDetailingOptions.length; j++) {
            list.push({option: this.chartDetailingOptions[j], id: this.chartDetailingOptionIds[j]});
        }
        list.sort(function (a, b) {
            if (['Tüm Projeler', 'Tüm Çalışanlar', 'Tüm Ödemeler', 'Tüm Faturalar'].includes(a.option)
                || b.option === 'Proje dahilinde değil' || b.option === 'Diğer') {
                return -1;
            } else if (['Tüm Projeler', 'Tüm Çalışanlar', 'Tüm Ödemeler', 'Tüm Faturalar'].includes(b.option)
                || a.option === 'Proje dahilinde değil' || a.option === 'Diğer') {
                return 1;
            }
            return a.option.localeCompare(b.option);
        });
        for (let k = 0; k < list.length; k++) {
            this.chartDetailingOptions[k] = list[k].option;
            this.chartDetailingOptionIds[k] = list[k].id;
        }
    }

}
