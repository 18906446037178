import {MomentDateAdapter} from '@angular/material-moment-adapter';

/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends MomentDateAdapter {

    getFirstDayOfWeek(): number {
        return 1;
    }

}
