import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EmployeeService} from '../shared/services/employee.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ModalDirective} from '../shared/directives/modal.directive';
import {AuthenticationService} from '../shared/services/authentication.service';
import {first, takeUntil} from 'rxjs/operators';
import {TimeSeriesChartData} from '../bank-account-management/timeSeriesChartData';
import * as moment from 'moment';
import {TimeSeries} from '../../models/timeSeries';
import {MenuControllerService} from '../shared/services/menu-controller.service';
import {UtilsService} from '../shared/services/utils.service';
import {Subject} from 'rxjs';
import {AlertUtil} from '../util/AlertUtil';
import {Employee} from '../../models/employee';
import {BankAccount} from '../../models/bankAccount';
import { HotTableComponent } from 'ng2-handsontable';
import {Course} from '../../models/course';
import * as Handsontable from 'handsontable';
import {WorkDay} from '../../models/workDay';
import {ComponentUtil} from '../util/ComponentUtil';
import {VacationDateFilterPipe} from '../shared/pipes/vacation-date-filter.pipe';
import {Paginator} from '../shared/paginator';
import {PaginatorBuilder} from '../shared/paginator-builder';
import {EnvironmentVariableService} from '../shared/services/environment-variable.service';
import {Environment} from '../../models/environmentVariables';

@Component({
    selector: 'app-profile-page',
    templateUrl: './profile-page.component.html',
    styleUrls: ['./profile-page.component.css'],
    providers: [ VacationDateFilterPipe ]
})

export class ProfilePageComponent implements OnInit, OnDestroy {
    @ViewChild('changePasswordModal') changePasswordModal: ModalDirective;
    @ViewChild('updateFileModal') updateFileModal: ModalDirective;
    @ViewChild('deleteFileModal') deleteFileModal: ModalDirective;
    @ViewChild('showTimeSeriesModal') showTimeSeriesModal: ModalDirective;
    @ViewChild('deleteBankAccountModal') deleteBankAccountModal: ModalDirective;
    @ViewChild('deleteTimeSeriesModal') deleteTimeSeriesModal: ModalDirective;

    @ViewChild('editTimeSeriesModal') editTimeSeriesModal: ModalDirective;
    @ViewChild(HotTableComponent) private hotTableComponent;

    dataSource: TimeSeriesChartData[] = [];
    chartName = 'MEBLAĞ (';
    user: Employee;
    CV_url: SafeResourceUrl = null;
    SSO_url: SafeResourceUrl = null;
    contract_urls: SafeResourceUrl[] = null;
    wrongInput = false;
    wrongPassword = false;
    differentPassword = false;
    wrongCurrentPassword = false;
    wrongBankAccount = false;
    wrongTimeSeries = false;
    editing = false;

    currentUserName: string;
    password = {current: null, new: null, verify: null};
    iban = '';
    citizenID = '';
    bankAccount: BankAccount;
    timeSeries: TimeSeries;
    requestedTimeSeries: TimeSeries[];
    currentTimeSeries: TimeSeries;
    isNewAccount = true;
    isNewTimeSeries = true;
    fileType = '';
    CV: Blob = null;
    SSOEmp: Blob = null;
    newContract = null;
    isCVSelected = false;
    isSSOEmpSelected = false;
    isNewContractSelected = false;
    bankAccountsArrived = false;
    timeSeriesRequestCame = false;

    contractIndexToDelete = -1;
    _destroyed$ = new Subject();
    selectedYear = new Date().getFullYear();
    years;

    paginator: Paginator;
    paginatorTimeSeries: Paginator;
    pageSizeOptionsArray = [5, 10, 25];

    clicked = false;
    excelData: any[];
    dataArray: any[];
    colHeaders: string[];
    columns: any[];
    options;
    educationTerm = '';
    environmentVariablesInDB: Environment;

    constructor(private employeeService: EmployeeService, private sanitizer: DomSanitizer,
                private authenticationService: AuthenticationService, public menuControllerService: MenuControllerService,
                public utilsService: UtilsService, private environmentVariableService: EnvironmentVariableService) {
        this.menuControllerService.setProfileMenu();
        this.customizeTooltip = this.customizeTooltip.bind(this);
        this.requestedTimeSeries = [];
    }

    initPaginator() {
        this.paginator = new PaginatorBuilder()
            .withPageSizeOptions(this.pageSizeOptionsArray)
            .withSelectedPageSize(5)
            .withRefreshFunction(() => this.getBankAccounts())
            .withResizeFunction(() => this.getBankAccountsSize())
            .build();
        this.getBankAccountsSize();
    }

    init_paginatorTimeSeries() {
        this.paginatorTimeSeries = new PaginatorBuilder()
            .withPageSizeOptions(this.pageSizeOptionsArray)
            .withSelectedPageSize(5)
            .withRefreshFunction(() => this.getTimeSeriesByAccID(this.bankAccount))
            .withResizeFunction(() => this.getTimeSeriesSizeByAccID(this.bankAccount))
            .build();
        this.getTimeSeriesSizeByAccID(this.bankAccount);
    }

    ngOnInit() {
        this.clearBankAccount();
        this.getEnvironmentVariables().then(r => {
            this.loadUser();
            const today = new Date();
            if (today.getMonth() >= 7 || today.getMonth() === 0) {
                this.educationTerm = (today.getFullYear() - 1) + '-' + today.getFullYear() + ' Sonbahar Dönemi';
            } else {
                this.educationTerm = today.getFullYear() + '-' + (today.getFullYear() + 1) + ' Bahar Dönemi';
            }
            this.init_paginatorTimeSeries();
        });
    }

    ngOnDestroy(): void {
        this.menuControllerService.resetSidebarItems();
        this._destroyed$.next();
    }

    getEnvironmentVariables() {
        return this.environmentVariableService.getEnvVariables()
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(response => {
                this.environmentVariablesInDB = response;
                this.years = Array.from( // All the years between database's start year and current year
                    Array(this.selectedYear - this.environmentVariablesInDB.dbStartingYear + 1),
                    (x, i) => this.environmentVariablesInDB.dbStartingYear + i
                ).reverse();
            });
    }

    getBankAccounts() {
        this.bankAccountsArrived = false;
        const query = {};
        this.paginator.addPagination(query);
        this.paginator.addFilterQueries(query);

        return this.employeeService.getBankAccounts(this.user.citizenID, query)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(response => {
                this.user.bankAccounts = <BankAccount[]>response;
                this.bankAccountsArrived = true;
            }, error => {
                this.bankAccountsArrived = true;
            });
    }

    getBankAccountsSize() {
        const query = {};
        this.paginator.addFilterQueries(query);
        return this.employeeService.getBankAccountsSize(this.user.citizenID)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(response => this.paginator.onTotalSizeChange(response['size']));
    }

    getTimeSeriesByAccID(account: BankAccount) {
        if (account === undefined || (account._id === undefined && !this.timeSeriesRequestCame)) {return; }

        this.timeSeriesRequestCame = false;
        const query = {};
        this.paginatorTimeSeries.addPagination(query);
        this.paginatorTimeSeries.addFilterQueries(query);

        return this.employeeService.getTimeSeriesByAccID(this.user.citizenID, account._id, query)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(response => {
                this.requestedTimeSeries = response;
                this.requestedTimeSeries.sort(this.utilsService.sortByDescendingDate.bind(this.utilsService));
                this.timeSeriesRequestCame = true;
            }, error => {
                this.requestedTimeSeries = [];
                this.timeSeriesRequestCame = true;
            });
    }

    getTimeSeriesSizeByAccID(account: BankAccount) {
        if (account._id === undefined) {
            return;
        }
        const query = {};
        this.paginatorTimeSeries.addFilterQueries(query);
        return this.employeeService.getTimeSeriesSizeByAccID(this.user.citizenID, account._id, query)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(response => {
                this.paginatorTimeSeries.onTotalSizeChange(response.size);
            });
    }

    loadUser() {
        this.employeeService.getCurrentUser().pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            response => {
                this.user = response;
                this.citizenID = this.user.citizenID;

                // handle file urls
                if (this.user.CV) {
                    const cv_url = new Buffer(this.user.CV);
                    const not_san = cv_url.toString();
                    this.CV_url = this.sanitizer.bypassSecurityTrustResourceUrl(not_san);
                }

                if (this.user.SSOEmploymentDocument) {
                    const sso_url = new Buffer(this.user.SSOEmploymentDocument);
                    const not_san = sso_url.toString();
                    this.SSO_url = this.sanitizer.bypassSecurityTrustResourceUrl(not_san);
                }

                if (this.user.contracts) {
                    this.contract_urls = [];
                    for (let i = 0; i < this.user.contracts.length; i++) {
                        const cont_url = new Buffer(this.user.contracts[i]);
                        const not_san = cont_url.toString();
                        this.contract_urls.push(this.sanitizer.bypassSecurityTrustResourceUrl(not_san));
                    }
                }
                this.initializeCourseSchedule();
                this.initPaginator();
            }
        );
    }

    showChangePasswordModal() {
        this.password = {current: null, new: null, verify: null};
        this.wrongPassword = false;
        this.differentPassword = false;
        this.wrongCurrentPassword = false;
        this.changePasswordModal.show();
        this.clicked = false;
    }

    showEditProfileModal() {
        this.employeeService.getCurrentUser().pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            response => {
                this.wrongInput = false;
                this.user = response;
                this.citizenID = this.user.citizenID;
                this.menuControllerService.profilePageView = 'inputView';
                this.clicked = false;
            }, error => this.clicked = false
        );
    }

    updateUser() {
        if (!this.user.person.firstName ||
            !this.user.person.lastName ||
            !this.user.citizenID ||
            !this.user.socialSecurityID ||
            !this.user.birthDate ||
            !this.user.dateOfEmployment_formal ||
            !this.user.dateOfEmployment_informal ||
            !this.user.person.contact.mobileNumber ||
            !this.user.person.contact.personalEmail ||
            !this.user.person.contact.workEmail
        ) {
            this.wrongInput = true;
            AlertUtil.showError('Gerekli alanları doldurunuz!');
        } else {
            this.removeFilesFromUser();
            this.employeeService.updateEmployee(this.user, this.citizenID).pipe(
                takeUntil(this._destroyed$)
            ).subscribe(
                response => {
                    this.user = response['employee'];
                    this.citizenID = this.user.citizenID;
                    localStorage.removeItem('currentUserName');
                    this.menuControllerService.profilePageView = 'overview';
                    this.clicked = false;
                }, error => this.clicked = false
            );
        }
    }

    updatePassword() {
        if (!this.password.current ||
            !this.password.new ||
            !this.password.verify
        ) {
            this.wrongPassword = true;
            this.differentPassword = false;
            this.wrongCurrentPassword = false;
            AlertUtil.showError('Gerekli alanları doldurunuz!');
        } else if (this.password.new !== this.password.verify) {
            this.wrongPassword = false;
            this.differentPassword = true;
            this.wrongCurrentPassword = false;
            AlertUtil.showError('Yeni şifrenizi onaylayamadınız!');
        } else {
            this.wrongPassword = false;
            this.differentPassword = false;
            this.authenticationService.verifyPassword(localStorage.getItem('currentUserID'), this.password.current)
                .pipe(first())
                .subscribe(
                    data => {
                        this.wrongCurrentPassword = false;
                        this.employeeService.getCurrentUser().pipe(
                            takeUntil(this._destroyed$)
                        ).subscribe(
                            response => {
                                this.user = response;
                                this.removeFilesFromUser();
                                this.user.password = this.password.new;
                                this.employeeService.updateEmployee(this.user, this.user.citizenID).pipe(
                                    takeUntil(this._destroyed$)
                                ).subscribe(
                                    resp => {
                                        this.user = resp['employee'];
                                        AlertUtil.showSuccess('Şifre başarıyla değiştirildi', 'Şifre Yenileme');
                                        this.changePasswordModal.close();
                                        this.clicked = false;
                                    }, error => this.clicked = false
                                );
                            }
                        );
                    },
                    error => {
                        this.wrongCurrentPassword = true;
                        AlertUtil.showError('Mevcut şifrenizi yanlış girdiniz!');
                    });
        }
    }

    updateFile(type) {
        this.fileType = type;
        this.clearFiles();
        this.updateFileModal.show();
        this.clicked = false;
    }

    deleteFileModalF(type) {
        this.fileType = type;
        this.deleteFileModal.show();
        this.clicked = false;
    }

    removeContract(index) {
        this.fileType = 'contracts';
        this.contractIndexToDelete = index;
        this.deleteFileModal.show();
        this.clicked = false;
    }

    deleteFile() {
        switch (this.fileType) {
            case 'CV':
                this.removeFilesFromUser();
                this.user.CV = null;
                this.employeeService.updateEmployee(this.user, this.citizenID).pipe(
                    takeUntil(this._destroyed$)
                ).subscribe(
                    response => {
                        this.user = response['employee'];
                        this.citizenID = this.user.citizenID;
                        this.CV_url = null;
                        this.deleteFileModal.close();
                        this.clicked = false;
                    }, error => this.clicked = false
                );
                break;
            case 'SSOEmp':
                this.removeFilesFromUser();
                this.user.SSOEmploymentDocument = null;
                this.employeeService.updateEmployee(this.user, this.citizenID).pipe(
                    takeUntil(this._destroyed$)
                ).subscribe(
                    response => {
                        this.user = response['employee'];
                        this.citizenID = this.user.citizenID;
                        this.SSO_url = null;
                        this.deleteFileModal.close();
                        this.clicked = false;
                    }, error => this.clicked = false
                );
                break;
            case 'contracts':
                this.user.contracts.splice(this.contractIndexToDelete, 1);
                const old_contracts = this.user.contracts;
                this.removeFilesFromUser();
                this.user.contracts = [];
                for (const item of old_contracts) {
                    const cont_url_buf = new Buffer(item);
                    this.user.contracts.push(cont_url_buf);
                }
                this.employeeService.updateEmployee(this.user, this.citizenID).pipe(
                    takeUntil(this._destroyed$)
                ).subscribe(
                    response => {
                        this.user = response['employee'];
                        this.citizenID = this.user.citizenID;
                        this.contract_urls.splice(this.contractIndexToDelete, 1);
                        this.deleteFileModal.close();
                        this.clicked = false;
                    }, error => this.clicked = false
                );
        }
    }

    private getTimeSeries(account: BankAccount) {
        moment.locale('tr');
        this.dataSource = [];
        this.chartName = 'MEBLAĞ (';
        const query = {'preventPagination': true};
        // this.paginatorTimeSeries.addFilterQueries(query);
        return this.employeeService.getTimeSeriesByAccID(this.user.citizenID, account._id, query)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(response => {
                this.requestedTimeSeries = response;
                for (const i in this.requestedTimeSeries) {
                    const obj = {
                        amount: this.requestedTimeSeries[i].amount.value,
                        date: moment(this.requestedTimeSeries[i].date).format('LL').toString()
                    };
                    this.dataSource.push(obj);
                    this.chartName += account.currency + ')';
                }
            });
    }

    showTimeSeriesModalF(account: BankAccount) {
        this.iban = account.iban;
        this.getTimeSeries(account);
        this.showTimeSeriesModal.show();
        this.clicked = false;
    }

    addTimeSeriesModalF() {
        this.clearTimeSeries();
        this.editTimeSeriesModal.show();
        this.clicked = false;
    }

    addBankAccount() {
        // submit
        this.editing = false;

        if (!this.bankAccount.iban ||
            !this.bankAccount.bank ||
            !this.bankAccount.branch ||
            !this.bankAccount.type ||
            !this.bankAccount.accountNo
        ) {
            this.clicked = false;
            this.wrongBankAccount = true;
            AlertUtil.showError('Gerekli alanları doldurunuz!');
        } else {
            this.removeFilesFromUser();
            // this.user.bankAccounts.push(this.bankAccount); Disabled due to paging
            this.bankAccount.accountBalanceTimeSeries = this.requestedTimeSeries;
            this.employeeService.addBankAccount(this.bankAccount, this.citizenID).pipe(
                takeUntil(this._destroyed$)
            ).subscribe(
                response => {
                    this.user = response['employee'];
                    this.citizenID = this.user.citizenID;
                    this.menuControllerService.profilePageView = 'overview';
                    this.initPaginator();
                    this.clicked = false;
                }, error => this.clicked = false
            );
        }
    }

    goBackButton() {
        this.menuControllerService.profilePageView = 'overview';
        this.editing = false;
    }

    updateBankAccount() {
        this.editing = false;

        if (!this.bankAccount.iban ||
            !this.bankAccount.bank ||
            !this.bankAccount.branch ||
            !this.bankAccount.type ||
            !this.bankAccount.accountNo
        ) {
            this.clicked = false;
            this.wrongBankAccount = true;
            AlertUtil.showError('Gerekli alanları doldurunuz!');
        } else {
            this.user.bankAccounts.map((todo, i) => {
                if (todo._id === this.bankAccount._id) {
                    this.removeFilesFromUser();
                    this.user.bankAccounts[i] = this.bankAccount;
                    this.employeeService.updateBankAccount(this.bankAccount, this.citizenID).pipe(
                        takeUntil(this._destroyed$)
                    ).subscribe(
                        response => {
                            this.user = response['employee'];
                            this.citizenID = this.user.citizenID;
                            this.menuControllerService.profilePageView = 'overview';
                            this.clicked = false;
                        }, error => this.clicked = false
                    );
                }
            });
        }
    }

    addNewBankAccount() {
        this.clearBankAccount();
        this.requestedTimeSeries = new Array;
        this.timeSeriesRequestCame = false;
        this.editing = true;
        this.menuControllerService.profilePageView = 'bankInputView';
        this.clicked = false;
    }

    bankAccountModalF(acc: BankAccount) {
        this.iban = acc.iban;
        this.clearBankAccount();
        this.isNewAccount = false;
        this.user.bankAccounts.map((todo, i) => {
            if (todo.iban === this.iban) {
                this.bankAccount = UtilsService.deepCopy(this.user.bankAccounts[i]);
                this.menuControllerService.profilePageView = 'bankInputView';
                this.init_paginatorTimeSeries();
                this.clicked = false;
            }
        });
    }

    deleteBankAccountModalF(iban: string) {
        this.iban = iban;
        this.deleteBankAccountModal.show();
        this.clicked = false;
    }

    deleteBankAccount() {
        this.user.bankAccounts.map((todo, i) => {
            if (todo.iban === this.iban) {
                this.removeFilesFromUser();
                this.user.bankAccounts.splice(i, 1);
                this.employeeService.deleteBankAccount(this.iban, this.citizenID).pipe(
                    takeUntil(this._destroyed$)
                ).subscribe(
                    response => {
                        this.user = response['employee'];
                        this.citizenID = this.user.citizenID;
                        this.initPaginator();
                        this.deleteBankAccountModal.close();
                        this.clicked = false;
                    }, error => this.clicked = false
                );
            }
        });
    }

    removeTimeSeriesF(ts: TimeSeries) {
        this.deleteTimeSeriesModal.show();
        this.timeSeries = ts;
        this.clicked = false;
    }

    removeTimeSeries() {
        const query = {'bankID': this.bankAccount._id};
        this.employeeService.deleteTimeSeries(this.citizenID, this.timeSeries, query).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(response => {
            this.deleteTimeSeriesModal.close();
            this.clicked = false;
            this.utilsService.removeFromArray(this.requestedTimeSeries, this.timeSeries);
            this.init_paginatorTimeSeries();
        }, error => {});
    }

    timeSeriesToAccount() {
        if (!this.timeSeries.amount.value ||
            !this.timeSeries.date
        ) {
            this.wrongTimeSeries = true;
            AlertUtil.showError('Gerekli alanları doldurunuz!');
        } else {
            this.timeSeries.amount.currency = this.bankAccount.currency;
            if (this.isNewTimeSeries) {
                if (!this.isNewAccount) {
                    // send request if its not create bank account page.
                    const query = {'bankID': this.bankAccount._id};
                    this.employeeService.addTimeSeries(this.citizenID, this.timeSeries, query).pipe(
                        takeUntil(this._destroyed$)
                    ).subscribe(response => {
                        this.init_paginatorTimeSeries();
                    }, error => {});
                } else {
                    // to display newly added one.
                    this.timeSeriesRequestCame = true;
                }
                this.requestedTimeSeries.push(this.timeSeries);
            } else {
                if (!this.isNewAccount) {
                    // send request if its not create bank account page.
                    const query = {'bankID': this.bankAccount._id};
                    this.employeeService.updateTimeSeries(this.citizenID, this.timeSeries, query).pipe(
                        takeUntil(this._destroyed$)
                    ).subscribe(response => {
                        this.init_paginatorTimeSeries();
                    }, error => {});
                }
                const seriesIndex = this.requestedTimeSeries.indexOf(this.currentTimeSeries);
                Object.assign(this.requestedTimeSeries[seriesIndex], this.timeSeries);
            }
            this.requestedTimeSeries.sort(this.utilsService.sortByDescendingDate.bind(this.utilsService));
            this.editTimeSeriesModal.close();
        }
        this.clicked = false;
    }

    updateTimeSeries(series: TimeSeries) {
        this.clearTimeSeries();
        this.isNewTimeSeries = false;
        this.timeSeries = UtilsService.deepCopy(series);
        this.currentTimeSeries = series;
        this.editTimeSeriesModal.show();
        this.clicked = false;
    }

    private clearTimeSeries() {
        this.wrongTimeSeries = false;
        this.isNewTimeSeries = true;
        this.timeSeries = new TimeSeries();
    }

    private clearBankAccount() {
        this.wrongBankAccount = false;
        this.isNewAccount = true;
        this.clearTimeSeries();
        this.bankAccount = new BankAccount();
    }

    clearFiles() {
        this.CV = null;
        this.SSOEmp = null;
        this.newContract = null;
        this.isCVSelected = false;
        this.isSSOEmpSelected = false;
        this.isNewContractSelected = false;
    }

    removeFilesFromUser() {
        delete this.user.CV;
        delete this.user.SSOEmploymentDocument;
        delete this.user.SSOUnemploymentDocument;
        delete this.user.contracts;
    }

    saveFile() {
        const onDone = () => {
            this.employeeService.updateEmployee(this.user, this.citizenID).pipe(
                takeUntil(this._destroyed$)
            ).subscribe(
                response => {
                    this.loadUser();
                    this.updateFileModal.close();
                    this.clicked = false;
                }, error => this.clicked = false);
        };
        this.handleEmployeeFiles(onDone);
    }

    cvChange(isSelected: boolean, e) {
        if (isSelected) {
            this.CV = e.target.files[0];
            if (this.CV) {
                this.isCVSelected = true;
            }
        } else {
            this.isCVSelected = false;
            this.CV = null;
        }
        this.clicked = false;
    }

    ssoEmpChange(isSelected: boolean, e) {
        if (isSelected) {
            this.SSOEmp = e.target.files[0];
            if (this.SSOEmp) {
                this.isSSOEmpSelected = true;
            }
        } else {
            this.isSSOEmpSelected = false;
            this.SSOEmp = null;
        }
        this.clicked = false;
    }

    contractsChange(isSelected: boolean, e) {
        if (isSelected) {
            this.newContract = e.target.files[0];
            if (this.newContract) {
                this.isNewContractSelected = true;
            }
        } else {
            this.isNewContractSelected = false;
            this.newContract = null;
        }
        this.clicked = false;
    }

    handleEmployeeFiles(callback) {
        if (this.fileType === 'CV') {
            this.removeFilesFromUser();
            if (this.CV) {
                const fr = new FileReader();
                fr.onload = (e) => {
                    this.user.CV = fr.result;
                    callback();
                };
                fr.readAsDataURL(this.CV);
            } else {
                this.updateFileModal.close();
                this.clicked = false;
            }
        } else if (this.fileType === 'SSOEmp') {
            this.removeFilesFromUser();
            if (this.SSOEmp) {
                const fr2 = new FileReader();
                fr2.onload = (e) => {
                    this.user.SSOEmploymentDocument = fr2.result;
                    callback();
                };
                fr2.readAsDataURL(this.SSOEmp);
            } else {
                this.updateFileModal.close();
                this.clicked = false;
            }
        } else if (this.fileType === 'contracts') {
            if (this.newContract) {
                const old_contracts = this.user.contracts;
                this.removeFilesFromUser();
                this.user.contracts = [];
                for (const item of old_contracts) {
                    const cont_url_buf = new Buffer(item);
                    this.user.contracts.push(cont_url_buf);
                }
                const fr3 = new FileReader();
                fr3.onload = (e) => {
                    this.user.contracts.push(fr3.result);
                    callback();
                };
                fr3.readAsDataURL(this.newContract);
            } else {
                this.updateFileModal.close();
                this.clicked = false;
            }
        }
    }

    customizeTooltip(arg) {
        return {text: this.utilsService.formatDouble(arg.value, 2)};
    }

    initializeCourseSchedule() {
        this.colHeaders = ['Saat/Gün', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma'];
        this.columns = [
            {
                data: 0,
                renderer: 'text',
                readOnly: true
            },
            {data: 1, renderer: 'text'}, {data: 2, renderer: 'text'}, {data: 3, renderer: 'text'},
            {data: 4, renderer: 'text'}, {data: 5, renderer: 'text'}];

        function rowRenderer(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.NumericRenderer.apply(this, arguments);
            if (col !== 0 && value && row !== 9) {
                td.style.background = 'rgba(97,255,190,0.45)';
            }
            if (row === 9) {
                td.style.fontWeight = 'bold';
            }
        }
        this.options = {
            height: this.user.isFullTime ? 236 : 260,
            rowHeaders: false,
            stretchH: 'all',
            contextMenu: false,
            className: 'htCenter',
            cells: function (row, col) {
                return {
                    renderer: rowRenderer
                };
            }
        };
        this.excelData = [];

        if (!this.user.isFullTime) {
            for (let i = 9; i < 18; i++) {
                this.dataArray = [i + ':00 - ' + (i + 1) + ':00', null, null, null, null, null];
                this.excelData.push(this.dataArray);
            }
            this.excelData.push(['Toplam', 0, 0, 0, 0, 0]);
            for (const day of this.user.workDays) {
                for (const hour of day.hours) {
                    this.excelData[hour - 9][day.day] = '+';
                }
            }
            this.updateTotals();
        } else {
            for (let i = 8; i < 17; i++) {
                this.dataArray = [i + ':40 - ' + (i + 1) + ':30', null, null, null, null, null];
                this.excelData.push(this.dataArray);
            }
            for (const course of this.user.courses) {
                for (let i = course.startHour; i < course.endHour; i++) {
                    this.excelData[i - 9][course.day] = course.courseCode;
                }
            }
        }
    }

    saveCourseSchedule() {
        this.hotTableComponent.getHandsontableInstance().validateCells(function(valid) {
            if (valid) {
                const self = this;
                if (this.user.isFullTime) {
                    const courses = {}; // {'CENG500-1': {startHour: 9, endHour: 13}}
                    this.excelData.map((item, hour) => {
                        for (let i = 1; i < 6; i++) {
                            if (item[i]) {
                                const course = item[i] + '-' + i;
                                if (courses[course]) { // already in
                                    let currentStart = courses[course]['startHour'];
                                    let currentEnd = courses[course]['endHour'];
                                    if ((hour + 9) < currentStart) {
                                        currentStart = hour + 9;
                                        courses[course]['startHour'] = currentStart;
                                    }
                                    if ((hour + 10) > currentEnd) {
                                        currentEnd = hour + 10;
                                        courses[course]['endHour'] = currentEnd;
                                    }
                                } else { // add
                                    courses[course] = {startHour: hour + 9, endHour: hour + 10};
                                }
                            }
                        }
                    });
                    self.user.courses = [];
                    Object.keys(courses).forEach(function(course) {
                        const day = course.substr(course.length - 1);
                        const courseCode = course.substring(0, course.length - 2);
                        const newCourse = new Course(courseCode, courses[course]['startHour'], courses[course]['endHour'], +day);
                        self.user.courses.push(newCourse);
                    });
                } else {
                    const days = {1: [], 2: [], 3: [], 4: [], 5: []};
                    this.excelData.map((item, hour) => {
                        for (let i = 1; i < 6; i++) {
                            if (item[i] && hour !== 9) {
                                days[i].push(hour + 9);
                            }
                        }
                    });
                    self.user.workDays = [];
                    Object.keys(days).forEach(function(day) {
                        if (days[day].length) {
                            const newDay = new WorkDay(days[day], +day);
                            self.user.workDays.push(newDay);
                        }
                    });
                }
                this.removeFilesFromUser();
                this.employeeService.updateEmployee(this.user, this.citizenID).pipe(
                    takeUntil(this._destroyed$)
                ).subscribe(
                    response => {
                        AlertUtil.showSuccess('Program başarıyla kaydedildi.');
                        this.user = response['employee'];
                        this.clicked = false;
                    }, error => this.clicked = false
                );
            } else {
                this.clicked = false;
                AlertUtil.showError('Kaydedebilmek için exceldeki hataları giderin!');
            }
        }.bind(this));
    }

    updateTotals() {
        for (let i = 1; i < 6; i++) {
            this.excelData[9][i] = 0;
        }
        this.excelData.map((item, hour) => {
            for (let i = 1; i < 6; i++) {
                if (item[i] && hour !== 9) {
                    this.excelData[9][i] += 1;
                }
            }
        });
    }

    excelDataChanged(event) {
        if (event[1] !== 'loadData') { // either editted or copy-pasted
            if (!this.user.isFullTime) {
                this.updateTotals();
                this.hotTableComponent.getHandsontableInstance().render();
            }
            AlertUtil.showWarning('Exceli değiştirdiniz, kaydet tuşuna tıklamazsanız değişikleriniz kaybolacak.');
        }
    }

    getFileName(file: string): string {
        return ComponentUtil.PROFILE_PAGE_FILES[file];
    }

}
