import { OnDestroy, OnInit } from '@angular/core';
import { PaymentService } from '../shared/services/payment.service';
import { ModalDirective } from '../shared/directives/modal.directive';
import { TravelService } from '../shared/services/travel.service';
import { environment } from '../../environments/environment';
import { InvoiceService } from '../shared/services/invoice.service';
import { UtilsService } from '../shared/services/utils.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ExchangeRateService } from '../shared/services/exchange-rate.service';
import * as moment from 'moment';
import { AlertUtil } from '../util/AlertUtil';
import { AuthorizationService } from '../shared/services/authorization.service';
import { Payment } from '../../models/payment';
import { Employee } from '../../models/employee';
import { InvoiceReportService } from '../shared/services/invoice-report.service';
import { PaginatorBuilder } from '../shared/paginator-builder';
import { InhouseReportService } from '../shared/services/inhouse-report.service';
import { Project } from '../../models/project';
import { PayrollService } from '../shared/services/payroll.service';
import { EnvironmentVariableService } from '../shared/services/environment-variable.service';
import { FonService } from "../shared/services/fon.service";
var HomeComponent = /** @class */ (function () {
    function HomeComponent(paymentService, travelService, inhouseReportService, invoiceService, utilsService, invoiceReportService, payrollService, exchangeRateService, authorizationService, environmentVariableService, fonService) {
        this.paymentService = paymentService;
        this.travelService = travelService;
        this.inhouseReportService = inhouseReportService;
        this.invoiceService = invoiceService;
        this.utilsService = utilsService;
        this.invoiceReportService = invoiceReportService;
        this.payrollService = payrollService;
        this.exchangeRateService = exchangeRateService;
        this.authorizationService = authorizationService;
        this.environmentVariableService = environmentVariableService;
        this.fonService = fonService;
        this.incomePieData = []; // data shown on pie chart
        this.outcomePieData = []; // data shown on pie chart
        this.detailedIncomeData = []; // selected feature's detailed data
        this.detailedOutcomeData = []; // selected feature's detailed data
        this.viewIncome = 'month';
        this.viewOutcome = 'month';
        this.currencyIncome = 'TL';
        this.currencyOutcome = 'TL';
        this.startingDate = new Date();
        this.incomes = [];
        this.outcomes = [];
        this.yearsOfIncomes = [];
        this.yearsOfOutcomes = [];
        this.detailModal = 'income';
        this.lineChartHeader = '';
        this.lineChartData = [];
        this.lineChartName = '';
        this.monthlyLineChart = [];
        this.yearlyLineChart = [];
        this.lineColor = '#ff0000';
        this.chartDetailingOptionIds = [];
        this.chartDetailingOptions = [];
        this.selectedOption = '';
        this.chartType = '';
        this.isIncome = true;
        this.isOptionable = false;
        this.index = 0;
        this._destroyed$ = new Subject();
        this.requestCameIncome = false;
        this.requestCameOutcome = false;
        this.TRMonths = environment.TRMonths;
        this.getLineChartLabel = this.getLineChartLabel.bind(this);
        this.customizePieChartLabel = this.customizePieChartLabel.bind(this);
        this.customizeTooltip = this.customizeTooltip.bind(this);
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.paginator = new PaginatorBuilder().build();
        this.clearMonthlyLineChart();
        this.setDates();
        this.getEnvironmentVariables().then(function (r) {
            _this.loadIncomes();
            _this.loadOutcomes();
        });
    };
    HomeComponent.prototype.ngOnDestroy = function () {
        this._destroyed$.next();
    };
    HomeComponent.prototype.getEnvironmentVariables = function () {
        var _this = this;
        return this.environmentVariableService.getEnvVariables()
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(function (response) {
            _this.environmentVariablesInDB = response;
            _this.regularTypes = _this.environmentVariablesInDB.regularInvoiceTypes;
        });
    };
    HomeComponent.prototype.setDates = function () {
        var _this = this;
        this.startingDate.setMonth(this.startingDate.getMonth());
        this.incomeYearStart = this.startingDate.getFullYear();
        this.incomeYearEnd = new Date().getFullYear();
        this.incomeMonthStart = this.startingDate.getMonth();
        this.incomeMonthEnd = new Date().getMonth();
        this.outcomeYearStart = this.startingDate.getFullYear();
        this.outcomeYearEnd = new Date().getFullYear();
        this.outcomeMonthStart = this.startingDate.getMonth();
        this.outcomeMonthEnd = new Date().getMonth();
        var thisYear = new Date().getFullYear();
        this.paymentService.getEarliestIncomePayments().pipe(takeUntil(this._destroyed$)).subscribe(function (response) {
            var minYear = new Date(response).getFullYear();
            while (minYear <= thisYear) {
                _this.yearsOfIncomes.push(minYear++);
            }
        });
        this.paymentService.getEarliestOutcomePayments().pipe(takeUntil(this._destroyed$)).subscribe(function (response) {
            var minYear = new Date(response).getFullYear();
            while (minYear <= thisYear) {
                _this.yearsOfOutcomes.push(minYear++);
            }
        });
    };
    HomeComponent.prototype.loadIncomes = function () {
        this.requestCameIncome = false;
        this.clearIncomes();
        this.loadIncomePayments();
    };
    HomeComponent.prototype.loadIncomeInvoices = function () {
        var _this = this;
        var query = { 'payeeOrganization.id': environment.SRDCOrganizationID, 'payeeEmployee': UtilsService.NOT_NULL_TOKEN };
        var tempDate;
        if (this.viewIncome === 'month') {
            tempDate = { begin: new Date(this.incomeYearStart, this.incomeMonthStart, 1),
                end: new Date(this.incomeYearEnd, this.incomeMonthEnd + 1, 1) };
        }
        else if (this.viewIncome === 'year') {
            tempDate = { begin: new Date(this.incomeYearStart, 0, 1),
                end: new Date(this.incomeYearEnd + 1, 0, 1) };
        }
        this.paginator.onDateChanges('paidDate', { value: tempDate });
        this.paginator.addFilterQueries(query);
        return this.invoiceService.getHomeInvoices(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(function (response) {
            var invoices = [].concat.apply([], response);
            for (var _i = 0, invoices_1 = invoices; _i < invoices_1.length; _i++) {
                var invoice = invoices_1[_i];
                _this.incomes.push({
                    paymentType: 'Invoice',
                    date: invoice.paidDate,
                    amount: invoice.totalAmount,
                    exchangeRate: invoice.exchangeRate,
                    invoiceType: invoice.invoiceType,
                    project: invoice.project
                });
            }
        });
    };
    HomeComponent.prototype.loadIncomePayments = function () {
        var _this = this;
        var query = this.generateQuery(false, true);
        this.paymentService.getHomeIncomePayments(query).pipe(takeUntil(this._destroyed$)).subscribe(function (response) {
            _this.incomes = response;
            var promises = [_this.loadIncomeInvoices()];
            Promise.all(promises).then(function () {
                _this.loadFonAmount(query);
            });
        });
    };
    HomeComponent.prototype.setFonFields = function () {
        var _this = this;
        var date = moment(new Date()).format('YYYY-MM-DD');
        var tl = 0;
        var euro = 0;
        var euroTL = 1;
        var usdTL = 1;
        var promises = [];
        if (this.fonIncomeTotal['EURO'] !== 0) {
            var promise1 = this.exchangeRateService
                .getRate(date, 'EURO')
                .toPromise()
                .then(function (curr) {
                euroTL = curr.exchangeRate;
            });
            promises.push(promise1);
        }
        if (this.fonIncomeTotal['DOLLAR'] !== 0) {
            var promise2 = this.exchangeRateService
                .getRate(date, 'USD')
                .toPromise()
                .then(function (curr) {
                usdTL = curr.exchangeRate;
            });
            promises.push(promise2);
        }
        Promise.all(promises).then(function () {
            tl += _this.fonIncomeTotal['TL'] + _this.fonIncomeTotal['EURO'] * euroTL + _this.fonIncomeTotal['DOLLAR'] * usdTL;
            euro += _this.fonIncomeTotal['EURO'] + _this.fonIncomeTotal['TL'] / euroTL + _this.fonIncomeTotal['DOLLAR'] * usdTL / euroTL;
            _this.fonIncomeTotal['TL'] = tl;
            _this.fonIncomeTotal['EURO'] = euro;
            setTimeout(function () {
                _this.generateData(true);
            });
        });
    };
    HomeComponent.prototype.loadFonAmount = function (query) {
        var _this = this;
        return this.fonService.getTotalBalance(0, query).pipe(takeUntil(this._destroyed$)).subscribe(function (response) {
            _this.fonIncomeTotal = response;
            _this.setFonFields();
        });
    };
    HomeComponent.prototype.loadOutcomes = function () {
        this.requestCameOutcome = false;
        this.clearOutcomes();
        this.loadOutcomePayments();
    };
    HomeComponent.prototype.loadOutcomeInvoices = function () {
        var _this = this;
        var query = this.generateQuery(true);
        return this.invoiceReportService.getAllPeriods(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(function (response) {
            response.map(function (table, index) {
                table.invoices.forEach(function (invoice) {
                    var temp = {
                        paymentType: 'InvoicePayment',
                        date: invoice.paidDate,
                        amount: invoice.totalAmount,
                        exchangeRate: invoice.exchangeRate,
                        invoiceType: invoice.invoiceType,
                        type: _this.regularTypes.includes(invoice.invoiceType.toString()) ? 'Düzenli' : 'Diğer'
                    };
                    temp.invoiceType = _this.TRMonths[table.month - 1].month + ' Ayı ' + invoice.invoiceType;
                    _this.outcomes.push(temp);
                });
            });
        });
    };
    HomeComponent.prototype.loadInhousePayments = function () {
        var _this = this;
        var query = this.generateQuery(true);
        return this.inhouseReportService.getAllPeriods(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(function (response) {
            var arrays = response.map(function (table) { return table.invoices; });
            var invoices = [].concat.apply([], arrays);
            for (var _i = 0, invoices_2 = invoices; _i < invoices_2.length; _i++) {
                var invoice = invoices_2[_i];
                _this.outcomes.push({
                    paymentType: 'Inhouse',
                    date: invoice.date,
                    amount: invoice.totalAmount,
                    exchangeRate: invoice.exchangeRate,
                    invoiceType: invoice.invoiceType,
                    payerEmployee: invoice.payerEmployee
                });
            }
        });
    };
    HomeComponent.prototype.loadTravels = function () {
        var _this = this;
        var query = this.generateQuery(false, false);
        return this.travelService.getHomeTravels(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(function (response) {
            for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
                var travel = response_1[_i];
                for (var _a = 0, _b = travel.advancePayments; _a < _b.length; _a++) {
                    var payment = _b[_a];
                    _this.outcomes.push({
                        paymentType: Payment.PaymentType.TRAVEL,
                        advanceType: payment.paymentType === Payment.PaymentType.TRAVEL ? 'Avans' : 'Avans Kapama',
                        date: payment.date,
                        amount: payment.amount,
                        exchangeRate: payment.exchangeRate,
                        isIncoming: payment.isIncoming,
                        projectName: (travel.project ? travel.project.acronym : 'Proje dahilinde değil'),
                        travelDates: [travel.startDate, travel.endDate],
                        travelDescription: travel.destination + ' ' + travel.description
                    });
                }
            }
        });
    };
    HomeComponent.prototype.loadPayrolls = function () {
        var _this = this;
        var query = this.generateQuery(false, false);
        return this.payrollService.getHomePayrolls(query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(function (response) {
            for (var _i = 0, response_2 = response; _i < response_2.length; _i++) {
                var payroll = response_2[_i];
                for (var _a = 0, _b = payroll.payrollLineItems; _a < _b.length; _a++) {
                    var item = _b[_a];
                    _this.outcomes.push({
                        paymentType: Payment.PaymentType.SALARY,
                        employee: item.employee,
                        date: payroll.paidDate ? payroll.paidDate : payroll.date,
                        month: _this.TRMonths[new Date(payroll.date).getMonth()].month,
                        year: new Date(payroll.date).getFullYear(),
                        amount: item.netSalaryAmount,
                        exchangeRate: payroll.ecbExchangeRate
                    });
                }
            }
        });
    };
    HomeComponent.prototype.loadOutcomePayments = function () {
        var _this = this;
        var query = this.generateQuery(false, false);
        this.paymentService.getHomeOutcomePayments(query).pipe(takeUntil(this._destroyed$)).subscribe(function (response) {
            _this.outcomes = response;
            var promises = [_this.loadOutcomeInvoices(), _this.loadInhousePayments(), _this.loadTravels(), _this.loadPayrolls()];
            Promise.all(promises).then(function () {
                setTimeout(function () {
                    _this.generateData(false);
                });
            });
        });
    };
    HomeComponent.prototype.generateQuery = function (isMonthly, isIncome) {
        var query = {};
        if (isMonthly) {
            if (this.viewOutcome === 'month') {
                query = { monthStart: this.outcomeMonthStart + 1, yearStart: this.outcomeYearStart,
                    monthEnd: this.outcomeMonthEnd + 1, yearEnd: this.outcomeYearEnd };
            }
            else if (this.viewOutcome === 'year') {
                query = { monthStart: 1, yearStart: this.outcomeYearStart, monthEnd: 12, yearEnd: this.outcomeYearEnd };
            }
            else {
                var minYear = Math.min.apply(null, this.yearsOfOutcomes);
                var maxYear = Math.max.apply(null, this.yearsOfOutcomes);
                query = { monthStart: 1, yearStart: minYear, monthEnd: 12, yearEnd: maxYear };
            }
        }
        else {
            var yearStart = isIncome ? this.incomeYearStart : this.outcomeYearStart;
            var yearEnd = isIncome ? this.incomeYearEnd : this.outcomeYearEnd;
            var monthStart = isIncome ? this.incomeMonthStart : this.outcomeMonthStart;
            var monthEnd = isIncome ? this.incomeMonthEnd : this.outcomeMonthEnd;
            if ((!isIncome && this.viewOutcome === 'month') || (isIncome && this.viewIncome === 'month')) {
                query = { start: new Date(yearStart, monthStart, 1),
                    end: new Date(yearEnd, monthEnd + 1, 1) };
            }
            else if ((!isIncome && this.viewOutcome === 'year') || (isIncome && this.viewIncome === 'year')) {
                query = { start: new Date(yearStart, 0, 1),
                    end: new Date(yearEnd + 1, 0, 1) };
            }
        }
        return query;
    };
    HomeComponent.prototype.generateData = function (isIncome) {
        var _this = this;
        var handleEUROtoUSD = function (payment) {
            var date = moment(payment.date).format('YYYY-MM-DD');
            return _this.exchangeRateService
                .getRate(date, 'EURO')
                .toPromise()
                .then(function (response) {
                var exchangeRate = payment.exchangeRate / response.exchangeRate;
                return [payment, exchangeRate];
            });
        };
        if (isIncome) {
            this.clearIncomes();
            var promises = this.incomes.map(function (payment) {
                if (_this.currencyIncome === 'TL' && ['EURO', 'USD'].indexOf(payment.amount.currency) >= 0) {
                    return [payment, payment.exchangeRate];
                }
                else if (_this.currencyIncome === 'EURO' && payment.amount.currency === 'TL') {
                    return [payment, 1 / payment.exchangeRate];
                }
                else if (_this.currencyIncome === 'EURO' && payment.amount.currency === 'USD') {
                    return handleEUROtoUSD(payment);
                }
                return [payment, 1]; // No conversion between currencies needed.
            });
            Promise.all(promises).then(function (exchangeRates) {
                exchangeRates.forEach(function (pair) {
                    var payment = pair[0];
                    var exchangeRate = pair[1];
                    var paymentAmount = +(payment.amount.value * exchangeRate).toFixed(2);
                    switch (payment.paymentType) {
                        case Payment.PaymentType.PROJECT_PAYMENT:
                            switch (payment.project.projectType) {
                                case Project.Type.H2020_IA:
                                case Project.Type.H2020_RIA:
                                // TODO ayri sekilde gosterelim horizon projelerini
                                case Project.Type.HORIZON_EUROPE_RIA:
                                case Project.Type.HORIZON_EUROPE_IA:
                                    _this.incomePieData[0].amount += paymentAmount;
                                    _this.currentIncomePayments.H2020_projects.push(payment);
                                    break;
                                case Project.Type.ITEA:
                                    _this.incomePieData[1].amount += paymentAmount;
                                    _this.currentIncomePayments.ITEA_projects.push(payment);
                                    break;
                            }
                            break;
                        case Payment.PaymentType.INVOICE:
                            if (payment.project) {
                                _this.incomePieData[2].amount += paymentAmount;
                                _this.currentIncomePayments.local_projects.push(payment);
                            }
                            else {
                                _this.incomePieData[3].amount += paymentAmount;
                                _this.currentIncomePayments.invoices.push(payment);
                            }
                            break;
                        case Payment.PaymentType.PRIZE:
                            _this.incomePieData[4].amount += paymentAmount;
                            _this.currentIncomePayments.prizes.push(payment);
                            break;
                        case Payment.PaymentType.REIMBURSEMENT:
                            _this.incomePieData[5].amount += paymentAmount;
                            _this.currentIncomePayments.reimbursement.push(payment);
                            break;
                        default:
                            if (payment.paymentType !== Payment.PaymentType.TRAVEL_KAPAMA) {
                                _this.incomePieData[6].amount += paymentAmount;
                                _this.currentIncomePayments.other.push(payment);
                            }
                            break;
                    }
                });
                if (_this.currencyIncome === 'TL') {
                    _this.incomePieData[4].amount += _this.fonIncomeTotal['TL'];
                }
                else {
                    _this.incomePieData[4].amount += _this.fonIncomeTotal['EURO'];
                }
                _this.incomePieData = _this.incomePieData.filter(function (obj) { return obj.amount; });
                _this.requestCameIncome = true;
            });
        }
        else {
            this.clearOutcomes();
            var promises = this.outcomes.map(function (payment) {
                if (_this.currencyOutcome === 'TL' && ['EURO', 'USD'].indexOf(payment.amount.currency) >= 0) {
                    return [payment, payment.exchangeRate];
                }
                else if (_this.currencyOutcome === 'EURO' && payment.amount.currency === 'TL') {
                    return [payment, 1 / payment.exchangeRate];
                }
                else if (_this.currencyOutcome === 'EURO' && payment.amount.currency === 'USD') {
                    return handleEUROtoUSD(payment);
                }
                return [payment, 1]; // No conversion between currencies needed.
            });
            Promise.all(promises).then(function (exchangeRates) {
                exchangeRates.forEach(function (pair) {
                    var payment = pair[0];
                    var exchangeRate = pair[1];
                    var paymentAmount = +(payment.amount.value * exchangeRate).toFixed(2);
                    switch (payment.paymentType) {
                        case Payment.PaymentType.TRAVEL:
                        case Payment.PaymentType.TRAVEL_KAPAMA:
                            if (payment.isIncoming) {
                                _this.outcomePieData[0].amount -= paymentAmount;
                            }
                            else {
                                _this.outcomePieData[0].amount += paymentAmount;
                            }
                            _this.currentOutcomePayments.travels.push(payment);
                            break;
                        case Payment.PaymentType.SALARY:
                            _this.outcomePieData[1].amount += paymentAmount;
                            _this.currentOutcomePayments.salary.push(payment);
                            break;
                        case Payment.PaymentType.BES:
                            _this.outcomePieData[2].amount += paymentAmount;
                            _this.currentOutcomePayments.bes.push(payment);
                            break;
                        case Payment.PaymentType.KDV:
                        case Payment.PaymentType.MUHTASAR:
                        case Payment.PaymentType.SGK5510:
                        case Payment.PaymentType.SGK5746:
                        case Payment.PaymentType.GECICI_VERGI:
                        case Payment.PaymentType.KURUMLAR_VERGISI:
                            _this.outcomePieData[3].amount += paymentAmount;
                            _this.currentOutcomePayments.state.push(payment);
                            break;
                        case 'Inhouse':
                            _this.outcomePieData[4].amount += paymentAmount;
                            _this.currentOutcomePayments.inhouse.push(payment);
                            break;
                        case 'InvoicePayment':
                            _this.outcomePieData[5].amount += paymentAmount;
                            _this.currentOutcomePayments.invoices.push(payment);
                            break;
                        default:
                            _this.outcomePieData[6].amount += paymentAmount;
                            _this.currentOutcomePayments.other.push(payment);
                            break;
                    }
                });
                _this.outcomePieData = _this.outcomePieData.filter(function (obj) { return obj.amount; });
                _this.requestCameOutcome = true;
            });
        }
    };
    HomeComponent.prototype.viewIsSet = function (isIncome, type) {
        if (isIncome && type !== this.viewIncome) {
            this.viewIncome = type;
            this.loadIncomes();
        }
        else if (!isIncome && type !== this.viewOutcome) {
            this.viewOutcome = type;
            this.loadOutcomes();
        }
    };
    HomeComponent.prototype.getPieChartTitle = function (isIncome) {
        var result = '';
        if (isIncome) {
            if (this.viewIncome === 'year') {
                result = this.incomeYearStart + '';
                if (this.incomeYearStart !== this.incomeYearEnd) {
                    result += '-' + this.incomeYearEnd;
                }
            }
            else if (this.viewIncome === 'month') {
                if (this.incomeMonthStart === this.incomeMonthEnd && this.incomeYearStart === this.incomeYearEnd) {
                    result = this.TRMonths[this.incomeMonthStart].month + '/' + this.incomeYearStart;
                }
                else {
                    result = this.TRMonths[this.incomeMonthStart].month + '/' + this.incomeYearStart + ' - ' + this.TRMonths[this.incomeMonthEnd].month + '/' + this.incomeYearEnd;
                }
            }
            else {
                result = 'Tüm Zamanlar';
            }
            result += ' Gelir Dağılımı (' + this.currencyIncome + ')';
        }
        else {
            if (this.viewOutcome === 'year') {
                result = this.outcomeYearStart + '';
                if (this.outcomeYearStart !== this.outcomeYearEnd) {
                    result += '-' + this.outcomeYearEnd;
                }
            }
            else if (this.viewOutcome === 'month') {
                if (this.outcomeMonthStart === this.outcomeMonthEnd && this.outcomeYearStart === this.outcomeYearEnd) {
                    result = this.TRMonths[this.outcomeMonthStart].month + '/' + this.outcomeYearStart;
                }
                else {
                    result = this.TRMonths[this.outcomeMonthStart].month + '/' + this.outcomeYearStart + ' - ' + this.TRMonths[this.outcomeMonthEnd].month + '/' + this.outcomeYearEnd;
                }
            }
            else {
                result = 'Tüm Zamanlar';
            }
            result += ' Gider Dağılımı (' + this.currencyOutcome + ')';
        }
        return result;
    };
    HomeComponent.prototype.getLineChartLabel = function (arg) {
        return this.utilsService.formatDouble(arg.value, 2);
    };
    HomeComponent.prototype.customizePieChartLabel = function (arg) {
        return this.utilsService.formatDouble(arg.value, 2) + ' (' + (arg.percent * 100).toFixed(2) + '%)';
    };
    HomeComponent.prototype.customizeTooltip = function (arg) {
        return { text: this.utilsService.formatDouble(arg.value, 2) };
    };
    HomeComponent.prototype.incomePointClickHandler = function (e) {
        this.lineColor = e.target.getColor();
        this.showDetails(true, e.target.data.type);
    };
    HomeComponent.prototype.outcomePointClickHandler = function (e) {
        this.lineColor = e.target.getColor();
        this.showDetails(false, e.target.data.type);
    };
    HomeComponent.prototype.incomeLegendClickHandler = function (e) {
        for (var _i = 0, _a = e.component._legend._data; _i < _a.length; _i++) {
            var type = _a[_i];
            if (type.argument === e.target) {
                this.lineColor = type.states.normal.fill;
                break;
            }
        }
        this.showDetails(true, e.target);
    };
    HomeComponent.prototype.outcomeLegendClickHandler = function (e) {
        for (var _i = 0, _a = e.component._legend._data; _i < _a.length; _i++) {
            var type = _a[_i];
            if (type.argument === e.target) {
                this.lineColor = type.states.normal.fill;
                break;
            }
        }
        this.showDetails(false, e.target);
    };
    HomeComponent.prototype.getLineColor = function () {
        return this.lineColor;
    };
    HomeComponent.prototype.filterData = function (index, index2) {
        var _this = this;
        if (index !== -1) {
            this.index = +index;
        }
        if (this.isIncome) {
            switch (this.chartType) {
                case 'H2020 Projeleri':
                case 'ITEA Projeleri':
                case 'Yerli Projeler':
                    if (this.chartType === 'H2020 Projeleri') {
                        this.detailedIncomeData = this.currentIncomePayments.H2020_projects;
                    }
                    else if (this.chartType === 'ITEA Projeleri') {
                        this.detailedIncomeData = this.currentIncomePayments.ITEA_projects;
                    }
                    else {
                        this.detailedIncomeData = this.currentIncomePayments.local_projects;
                    }
                    if (this.index !== 0) {
                        this.detailedIncomeData = this.detailedIncomeData.filter(function (obj) { return obj.project.projectId === _this.chartDetailingOptionIds[_this.index]; });
                    }
                    break;
            }
        }
        else {
            switch (this.chartType) {
                case 'Seyahatlar':
                    this.detailedOutcomeData = this.currentOutcomePayments.travels;
                    if (this.index !== 0) {
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(function (obj) { return obj.projectName === _this.chartDetailingOptions[_this.index]; });
                    }
                    break;
                case 'Maaşlar':
                    this.detailedOutcomeData = this.currentOutcomePayments.salary;
                    if (this.index !== 0) {
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(function (obj) { return obj.employee.citizenID === _this.chartDetailingOptionIds[_this.index]; });
                    }
                    break;
                case 'BES':
                    this.detailedOutcomeData = this.currentOutcomePayments.bes;
                    if (this.index !== 0) {
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(function (obj) { return obj.payeeEmployee.citizenID === _this.chartDetailingOptionIds[_this.index]; });
                    }
                    break;
                case 'Vergi Ödemeleri':
                    this.detailedOutcomeData = this.currentOutcomePayments.state;
                    if (this.index !== 0) {
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(function (obj) { return obj.paymentType === _this.chartDetailingOptions[_this.index]; });
                    }
                    break;
                case 'Şirket İçi Harcamalar':
                    this.detailedOutcomeData = this.currentOutcomePayments.inhouse;
                    if (this.index !== 0) {
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(function (obj) { return obj.payerEmployee.citizenID === _this.chartDetailingOptionIds[_this.index]; });
                    }
                    break;
                case 'Faturalar':
                    this.detailedOutcomeData = this.currentOutcomePayments.invoices;
                    if (this.index !== 0) {
                        this.detailedOutcomeData = this.detailedOutcomeData.filter(function (obj) { return obj.type === _this.chartDetailingOptions[_this.index]; });
                    }
                    break;
            }
        }
        this.plot();
    };
    HomeComponent.prototype.showDetails = function (isIncome, type) {
        // Non-admin users should not see details.
        if (this.authorizationService.currentUserType !== Employee.UserType.ADMIN) {
            AlertUtil.showWarning('Detayları görmek için yetkiniz bulunmamaktadır.', 'Authorization Error');
            return;
        }
        this.isOptionable = false;
        this.isIncome = isIncome;
        this.chartType = type;
        if (this.isIncome) {
            switch (this.chartType) {
                case 'H2020 Projeleri':
                case 'ITEA Projeleri':
                case 'Yerli Projeler':
                    if (this.chartType === 'H2020 Projeleri') {
                        this.detailedIncomeData = this.currentIncomePayments.H2020_projects;
                    }
                    else if (this.chartType === 'ITEA Projeleri') {
                        this.detailedIncomeData = this.currentIncomePayments.ITEA_projects;
                    }
                    else {
                        this.detailedIncomeData = this.currentIncomePayments.local_projects;
                    }
                    this.selectedOption = 'Tüm Projeler';
                    this.chartDetailingOptions = ['Tüm Projeler'];
                    this.chartDetailingOptionIds = ['Tüm Projeler'];
                    for (var _i = 0, _a = this.detailedIncomeData; _i < _a.length; _i++) {
                        var payment = _a[_i];
                        if (!this.chartDetailingOptionIds.includes(payment.project.projectId)) {
                            this.chartDetailingOptionIds.push(payment.project.projectId);
                            this.chartDetailingOptions.push(payment.project.acronym);
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Faturalar':
                    this.detailedIncomeData = this.currentIncomePayments.invoices;
                    break;
                case 'Ödüller':
                    this.detailedIncomeData = this.currentIncomePayments.prizes;
                    break;
                case 'Geri Ödemeler':
                    this.detailedIncomeData = this.currentIncomePayments.reimbursement;
                    break;
                case 'Diğer':
                    this.detailedIncomeData = this.currentIncomePayments.other;
                    break;
            }
        }
        else {
            switch (this.chartType) {
                case 'Seyahatlar':
                    this.detailedOutcomeData = this.currentOutcomePayments.travels;
                    this.selectedOption = 'Tüm Projeler';
                    this.chartDetailingOptions = ['Tüm Projeler'];
                    for (var _b = 0, _c = this.detailedOutcomeData; _b < _c.length; _b++) {
                        var payment = _c[_b];
                        if (!this.chartDetailingOptions.includes(payment.projectName)) {
                            this.chartDetailingOptions.push(payment.projectName);
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Maaşlar':
                    this.detailedOutcomeData = this.currentOutcomePayments.salary;
                    this.selectedOption = 'Tüm Çalışanlar';
                    this.chartDetailingOptions = ['Tüm Çalışanlar'];
                    this.chartDetailingOptionIds = ['Tüm Çalışanlar'];
                    for (var _d = 0, _e = this.detailedOutcomeData; _d < _e.length; _d++) {
                        var payment = _e[_d];
                        if (!this.chartDetailingOptionIds.includes(payment.employee.citizenID)) {
                            this.chartDetailingOptionIds.push(payment.employee.citizenID);
                            this.chartDetailingOptions.push(this.utilsService.getPersonFullName(payment.employee.person));
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'BES':
                    this.detailedOutcomeData = this.currentOutcomePayments.bes;
                    break;
                case 'Vergi Ödemeleri':
                    this.detailedOutcomeData = this.currentOutcomePayments.state;
                    this.selectedOption = 'Tüm Ödemeler';
                    this.chartDetailingOptions = ['Tüm Ödemeler'];
                    for (var _f = 0, _g = this.detailedOutcomeData; _f < _g.length; _f++) {
                        var payment = _g[_f];
                        if (!this.chartDetailingOptions.includes(payment.paymentType)) {
                            this.chartDetailingOptions.push(payment.paymentType);
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Şirket İçi Harcamalar':
                    this.detailedOutcomeData = this.currentOutcomePayments.inhouse;
                    this.selectedOption = 'Tüm Çalışanlar';
                    this.chartDetailingOptions = ['Tüm Çalışanlar'];
                    this.chartDetailingOptionIds = ['Tüm Çalışanlar'];
                    for (var _h = 0, _j = this.detailedOutcomeData; _h < _j.length; _h++) {
                        var payment = _j[_h];
                        if (!this.chartDetailingOptionIds.includes(payment.payerEmployee.citizenID)) {
                            this.chartDetailingOptionIds.push(payment.payerEmployee.citizenID);
                            this.chartDetailingOptions.push(this.utilsService.getPersonFullName(payment.payerEmployee.person));
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Faturalar':
                    this.detailedOutcomeData = this.currentOutcomePayments.invoices;
                    this.selectedOption = 'Tüm Faturalar';
                    this.chartDetailingOptions = ['Tüm Faturalar'];
                    for (var _k = 0, _l = this.detailedOutcomeData; _k < _l.length; _k++) {
                        var payment = _l[_k];
                        if (!this.chartDetailingOptions.includes(payment.type)) {
                            this.chartDetailingOptions.push(payment.type);
                        }
                    }
                    this.isOptionable = true;
                    break;
                case 'Diğer':
                    this.detailedOutcomeData = this.currentOutcomePayments.other;
                    break;
            }
        }
        this.sortOptions();
        this.plot();
    };
    HomeComponent.prototype.plot = function () {
        var _this = this;
        if (this.isIncome) {
            if (this.viewIncome === 'month') {
                this.detailModal = 'income';
                this.incomeOutcomeDetailModal.show();
            }
            else if (this.viewIncome === 'year') {
                this.clearMonthlyLineChart(true);
                for (var _i = 0, _a = this.detailedIncomeData; _i < _a.length; _i++) {
                    var payment = _a[_i];
                    var paymentAmount = payment.amount.value;
                    if (this.currencyIncome === 'TL' && payment.amount.currency !== 'TL') {
                        paymentAmount = paymentAmount * payment.exchangeRate;
                    }
                    else if (this.currencyIncome !== 'TL' && payment.amount.currency === 'TL') {
                        paymentAmount = paymentAmount / payment.exchangeRate;
                    }
                    var yearIndex = new Date(payment.date).getFullYear() - this.incomeYearStart;
                    var line_index = yearIndex * 12 + new Date(payment.date).getMonth();
                    if (line_index >= 0 && line_index < this.monthlyLineChart.length) {
                        this.monthlyLineChart[yearIndex * 12 + new Date(payment.date).getMonth()].amount += +paymentAmount.toFixed(2);
                    }
                }
                this.lineChartData = this.monthlyLineChart;
                this.lineChartHeader = (this.incomeYearStart === this.incomeYearEnd ? this.incomeYearStart : this.incomeYearStart + '-' + this.incomeYearEnd) + ' ' + this.chartType + ' Gelir Grafiği';
                this.lineChartName = 'MEBLAĞ (' + this.currencyIncome + ')';
                this.lineChartModal.show();
            }
            else {
                this.yearlyLineChart = [];
                for (var _b = 0, _c = this.yearsOfIncomes; _b < _c.length; _b++) {
                    var year = _c[_b];
                    this.yearlyLineChart.push({ date: year + '', amount: 0 });
                }
                var _loop_1 = function (payment) {
                    var paymentAmount = payment.amount.value;
                    if (this_1.currencyIncome === 'TL' && payment.amount.currency !== 'TL') {
                        paymentAmount = paymentAmount * payment.exchangeRate;
                    }
                    else if (this_1.currencyIncome !== 'TL' && payment.amount.currency === 'TL') {
                        paymentAmount = paymentAmount / payment.exchangeRate;
                    }
                    this_1.yearlyLineChart.map(function (todo, i) {
                        if (todo.date === (new Date(payment.date).getFullYear() + '')) {
                            _this.yearlyLineChart[i].amount += +paymentAmount.toFixed(2);
                        }
                    });
                };
                var this_1 = this;
                for (var _d = 0, _e = this.detailedIncomeData; _d < _e.length; _d++) {
                    var payment = _e[_d];
                    _loop_1(payment);
                }
                this.lineChartData = this.yearlyLineChart;
                this.lineChartHeader = 'Tüm Zamanlar ' + this.chartType + ' Gelir Grafiği';
                this.lineChartName = 'MEBLAĞ (' + this.currencyIncome + ')';
                this.lineChartModal.show();
            }
        }
        else {
            if (this.viewOutcome === 'month') {
                this.detailModal = 'outcome';
                this.incomeOutcomeDetailModal.show();
            }
            else if (this.viewOutcome === 'year') {
                this.clearMonthlyLineChart(false);
                for (var _f = 0, _g = this.detailedOutcomeData; _f < _g.length; _f++) {
                    var payment = _g[_f];
                    var paymentAmount = payment.amount.value;
                    if (this.currencyOutcome === 'TL' && payment.amount.currency !== 'TL') {
                        paymentAmount = paymentAmount * payment.exchangeRate;
                    }
                    else if (this.currencyOutcome !== 'TL' && payment.amount.currency === 'TL') {
                        paymentAmount = paymentAmount / payment.exchangeRate;
                    }
                    var yearIndex = new Date(payment.date).getFullYear() - this.outcomeYearStart;
                    var line_index = yearIndex * 12 + new Date(payment.date).getMonth();
                    if (line_index >= 0 && line_index < this.monthlyLineChart.length) {
                        this.monthlyLineChart[yearIndex * 12 + new Date(payment.date).getMonth()].amount += +paymentAmount.toFixed(2);
                    }
                }
                this.lineChartData = this.monthlyLineChart;
                this.lineChartHeader = (this.outcomeYearStart === this.outcomeYearEnd ? this.outcomeYearStart : this.outcomeYearStart + '-' + this.outcomeYearEnd) + ' ' + this.chartType + ' Gider Grafiği';
                this.lineChartName = 'MEBLAĞ (' + this.currencyOutcome + ')';
                this.lineChartModal.show();
            }
            else {
                this.yearlyLineChart = [];
                for (var _h = 0, _j = this.yearsOfOutcomes; _h < _j.length; _h++) {
                    var year = _j[_h];
                    this.yearlyLineChart.push({ date: year + '', amount: 0 });
                }
                var _loop_2 = function (payment) {
                    var paymentAmount = payment.amount.value;
                    if (this_2.currencyOutcome === 'TL' && payment.amount.currency !== 'TL') {
                        paymentAmount = paymentAmount * payment.exchangeRate;
                    }
                    else if (this_2.currencyOutcome !== 'TL' && payment.amount.currency === 'TL') {
                        paymentAmount = paymentAmount / payment.exchangeRate;
                    }
                    this_2.yearlyLineChart.map(function (todo, i) {
                        if (todo.date === (new Date(payment.date).getFullYear() + '')) {
                            _this.yearlyLineChart[i].amount += +paymentAmount.toFixed(2);
                        }
                    });
                };
                var this_2 = this;
                for (var _k = 0, _l = this.detailedOutcomeData; _k < _l.length; _k++) {
                    var payment = _l[_k];
                    _loop_2(payment);
                }
                this.lineChartData = this.yearlyLineChart;
                this.lineChartHeader = 'Tüm Zamanlar ' + this.chartType + ' Gider Grafiği';
                this.lineChartName = 'MEBLAĞ (' + this.currencyOutcome + ')';
                this.lineChartModal.show();
            }
        }
    };
    HomeComponent.prototype.getDescription = function (item) {
        switch (item.paymentType) {
            case 'Invoice':
            case 'InvoicePayment':
            case 'Inhouse':
                return item.invoiceType;
            case 'Project Payment':
                return item.project.acronym + ' Ödemesi';
            case 'Prize':
                return (item.project ? item.project.acronym + ' - ' : '') + this.utilsService.getPersonFullName(item.payeeEmployee.person);
            case 'Reimbursement':
                return item.project ? item.project.acronym : 'Proje dahilinde değil';
            case 'Other':
                return item.payerEmployee ? this.utilsService.getPersonFullName(item.payerEmployee.person) : item.payerOrganization.name;
            case 'Travel':
                return item.projectName ? item.projectName + ' ' + item.travelDescription : item.travelDescription;
            case 'Salary':
                return this.utilsService.getPersonFullName(item.employee.person);
            case 'BES':
                return 'Bireysel Emeklilik';
            case Payment.PaymentType.KDV:
            case Payment.PaymentType.MUHTASAR:
            case Payment.PaymentType.SGK5510:
            case Payment.PaymentType.SGK5746:
            case Payment.PaymentType.GECICI_VERGI:
            case Payment.PaymentType.KURUMLAR_VERGISI:
                return item.paymentType;
        }
    };
    HomeComponent.prototype.getFirstColumn = function (paymentType) {
        switch (paymentType) {
            case 'Invoice':
            case 'InvoicePayment':
                return 'Fatura';
            case 'Inhouse':
                return 'Harcama';
            case 'Project Payment':
                return 'Proje';
            case 'Prize':
                return 'Ödül';
            case 'Reimbursement':
                return 'Seyahat';
            case 'Other':
                return 'Ödeyen';
            case 'Travel':
                return 'Açıklama';
            case 'Salary':
                return 'Çalışan';
            case 'BES':
                return 'Tür';
            case Payment.PaymentType.KDV:
            case Payment.PaymentType.MUHTASAR:
            case Payment.PaymentType.SGK5510:
            case Payment.PaymentType.SGK5746:
            case Payment.PaymentType.GECICI_VERGI:
            case Payment.PaymentType.KURUMLAR_VERGISI:
                return 'Vergi';
        }
    };
    HomeComponent.prototype.getTotal = function (isIncome) {
        var total;
        if (isIncome) {
            total = this.incomePieData.reduce(function (a, b) { return a + b.amount; }, 0);
        }
        else {
            total = this.outcomePieData.reduce(function (a, b) { return a + b.amount; }, 0);
        }
        return 'Toplam: ' + this.utilsService.formatDouble(total, 2);
    };
    HomeComponent.prototype.clearIncomes = function () {
        this.incomePieData = [{
                type: 'H2020 Projeleri',
                amount: 0
            }, {
                type: 'ITEA Projeleri',
                amount: 0
            }, {
                type: 'Yerli Projeler',
                amount: 0
            }, {
                type: 'Faturalar',
                amount: 0
            }, {
                type: 'Fon',
                amount: 0
            }, {
                type: 'Geri Ödemeler',
                amount: 0
            }, {
                type: 'Diğer',
                amount: 0
            }];
        this.currentIncomePayments = {
            H2020_projects: [],
            ITEA_projects: [],
            local_projects: [],
            invoices: [],
            prizes: [],
            reimbursement: [],
            other: []
        };
    };
    HomeComponent.prototype.clearOutcomes = function () {
        this.outcomePieData = [{
                type: 'Seyahatlar',
                amount: 0
            }, {
                type: 'Maaşlar',
                amount: 0
            }, {
                type: 'BES',
                amount: 0
            }, {
                type: 'Vergi Ödemeleri',
                amount: 0
            }, {
                type: 'Şirket İçi Harcamalar',
                amount: 0
            }, {
                type: 'Faturalar',
                amount: 0
            }, {
                type: 'Diğer',
                amount: 0
            }];
        this.currentOutcomePayments = {
            travels: [],
            salary: [],
            bes: [],
            state: [],
            inhouse: [],
            invoices: [],
            other: []
        };
    };
    HomeComponent.prototype.clearMonthlyLineChart = function (isIncome) {
        this.monthlyLineChart = [];
        if (isIncome === undefined) {
            this.addYearToMonthlyChart();
        }
        else {
            if (isIncome) {
                for (var i = this.incomeYearStart; i <= this.incomeYearEnd; i++) {
                    this.addYearToMonthlyChart(this.incomeYearStart === this.incomeYearEnd ? null : i);
                }
                if (new Date().getFullYear() === this.incomeYearEnd) {
                    var years = this.incomeYearEnd - this.incomeYearStart;
                    this.monthlyLineChart.splice(years * 12 + new Date().getMonth() + 1, 11 - new Date().getMonth()); // to show only the months up today
                }
            }
            else {
                for (var i = this.outcomeYearStart; i <= this.outcomeYearEnd; i++) {
                    this.addYearToMonthlyChart(this.outcomeYearStart === this.outcomeYearEnd ? null : i);
                }
                if (new Date().getFullYear() === this.outcomeYearEnd) {
                    var years = this.outcomeYearEnd - this.outcomeYearStart;
                    this.monthlyLineChart.splice(years * 12 + new Date().getMonth() + 1, 11 - new Date().getMonth()); // to show only the months up today
                }
            }
        }
    };
    HomeComponent.prototype.addYearToMonthlyChart = function (year) {
        var yearText = year ? ' ' + year : '';
        this.monthlyLineChart.push({
            date: 'Ocak' + yearText,
            amount: 0,
        }, {
            date: 'Şubat' + yearText,
            amount: 0,
        }, {
            date: 'Mart' + yearText,
            amount: 0,
        }, {
            date: 'Nisan' + yearText,
            amount: 0,
        }, {
            date: 'Mayıs' + yearText,
            amount: 0,
        }, {
            date: 'Haziran' + yearText,
            amount: 0,
        }, {
            date: 'Temmuz' + yearText,
            amount: 0,
        }, {
            date: 'Ağustos' + yearText,
            amount: 0,
        }, {
            date: 'Eylül' + yearText,
            amount: 0,
        }, {
            date: 'Ekim' + yearText,
            amount: 0,
        }, {
            date: 'Kasım' + yearText,
            amount: 0,
        }, {
            date: 'Aralık' + yearText,
            amount: 0,
        });
    };
    HomeComponent.prototype.sortOptions = function () {
        var list = [];
        for (var j = 0; j < this.chartDetailingOptions.length; j++) {
            list.push({ option: this.chartDetailingOptions[j], id: this.chartDetailingOptionIds[j] });
        }
        list.sort(function (a, b) {
            if (['Tüm Projeler', 'Tüm Çalışanlar', 'Tüm Ödemeler', 'Tüm Faturalar'].includes(a.option)
                || b.option === 'Proje dahilinde değil' || b.option === 'Diğer') {
                return -1;
            }
            else if (['Tüm Projeler', 'Tüm Çalışanlar', 'Tüm Ödemeler', 'Tüm Faturalar'].includes(b.option)
                || a.option === 'Proje dahilinde değil' || a.option === 'Diğer') {
                return 1;
            }
            return a.option.localeCompare(b.option);
        });
        for (var k = 0; k < list.length; k++) {
            this.chartDetailingOptions[k] = list[k].option;
            this.chartDetailingOptionIds[k] = list[k].id;
        }
    };
    return HomeComponent;
}());
export { HomeComponent };
