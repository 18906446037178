import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EmployeeService} from '../shared/services/employee.service';
import {ModalDirective} from '../shared/directives/modal.directive';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {UtilsService} from '../shared/services/utils.service';
import {Paginator} from '../shared/paginator';
import {PaginatorBuilder} from '../shared/paginator-builder';
import {AlertUtil} from '../util/AlertUtil';
import {environment} from '../../environments/environment';
import {Employee} from '../../models/employee';
import {VacationDaysEntry} from '../../models/vacationDaysEntry';
import {EnvironmentVariableService} from '../shared/services/environment-variable.service';
import {Environment} from '../../models/environmentVariables';
import {VacationInformation} from "../../models/vacationInformation";
import {start} from "repl";

declare const $: any;

@Component({
    selector: 'app-leave-excuse-management',
    templateUrl: './leave-excuse-management.component.html',
    styleUrls: ['./leave-excuse-management.component.css'],
})

export class LeaveExcuseManagementComponent implements OnInit, OnDestroy {
    @ViewChild('vacationEntryModal') vacationEntryModal: ModalDirective;
    @ViewChild('vacationModal') vacationModal: ModalDirective;
    @ViewChild('excuseEntryModal') excuseEntryModal: ModalDirective;
    @ViewChild('experienceModal') experienceModal: ModalDirective;
    @ViewChild('areYouSureModal') areYouSureModal: ModalDirective;
    @ViewChild('showAllModal') showAllModal: ModalDirective;

    employees: Employee[] = [];
    allEmployees: Employee[] = [];
    employee: Employee;
    vacation: VacationDaysEntry;
    isNewVacation = true;
    wrongVacation = false;
    wrongVacationEnd = false;
    pageView: 'overview' | 'infoView' | 'inputView' = 'overview';
    _destroyed$ = new Subject();
    requestCame = false;
    vacationToDelete: VacationDaysEntry;
    month: number = new Date().getMonth();
    year: number = new Date().getFullYear();

    paginator: Paginator;
    clicked = false;
    selectedYear = new Date().getFullYear();
    years;
    TRMonths: {month: string, value: number}[] = environment.TRMonths;
    allVacations = [];
    environmentVariablesInDB: Environment;

    constructor(public employeeService: EmployeeService, public utilsService: UtilsService, private environmentVariableService: EnvironmentVariableService) {
    }

    ngOnInit() {
        this.clearVacation();
        this.getEnvironmentVariables().then(r => {
            this.paginator = new PaginatorBuilder()
                .withPageSizeOptions([10, 20, 30])
                .withRefreshFunction(() => this.getEmployees())
                .withResizeFunction(() => this.getEmployeesSize())
                .build();
            this.paginator.sortField = 'person.firstName';
            this.paginator.sortType = 1;
            this.getEmployeesSize();
            this.loadEmployees();
        });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
    }

    getEnvironmentVariables() {
        return this.environmentVariableService.getEnvVariables()
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(response => {
                this.environmentVariablesInDB = response;
                this.years = Array.from( // All the years between database's start year and current year
                    Array(this.selectedYear - this.environmentVariablesInDB.dbStartingYear + 1),
                    (x, i) => this.environmentVariablesInDB.dbStartingYear + i
                ).reverse();
            });
    }

    private getEmployeesSize() {
        const query = {active: true};
        this.paginator.addPagination(query);
        this.paginator.addFilterQueries(query);
        return this.employeeService.getEmployeesSize(query)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(response => this.paginator.onTotalSizeChange(response.size));
    }

    private getEmployees() {
        this.requestCame = false;
        const query = {sort_field: 'person.firstName', sort_type: 1};
        this.paginator.addPagination(query);
        this.paginator.addFilterQueries(query);
        return this.employeeService.getExcuseEmployees(query).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            response => {
                this.employees = response;
                this.requestCame = true;
                this.clicked = false;
            },
            error => {
                this.employees = [];
                this.requestCame = true;
                this.clicked = false;
            }
        );
    }

    loadEmployees() {
        this.employeeService.getExcuseEmployees({preventPagination: true, sort_field: 'person.firstName', sort_type: 1})
            .pipe(takeUntil(this._destroyed$))
            .subscribe(response => {
                this.allEmployees = response;
            });
    }

    editVacation(vacation: VacationDaysEntry) {
        this.clearVacation();
        this.isNewVacation = false;
        this.vacation = UtilsService.deepCopy(vacation);
        this.vacationEntryModal.show();
        this.clicked = false;
    }

    deleteVacation() {
        this.areYouSureModal.close();
        this.adjustRemainings(this.vacationToDelete, true);
        this.utilsService.removeFromArray(this.employee.vacationInformation.vacationDaysEntries, this.vacationToDelete);
        this.employee.vacationInformation.vacationDaysEntries =
            [].concat(this.employee.vacationInformation.vacationDaysEntries); // Trigger pipe
        this.clicked = false;
    }

    showAllVacations() {
        const start = new Date(this.year, this.month, 1);
        const end = new Date(this.year, this.month + 1, 0);
        this.allVacations = [];
        for (const employee of this.employees) {
            const dayEntries = employee.vacationInformation.vacationDaysEntries.filter(entry =>
                (new Date(entry.startDate) >= start && new Date(entry.startDate) <= end) || (new Date(entry.endDate) >= start && new Date(entry.endDate) <= end));
            for (const entry of dayEntries) {
                const tempObj = {
                    person: employee.person,
                    date: entry.startDate === entry.endDate ? moment(entry.startDate).format('DD/MM/YYYY') :
                        moment(entry.startDate).format('DD/MM/YYYY') + ' - ' + moment(entry.endDate).format('DD/MM/YYYY'),
                    numberOfDays: entry.numberOfDays,
                };
                this.allVacations.push(tempObj);
            }
        }
        this.showAllModal.show();
    }

    showVacationModal() {
        this.clicked = false;
        this.clearVacation();
        this.vacationModal.show();
        this.employee = this.allEmployees[0];
        setTimeout(() => {
            $('#employeeDropdown').dropdown('set selected', this.allEmployees[0].citizenID);
            this.utilsService.configureDropdown('#employeeDropdown');
        });
    }
    adjustRemainings(vacation: VacationDaysEntry, isDeletion: boolean = false) {
        const today = new Date();
        let startDate = new Date(vacation.startDate);
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        let endDate = new Date(vacation.endDate);
        endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        if (startDate.getFullYear() < today.getFullYear()) {
            if (endDate.getFullYear() >= today.getFullYear()) {
                const diff = (e , t) => Math.floor((e.getTime() - t.getTime()) / 86400000);
                const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
                const daysThisYear = diff(endDate, firstDayOfYear);
                const daysPrev = diff(firstDayOfYear, startDate);
                if (!isDeletion) {
                    this.employee.vacationInformation.vacationDaysUsedInThisYear += daysThisYear;
                    this.employee.vacationInformation.vacationDaysRemainingFromLastYear -= daysPrev;
                } else {
                    this.employee.vacationInformation.vacationDaysUsedInThisYear -= daysThisYear;
                    this.employee.vacationInformation.vacationDaysRemainingFromLastYear += daysPrev;
                }
            } else {
                if (!isDeletion) {
                    this.employee.vacationInformation.vacationDaysRemainingFromLastYear -= vacation.numberOfDays;
                } else {
                    this.employee.vacationInformation.vacationDaysRemainingFromLastYear += vacation.numberOfDays;
                }
            }
        } else {
            if (!isDeletion) {
                this.employee.vacationInformation.vacationDaysUsedInThisYear += vacation.numberOfDays;
            } else {
                this.employee.vacationInformation.vacationDaysUsedInThisYear -= vacation.numberOfDays;
            }
        }
    }

    addVacation(isNew: boolean) {
        if (!this.vacation.startDate ||
            !this.vacation.endDate ||
            !this.vacation.numberOfDays ||
            !this.employee
        ) {
            this.clicked = false;
            this.wrongVacation = true;
            AlertUtil.showError('Gerekli alanları doldurunuz!');
        } else if (this.vacation.startDate > this.vacation.endDate) {
            this.clicked = false;
            this.wrongVacation = false;
            this.wrongVacationEnd = true;
            AlertUtil.showError('Bitiş tarihi başlangıç tarihinden önce olamaz!');
        } else {
            this.employee.vacationInformation.vacationDaysEntries.push(this.vacation);
            this.employee.vacationInformation.vacationDaysEntries =
                [].concat(this.employee.vacationInformation.vacationDaysEntries); // Trigger pipe
            this.adjustRemainings(this.vacation);
            if (this.employee.vacationInformation.vacationDaysUsedInThisYear >=
                (this.employee.vacationInformation.numberOfVacationDaysRights +
                    this.employee.vacationInformation.vacationDaysRemainingFromLastYear)) {
                AlertUtil.showInfo('Çalışanın izin hakları bitti.', 'Dikkat!');
            }

            if (isNew) {
                this.employeeService.updateEmployee(this.employee, this.employee.citizenID).pipe(
                    takeUntil(this._destroyed$)
                ).subscribe(
                    response => {
                        this.clearVacation();
                        this.getEmployees();
                        this.loadEmployees();
                        this.clicked = false;
                        this.vacationModal.close();
                        AlertUtil.showSuccess('İzinler başarıyla eklendi');
                    }
                );
            } else {
                this.clicked = false;
                this.vacationEntryModal.close();
            }
        }
    }

    updateVacation() {
        if (!this.vacation.startDate ||
            !this.vacation.endDate ||
            !this.vacation.numberOfDays ||
            !this.employee
        ) {
            this.clicked = false;
            this.wrongVacation = true;
            AlertUtil.showError('Gerekli alanları doldurunuz!');
        } else if (this.vacation.startDate > this.vacation.endDate) {
            this.clicked = false;
            this.wrongVacation = false;
            this.wrongVacationEnd = true;
            AlertUtil.showError('Bitiş tarihi başlangıç tarihinden önce olamaz!');
        } else {
            this.employee.vacationInformation.vacationDaysEntries.map((todo, i) => {
                if (todo._id === this.vacation._id) {
                    this.adjustRemainings(this.employee.vacationInformation.vacationDaysEntries[i], true);
                    this.adjustRemainings(this.vacation);
                    this.employee.vacationInformation.vacationDaysEntries[i] = this.vacation;
                    this.employee.vacationInformation.vacationDaysEntries =
                        [].concat(this.employee.vacationInformation.vacationDaysEntries); // Trigger pipe
                    this.vacationEntryModal.close();
                    this.clicked = false;
                }
            });
        }
    }

    updateInfo() {
        this.employeeService.updateEmployee(this.employee, this.employee.citizenID).pipe(
            takeUntil(this._destroyed$)
        ).subscribe(
            response => {
                this.clearVacation();
                this.getEmployees();
                this.loadEmployees();
                this.pageView = 'overview';
                AlertUtil.showSuccess('İzinler başarıyla güncellendi');
            }
        );
    }

    infoModalF(employee: Employee) {
        this.employee = UtilsService.deepCopy(employee);
        this.pageView = 'inputView';
        this.clicked = false;
    }

    updateInNewYear() {
        this.environmentVariableService.putUpdateEnvVariables(this.environmentVariablesInDB)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(response => {
                this.employeeService.getExcuseEmployees({preventPagination: true}).pipe(
                    takeUntil(this._destroyed$)
                ).subscribe(
                    employees => {
                        const promises = employees.map(employee => {
                            const today = moment();
                            let experience = -1;  // part-time
                            if (employee.bachelorGraduationDate) {  // full-time
                                const graduation = moment(employee.bachelorGraduationDate);
                                experience = today.diff(graduation, 'years', true);
                            }

                            employee.vacationInformation.vacationDaysRemainingFromLastYear =
                                employee.vacationInformation.numberOfVacationDaysRights.valueOf()
                                + employee.vacationInformation.vacationDaysRemainingFromLastYear.valueOf()
                                - employee.vacationInformation.vacationDaysUsedInThisYear.valueOf();
                            employee.vacationInformation.vacationDaysUsedInThisYear = 0;

                            if (experience === -1) { // part-time
                                employee.vacationInformation.numberOfVacationDaysRights = this.environmentVariablesInDB.parttimeVacationRight;
                            } else if (experience < this.environmentVariablesInDB.experienceVacationRights[1]) { // junior
                                employee.vacationInformation.numberOfVacationDaysRights = this.environmentVariablesInDB.experienceVacationRights[3];
                            } else if (experience < this.environmentVariablesInDB.experienceVacationRights[2]) { // mid
                                employee.vacationInformation.numberOfVacationDaysRights = this.environmentVariablesInDB.experienceVacationRights[4];
                            } else { // senior
                                employee.vacationInformation.numberOfVacationDaysRights = this.environmentVariablesInDB.experienceVacationRights[5];
                            }
                            return this.employeeService.updateEmployee(employee, employee.citizenID)
                                .pipe(takeUntil(this._destroyed$))
                                .toPromise()
                                .then(r => {});
                        });
                        Promise.all(promises).then(() => {
                            this.getEmployees();
                            this.loadEmployees();
                            AlertUtil.showSuccess('İzinler başarıyla güncellendi');
                            this.experienceModal.close();
                            this.clicked = false;
                        }).catch(error => this.clicked = false);
                    },
                    error => {
                        this.clicked = false;
                    }
                );
            }, error => {
                this.clicked = false;
                AlertUtil.showError('Hata');
            });
    }

    addEmployeeOption(employeeID: string) {
        this.employee = this.allEmployees.find(x => x.citizenID === employeeID);
    }

    clearVacation() {
        this.wrongVacation = false;
        this.isNewVacation = true;
        this.wrongVacationEnd = false;
        this.vacation = new VacationDaysEntry();
    }

}
