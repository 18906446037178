import {Contact} from './contact';

export class Person {
    firstName: string;
    middleName: string;
    lastName: string;
    contact: Contact;

    constructor() {
        this.contact = new Contact();
    }
}
