import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthorizationService} from '../services/authorization.service';

@Directive({
    selector: '[appComponent]'
})
export class CanAccessDirective implements OnInit {
    @Input('appComponent') component: string;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private route: ActivatedRoute,
                private authorizationService: AuthorizationService) {
    }

    ngOnInit(): void {
        const promise = this.applyPermission();
        promise.then();
    }

    private applyPermission(): Promise<boolean> {
        return new Promise((resolve) => {
            this.authorizationService.isAuthenticated(this.component)
                .then((value: boolean) => {
                    if (value) {
                        this.viewContainer.createEmbeddedView(this.templateRef);
                    } else {
                        this.viewContainer.clear();
                    }
                });
        });
    }

}
