<img src="https://www.srdc.com.tr/wp-content/uploads/2014/12/srdc-wp.png" id="imgSRDC">

<div id="forgotPassForm">
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <div class="form-group ui form">
      <label><b>Mail Adresinizi Giriniz</b><span *ngIf="submitted && f.workEmail.errors && f.workEmail.errors.required" style="color: red"> *gerekli</span></label>
      <input style="margin-top: 1%" type="email" formControlName="workEmail" class="form-control ui input" [ngClass]="{ 'is-invalid': submitted && f.workEmail.errors }" />
    </div>
    <div class="form-group ui form">
        <button id="generateButton" class="ui right floated button">Sifremi Unuttum</button>
        <div *ngIf="wrongEmail">Please provide an existing email.</div>
    </div>
  </form>
</div>