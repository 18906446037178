import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LeaveExcuseManagementComponent} from './leave-excuse-management.component';

const routes: Routes = [
    {
        path: '',
        component: LeaveExcuseManagementComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeaveExcuseManagementRoutingModule { }
