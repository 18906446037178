import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { Environment } from 'src/models/environmentVariables';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentVariableService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/environment';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getEnvVariables(query?: any) {
        const URL = this.URL + '/variables';
        return this.http.get<Environment>(URL, {headers: this.headers, params: query});
    }

    public putUpdateEnvVariables(data: Environment) {
        const URL = this.URL + '/variables/' + data._id;
        return this.http.put<Environment>(URL, data, {headers: this.headers});
    }

}




