<img src="https://www.srdc.com.tr/wp-content/uploads/2014/12/srdc-wp.png" id="imgSRDC">

<div id="loginForm">
    <form [formGroup]="loginForm" >
        <div class="form-group ui form">
            <label><b>TC Kimlik No</b><span *ngIf="submitted && f.citizenID.errors && f.citizenID.errors.required" style="color: red"> *gerekli</span></label>
            <input style="margin-top: 1%" type="text" formControlName="citizenID" class="form-control ui input" [ngClass]="{ 'is-invalid': submitted && f.citizenID.errors }" />
        </div>
        <div class="form-group ui form">
            <label><b>Şifre</b><span *ngIf="submitted && f.password.errors && f.password.errors.required" style="color: red"> *gerekli</span></label>
            <input style="margin-top: 1%" type="password" formControlName="password" class="form-control ui input" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        </div>
        <div class="form-group ui form">
            <button id="loginButton" (click)="onSubmit()" [disabled]="loading" class="ui right floated button">Giriş Yap</button>
            <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            <div *ngIf="loginFailed">Login failed.</div>
            <button id="forgotPassword" (click)="navigate()" [disabled]="loading" class="ui right floated button">Şifremi Unuttum</button>
        </div>
    </form>
</div>
