import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Payment} from '../../../models/payment';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/payment';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getStatePayments() {
        const URL = this.URL + '/state';
        return this.http.get<Payment[]>(URL, {headers: this.headers});
    }

    public getCostItemPayments() {
        const URL = this.URL + '/cost-item';
        return this.http.get<Payment[]>(URL, {headers: this.headers});
    }

    public getAdvanceClosurePayments(paymentIds: number[]) {
        const URL = this.URL + '/advance-closure';
        return this.http.get<Payment[]>(URL, {headers: this.headers, params: {id: paymentIds.map(i => i.toString(10))}});
    }

    public getEarliestIncomePayments() {
        const URL = this.URL + '/earliest-income';
        return this.http.get<Date>(URL, {headers: this.headers});
    }

    public getHomeIncomePayments(query: any) {
        const URL = this.URL + '/income';
        return this.http.get<Payment[]>(URL, {headers: this.headers, params: query});
    }

    public getEarliestOutcomePayments() {
        const URL = this.URL + '/earliest-outcome';
        return this.http.get<Date>(URL, {headers: this.headers});
    }

    public getHomeOutcomePayments(query: any) {
        const URL = this.URL + '/outcome';
        return this.http.get<Payment[]>(URL, {headers: this.headers, params: query});
    }

    public getPaymentWithID(id: number) {
        const URL = this.URL + '/' + id;
        return this.http.get<Payment>(URL, {headers: this.headers});
    }

    public getMaxPaymentID() {
        const URL = this.URL + '/getMaxId';
        return this.http.post(URL, {headers: this.headers});
    }

    public addPayment(payment: Payment, query?: any) {
        const URL = this.URL + '/add';
        return this.http.post(URL, payment, {headers: this.headers, params: query});
    }

    public updatePayment(payment: Payment) {
        const URL = this.URL + '/' + payment.id;
        return this.http.put(URL, payment, {headers: this.headers});
    }

    public deletePayment(id: number) {
        const URL = this.URL + '/' + id;
        return this.http.delete(URL, {headers: this.headers});
    }

    public getPaymentsWithQuery(query: any) {
        return this.http.get<Payment[]>(this.URL, {headers: this.headers, params: query});
    }

    public pay(talimat: any) {
        const URL = this.URL + '/pay';
        return this.http.post(URL, talimat, {headers: this.headers, responseType: 'blob'});
    }

    public getABProjectIncomes(query: any) {
        const URL = this.URL + '/ab-project-income';
        return this.http.get<Payment[]>(URL, {headers: this.headers, params: query});
    }

    public getABProjectIncomesSize(query: any) {
        const URL = this.URL + '/ab-project-income/size';
        return this.http.get<{size: number}>(URL, {headers: this.headers, params: query});
    }

    public addIncome(data: any) {
        const URL = this.URL + '/addFonIncome';
        return this.http.post<{size: number}>(URL, data, {headers: this.headers});
    }

}
