export class VacationDaysEntry {
    _id: string;
    startDate: Date;
    endDate: Date;
    numberOfDays: number;
    description: string;

    constructor() {
        this.startDate = new Date();
        this.endDate = new Date();
    }
}
