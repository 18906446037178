import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from '../shared/services/authorization.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authorizationService: AuthorizationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (localStorage.getItem('currentUser')) { // logged in
            return new Promise((resolve) => {
                this.authorizationService.isAuthenticated(route.data.component)
                    .then((value: boolean) => {
                        if (!value) {
                            this.router.navigate(['/']); // redirect to home
                        }
                        resolve(true);
                    });
            });
        }
        this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return new Promise((resolve) => {
            resolve(false);
        });
    }

}
