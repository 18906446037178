import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Payroll} from '../../../models/payroll';

@Injectable({
    providedIn: 'root'
})
export class PayrollService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/payroll';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getSalaryPaymentPayrolls(query: any) {
        const URL = this.URL + '/salary-payment';
        return this.http.get<Payroll[]>(URL, {headers: this.headers, params: query});
    }

    public getPayrollDates() {
        const URL = this.URL + '/payroll-dates';
        return this.http.get<Date[]>(URL, {headers: this.headers});
    }

    public besWordDocument(talimat: any) {
        const URL = this.URL + '/bes';
        return this.http.post(URL, talimat, {headers: this.headers, responseType: 'blob'});
    }

    public getPeriodicReportPayrolls() { // real payroll
        const URL = this.URL + '/periodic-report';
        return this.http.get<Payroll[]>(URL, {headers: this.headers});
    }

    public getCostStatementPayrolls(query: any) { // real payroll
        const URL = this.URL + '/cost';
        return this.http.get<Payroll[]>(URL, {headers: this.headers, params: query});
    }

    public getPayrollsWithID(id: number) { // both
        const URL = this.URL + '/' + id;
        return this.http.get<Object[]>(URL, {headers: this.headers});
    }

    public getPreviousPayroll(date) { // payroll request
        const URL = this.URL + '/previous/' + date;
        return this.http.get<Payroll>(URL, {headers: this.headers});
    }

    public addPayroll(payroll: Payroll) {
        const URL = this.URL + '/add';
        return this.http.post(URL, payroll, {headers: this.headers});
    }

    public updatePayroll(payroll: Payroll) {
        const URL = this.URL + '/' + payroll.id;
        return this.http.put(URL, payroll, {headers: this.headers});
    }

    public deletePayroll(id: number) {
        const URL = this.URL + '/' + id;
        return this.http.delete(URL, {headers: this.headers});
    }

    public getActivityTrackingPayrolls(query: any) {
        return this.http.get<Payroll>(this.URL, {headers: this.headers, params: query});
    }

    public getHomePayrolls(query: any) {
        const URL = this.URL + '/home';
        return this.http.get<Payroll[]>(URL, {headers: this.headers, params: query});
    }

}
