<!-- SHOW ALL VACATIONS MODAL -->
<div appModal #showAllModal=modal class="ui modal">
    <div class="header">
        <b>Tüm İzinler</b>
        <i (click)="showAllModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <select (change)="showAllVacations()" [(ngModel)]="month" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
            <option *ngFor="let month of TRMonths" [ngValue]="month.value" name="month">
                {{month.month}}
            </option>
        </select>
        <select (change)="showAllVacations()" [(ngModel)]="year" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
            <option *ngFor="let year of years" [ngValue]=year name="year">{{year}}</option>
        </select>
        <table class="ui compact celled table">
            <thead>
            <tr>
                <th>Çalışan</th>
                <th>İzin Tarihi</th>
                <th>Gün Sayısı</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let vac of allVacations">
                <td>{{utilsService.getPersonFullName(vac.person)}}</td>
                <td>{{vac.date}}</td>
                <td>{{vac.numberOfDays}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<!-- EDIT VACATION MODAL -->
<div appModal #vacationModal=modal class="ui modal">
    <div class="header">
        <b>Yeni İzin Girişi</b>
        <i (click)="vacationModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <form class="ui form" xmlns="">
            <div *ngIf="wrongVacation" class="field"><i class="exclamation icon reqIcon">=gerekli</i></div>
            <div class="field">
                <label>Çalışan<span *ngIf="wrongVacation && !employee"><i class="exclamation icon reqIcon"></i></span></label>
                <select (change)="addEmployeeOption($event.target.value)" class="ui fluid search dropdown" id="employeeDropdown">
                    <option *ngFor="let item of allEmployees; trackBy: utilsService.trackByFn" [value]="item.citizenID" [selected]="employee && item.citizenID === employee.citizenID">{{utilsService.getPersonFullName(item.person)}}</option>
                </select>
            </div>
            <div class="two fields">
                <div class="field">
                    <label>Açıklama</label>
                    <input type="text" [(ngModel)]="vacation.description" name="description" placeholder="Açıklama">
                </div>
                <div class="field">
                    <label>İzinli gün sayısı<span *ngIf="wrongVacation && !vacation.numberOfDays"><i
                            class="exclamation icon reqIcon"></i></span></label>
                    <input type="number" [(ngModel)]="vacation.numberOfDays" name="numberOfDays">
                </div>
            </div>
            <div class="two fields">
                <div class="field">
                    <label>Başlangıç tarihi<span *ngIf="wrongVacation && !vacation.startDate"><i
                            class="exclamation icon reqIcon"></i></span></label>
                    <div class="ui icon input">
                        <i>
                            <mat-datepicker #startDateVacation></mat-datepicker>
                        </i>
                        <input [(ngModel)]="vacation.startDate" name="startDate"
                               [ngModelOptions]="{standalone: true}" [matDatepicker]="startDateVacation">
                        <mat-datepicker-toggle matSuffix [for]="startDateVacation"></mat-datepicker-toggle>
                    </div>
                </div>
                <div class="field">
                    <label>Bitiş tarihi<span *ngIf="wrongVacation && !vacation.endDate"><i
                            class="exclamation icon reqIcon"></i></span><span *ngIf="wrongVacationEnd"
                                                                              style="color: red"><i
                            class="exclamation icon reqIcon"></i>Bitiş tarihi başlangıç tarihinden önce olamaz</span></label>
                    <div class="ui icon input">
                        <i>
                            <mat-datepicker #endDateVacation></mat-datepicker>
                        </i>
                        <input [(ngModel)]="vacation.endDate" name="endDate"
                               [ngModelOptions]="{standalone: true}" [matDatepicker]="endDateVacation">
                        <mat-datepicker-toggle matSuffix [for]="endDateVacation"></mat-datepicker-toggle>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="actions">
        <button [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; addVacation(true)">
            Ekle<i class="checkmark icon"></i>
        </button>
    </div>
</div>

<!-- EDIT EXPERIENCE MODAL -->
<div appModal #experienceModal=modal class="ui modal">
    <div class="header">
        <b>Tecrübeye Göre Otomatik İzin Güncelleme</b>
        <i (click)="experienceModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <form *ngIf="environmentVariablesInDB" class="ui form" xmlns="">
            <div class="ui negative message">
                <div class="ui header">
                    Bu işlem geri alınamamaktadır. Aşağıdaki izin haklarıyla izin güncelleme işlemini onaylıyor musunuz?
                </div>
                <p>NOT: İzinlerin doğru güncellenebilmesi için çalışanların işe başlama tarihlerinin (resmi olmayan) doğruluğundan emin olun.</p>
            </div>
            <div class="inline fields">
                <div class="four wide field">
                    <div class="ui right labeled input">
                        <input [(ngModel)]="environmentVariablesInDB.experienceVacationRights[0]" [ngModelOptions]="{standalone: true}" type="number" placeholder="Taban">
                        <div class="ui basic label">Yıl</div>
                    </div>
                </div>
                <div class="field">
                    <i class="minus icon"></i>
                </div>
                <div class="four wide field">
                    <div class="ui right labeled input">
                        <input [(ngModel)]="environmentVariablesInDB.experienceVacationRights[1]" [ngModelOptions]="{standalone: true}" type="number" placeholder="Tavan">
                        <div class="ui basic label">Yıl</div>
                    </div>
                </div>
                <div class="four wide field" style="margin-left: 5% !important;">
                    <div class="ui right labeled input">
                        <input [(ngModel)]="environmentVariablesInDB.experienceVacationRights[3]" [ngModelOptions]="{standalone: true}" type="number" placeholder="İzin">
                        <div class="ui basic label">İş Günü</div>
                    </div>
                </div>
            </div>
            <div class="inline fields">
                <div class="four wide field">
                    <div class="ui right labeled input">
                        <input [(ngModel)]="environmentVariablesInDB.experienceVacationRights[1]" [ngModelOptions]="{standalone: true}" type="number" placeholder="Taban">
                        <div class="ui basic label">Yıl</div>
                    </div>
                </div>
                <div class="field">
                    <i class="minus icon"></i>
                </div>
                <div class="four wide field">
                    <div class="ui right labeled input">
                        <input [(ngModel)]="environmentVariablesInDB.experienceVacationRights[2]" [ngModelOptions]="{standalone: true}" type="number" placeholder="Tavan">
                        <div class="ui basic label">Yıl</div>
                    </div>
                </div>
                <div class="four wide field" style="margin-left: 5% !important;">
                    <div class="ui right labeled input">
                        <input [(ngModel)]="environmentVariablesInDB.experienceVacationRights[4]" [ngModelOptions]="{standalone: true}" type="number" placeholder="İzin">
                        <div class="ui basic label">İş Günü</div>
                    </div>
                </div>
            </div>
            <div class="inline fields">
                <div class="four wide field">
                    <div class="ui right labeled input">
                        <input [(ngModel)]="environmentVariablesInDB.experienceVacationRights[2]" [ngModelOptions]="{standalone: true}" type="number" placeholder="Taban">
                        <div class="ui basic label">Yıl</div>
                    </div>
                </div>
                <div class="field">
                    <i class="minus icon"></i>
                </div>
                <div class="four wide field">
                    <div class="ui right labeled input">
                        <input [disabled]="true" type="number" placeholder="Tavan">
                        <div class="ui basic label">Yıl</div>
                    </div>
                </div>
                <div class="four wide field" style="margin-left: 5% !important;">
                    <div class="ui right labeled input">
                        <input [(ngModel)]="environmentVariablesInDB.experienceVacationRights[5]" [ngModelOptions]="{standalone: true}" type="number" placeholder="İzin">
                        <div class="ui basic label">İş Günü</div>
                    </div>
                </div>
            </div>
            <div class="ui divider"></div>
            <div class="inline fields">
                <div class="field">
                    <b>Part-time:</b>
                </div>
                <div class="field">
                    <div class="ui right labeled input">
                        <input [(ngModel)]="environmentVariablesInDB.parttimeVacationRight" [ngModelOptions]="{standalone: true}" type="number" placeholder="İzin">
                        <div class="ui basic label">İş Günü</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="actions">
        <button [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; updateInNewYear()">
            İzinleri Güncelle
            <i class="checkmark icon"></i>
        </button>
    </div>
</div>

<!-- EDIT VACATION MODAL -->
<div appModal #vacationEntryModal=modal class="ui modal">
    <div class="header">
        <b *ngIf="isNewVacation">Yeni İzin Girişi</b>
        <b *ngIf="!isNewVacation">İzin Girişi Güncelleme</b>
        <i (click)="vacationEntryModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <form class="ui form" xmlns="">
            <div *ngIf="wrongVacation" class="field"><i class="exclamation icon reqIcon">=gerekli</i></div>
            <div class="two fields">
                <div class="field">
                    <label>Açıklama</label>
                    <input type="text" [(ngModel)]="vacation.description" name="description" placeholder="Açıklama">
                </div>
                <div class="field">
                    <label>İzinli gün sayısı<span *ngIf="wrongVacation && !vacation.numberOfDays"><i
                            class="exclamation icon reqIcon"></i></span></label>
                    <input type="number" [(ngModel)]="vacation.numberOfDays" name="numberOfDays">
                </div>
            </div>
            <div class="two fields">
                <div class="field">
                    <label>Başlangıç tarihi<span *ngIf="wrongVacation && !vacation.startDate"><i
                            class="exclamation icon reqIcon"></i></span></label>
                    <div class="ui icon input">
                        <i>
                            <mat-datepicker #startDateDP></mat-datepicker>
                        </i>
                        <input [(ngModel)]="vacation.startDate" name="startDate"
                               [ngModelOptions]="{standalone: true}" [matDatepicker]="startDateDP">
                        <mat-datepicker-toggle matSuffix [for]="startDateDP"></mat-datepicker-toggle>
                    </div>
                </div>
                <div class="field">
                    <label>Bitiş tarihi<span *ngIf="wrongVacation && !vacation.endDate"><i
                            class="exclamation icon reqIcon"></i></span><span *ngIf="wrongVacationEnd"
                                                                              style="color: red"><i
                            class="exclamation icon reqIcon"></i>Bitiş tarihi başlangıç tarihinden önce olamaz</span></label>
                    <div class="ui icon input">
                        <i>
                            <mat-datepicker #endDateDP></mat-datepicker>
                        </i>
                        <input [(ngModel)]="vacation.endDate" name="endDate"
                               [ngModelOptions]="{standalone: true}" [matDatepicker]="endDateDP">
                        <mat-datepicker-toggle matSuffix [for]="endDateDP"></mat-datepicker-toggle>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="actions">
        <button *ngIf="isNewVacation" [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; addVacation(false)">
            Ekle
            <i class="checkmark icon"></i>
        </button>
        <button *ngIf="!isNewVacation" [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; updateVacation()">
            Güncelle
            <i class="checkmark icon"></i>
        </button>
    </div>
</div>

<!-- ARE YOU SURE MODAL -->
<div appModal #areYouSureModal=modal class="ui mini modal">
    <div class="header">
        <b>İzni Sil</b>
        <i (click)="areYouSureModal.close()" class="close icon"
           style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        İzni silmek istediğinizden emin misiniz?
    </div>
    <div class="actions">
        <button [disabled]="clicked" class="ui positive button" (click)="clicked=true; deleteVacation()">Evet</button>
        <button [disabled]="clicked" class="ui negative button" (click)="areYouSureModal.close()">Hayır</button>
    </div>
</div>

<!-- DETAILS VIEW -->
<div *ngIf="pageView==='inputView'" id="inputView">
    <div class="column" style="margin-top: 51px;">
        <div class="ui breadcrumb">
            <div class="section">{{utilsService.getComponentPart('management')}}</div>
            <i class="right chevron icon divider"></i>
            <div class="section">{{utilsService.getComponentName('leaveExcuseManagement')}}</div>
            <i class="right chevron icon divider"></i>
            <div class="active section">{{utilsService.getPersonFullName(employee.person)}}</div>
        </div>
    </div>
    <div *ngIf="employee" class="container padded">
        <div class="row">
            <div class="ui grid one column padded">
                <div class="column">
                    <div class="ui fluid card">
                        <div class="content">
                            <div class="ui header">
                                {{utilsService.getPersonFullName(employee.person)}}
                            </div>
                        </div>
                        <div class="content">
                            <form class="ui form" xmlns="">
                                <div class="two fields">
                                    <div class="field">
                                        <label>İzin Hakkı</label>
                                        <input type="number"
                                               [(ngModel)]="employee.vacationInformation.numberOfVacationDaysRights"
                                               name="numberOfVacationDaysRights">
                                    </div>
                                    <div class="field">
                                        <label>Eski Yıllardan Devreden</label>
                                        <input type="number"
                                               [(ngModel)]="employee.vacationInformation.vacationDaysRemainingFromLastYear"
                                               name="vacationDaysRemainingFromLastYear">
                                    </div>
                                </div>
                                <table class="ui compact celled table">
                                    <thead>
                                    <tr>
                                        <th>Başlangıç Tarihi</th>
                                        <th>Bitiş Tarihi</th>
                                        <th>Gün Sayısı</th>
                                        <th>Açıklama</th>
                                        <th><select [(ngModel)]="selectedYear" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                            <option *ngFor="let year of years" [ngValue]=year>{{year}}</option>
                                        </select></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let vac of employee.vacationInformation.vacationDaysEntries | vacationDateFilter: selectedYear">
                                        <td>{{vac.startDate | date: 'dd/MM/yyyy'}}</td>
                                        <td>{{vac.endDate | date: 'dd/MM/yyyy'}}</td>
                                        <td>{{vac.numberOfDays}}</td>
                                        <td>{{vac.description}}</td>
                                        <td class="center aligned">
                                            <div class="ui icon buttons" style="box-sizing: border-box">
                                                <button [disabled]="clicked" class="ui button" data-tooltip="İzni Güncelle" (click)="clicked=true; editVacation(vac)" tabindex="0">
                                                    <i class="edit icon"></i>
                                                </button>
                                                <button class="ui negative button" data-tooltip="İzni Sil" (click)="vacationToDelete=vac; areYouSureModal.show()">
                                                    <i class="trash alternate outline icon"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot class="full-width">
                                    <tr>
                                        <th colspan="5">
                                            <button [disabled]="clicked" class="ui right floated primary labeled icon button" (click)="clicked=true; clearVacation(); vacationEntryModal.show(); clicked=false;" style="margin-right: 1%">
                                                <i class="add icon"></i> İzin Ekle
                                            </button>
                                        </th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </form>
                        </div>
                        <div class="extra content">
                            <button class="ui left labeled icon button" (click)="pageView='overview'" tabindex="0">
                                <i class="left arrow icon"></i>Geri
                            </button>
                            <button [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; updateInfo()">
                                Güncelle
                                <i class="checkmark icon"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- LIST ALL EMPLOYEES -->
<div [hidden]="pageView !== 'overview'" id="overview">
    <div class="ui fluid container padded">
        <div class="row">
            <div class="ui one column grid">
                <div class="column" style="margin-top: 51px;">
                    <div class="ui breadcrumb">
                        <div class="section">{{utilsService.getComponentPart('management')}}</div>
                        <i class="right chevron icon divider"></i>
                        <div class="active section">{{utilsService.getComponentName('leaveExcuseManagement')}}</div>
                    </div>
                    <table id="empTable" class="ui striped sortable celled table">
                        <colgroup>
                            <col span="1" style="width: 20%;">
                            <col span="1" style="width: 15%;">
                            <col span="1" style="width: 15%;">
                            <col span="1" style="width: 15%;">
                            <col span="1" style="width: 15%;">
                            <col span="1" style="width: 20%;">
                        </colgroup>
                        <thead>
                        <tr *ngIf="paginator">
                            <th (click)="paginator.onSortClick('person.firstName')">Çalışan
                                <i class="sort icon"
                                   [ngClass]="paginator.getNgClass('person.firstName')">
                                </i>
                            </th>
                            <th (click)="paginator.onSortClick('vacationInformation.vacationDaysRemainingFromLastYear')">
                                Eski Yıllardan Devreden
                                <i class="sort icon"
                                   [ngClass]="paginator.getNgClass('vacationInformation.vacationDaysRemainingFromLastYear')">
                                </i>
                            </th>
                            <th (click)="paginator.onSortClick('vacationInformation.vacationDaysUsedInThisYear')">
                                Bu Sene Kullanılan
                                <i class="sort icon"
                                   [ngClass]="paginator.getNgClass('vacationInformation.vacationDaysUsedInThisYear')">
                                </i>
                            </th>
                            <th (click)="paginator.onSortClick('vacationInformation.numberOfVacationDaysRights')">
                                İzin Hakkı
                                <i class="sort icon"
                                   [ngClass]="paginator.getNgClass('vacationInformation.numberOfVacationDaysRights')">
                                </i>
                            </th>
                            <th>
                                Kalan Gün
                            </th>
                            <th>
                                <button class="ui right floated positive labeled icon button" (click)="showVacationModal()" style="width: 48%">
                                    <i class="plus icon"></i>İzin Ekle
                                </button>
                                <button class="ui right floated negative primary labeled icon button" (click)="experienceModal.show()" style="width: 48%">
                                    <i class="refresh icon"></i>Yeni Yıl
                                </button>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <div class="ui fluid input">
                                    <input type="text" id="empSearch0"
                                           (keyup)="paginator.onSearchChanges('person.firstName', $event)"
                                           placeholder="Ara">
                                </div>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th class="center aligned">
                                <button class="ui primary labeled icon button" (click)="showAllVacations()">
                                    <i class="table icon"></i>Tüm İzinleri Görüntüle
                                </button>
                            </th>
                        </tr>
                        </thead>
                        <tbody *ngIf="employees && employees.length && requestCame">
                        <tr *ngFor="let emp of employees"
                            [ngClass]="{'negative': (emp.vacationInformation.numberOfVacationDaysRights + emp.vacationInformation.vacationDaysRemainingFromLastYear - emp.vacationInformation.vacationDaysUsedInThisYear) <= 0}">
                            <td>
                                {{utilsService.getPersonFullName(emp.person)}}
                            </td>
                            <td>
                                {{emp.vacationInformation.vacationDaysRemainingFromLastYear}}
                            </td>
                            <td>
                                {{emp.vacationInformation.vacationDaysUsedInThisYear}}
                            </td>
                            <td>
                                {{emp.vacationInformation.numberOfVacationDaysRights}}
                            </td>
                            <td>
                                {{emp.vacationInformation.numberOfVacationDaysRights + emp.vacationInformation.vacationDaysRemainingFromLastYear - emp.vacationInformation.vacationDaysUsedInThisYear}}
                            </td>
                            <td class="center aligned">
                                <button [disabled]="clicked" class="ui icon button" data-tooltip="Detaylar" (click)="clicked=true; infoModalF(emp)" tabindex="0">
                                    <i class="eye icon"></i>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                        <tbody *ngIf="!employees.length && requestCame">
                        <tr>
                            <td colspan="100%">
                                <div class="ui info message">
                                    <div class="ui header">
                                        Personel Bulunmamaktadır
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                        <tbody *ngIf="!requestCame">
                        <tr>
                            <td colspan="100%">
                                <div class="ui info message">
                                    <div class="content">
                                        <div class="ui text active centered inline loader">
                                            Yükleniyor...
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot class="full-width" *ngIf="paginator && paginator.isPaginatorVisible()">
                        <tr>
                            <th colspan="1" style="border-right: none">
                                <label>Gösterim: </label>
                                <select (change)="paginator.onPageSizeChange()" [(ngModel)]="paginator.selectedPageSize" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                    <option *ngFor="let pageSize of paginator.pageSizeOptions" [ngValue]=pageSize name="pageSize">{{pageSize}}</option>
                                </select>
                            </th>
                            <th colspan="4" style="border-left: none" class="center aligned">
                                <div class="ui menu"
                                     *ngIf="paginator.isPagesMenuVisible()"
                                     style="width: fit-content; display: inline-flex">
                                    <ng-container *ngIf="paginator.selectedPage !== 1">
                                        <div class="icon item" (click)="paginator.changePage(1)" style="cursor: pointer">
                                            <i class="large angle double left icon" ></i>
                                        </div>
                                        <div class="icon item" (click)="paginator.changePage(0)" style="cursor: pointer">
                                            <i class="large angle left icon" ></i>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let number of [4,3,2,1]">
                                        <a *ngIf="paginator.selectedPage > number && paginator.selectedPage > paginator.pageCount - (5 - number)" class="item" (click)="paginator.changePage(paginator.selectedPage - number)">
                                            {{paginator.selectedPage - number}}
                                        </a>
                                    </ng-container>
                                    <a class="active item">{{paginator.selectedPage}}</a>
                                    <ng-container *ngFor="let number of [1,2,3]">
                                        <a *ngIf="paginator.selectedPage < paginator.pageCount - number" class="item" (click)="paginator.changePage(paginator.selectedPage + number)">
                                            {{paginator.selectedPage + number}}
                                        </a>
                                    </ng-container>
                                    <div *ngIf="paginator.selectedPage  < paginator.pageCount - 4" class="disabled item">...</div>
                                    <a *ngIf="paginator.pageCount !== 0 &&  paginator.selectedPage !== paginator.pageCount" class="item" (click)="paginator.changePage(paginator.pageCount)">
                                        {{paginator.pageCount}}
                                    </a>
                                    <ng-container *ngIf="paginator.selectedPage !== paginator.pageCount && paginator.pageCount !== 0">
                                        <div class="icon item" (click)="paginator.changePage(paginator.pageCount + 1)" style="cursor: pointer">
                                            <i class="large angle right icon" ></i>
                                        </div>
                                        <div class="icon item" (click)="paginator.changePage(paginator.pageCount)" style="cursor: pointer">
                                            <i class="large angle double right icon" ></i>
                                        </div>
                                    </ng-container>
                                </div>
                            </th>
                            <th colspan="1" style="border-left: none"></th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
