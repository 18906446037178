import {Directive, DoCheck, ElementRef, HostListener, Input, OnInit} from '@angular/core';

declare const $: any;

@Directive({
    selector: '[appSidebar]'
})
export class SidebarDirective implements OnInit, DoCheck {
    @Input() sidebarContext: string;

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        (<any>$(this.el.nativeElement))
            .sidebar({context: $(this.sidebarContext)});
        if (window.innerWidth <= 993) {
            $(this.el.nativeElement).removeClass('visible');
            $(this.sidebarContext + ' > .pusher').outerWidth('100%');
            $(this.sidebarContext + ' > .pusher').css({'overflow-y': 'auto'});
            $(this.sidebarContext + ' > .ui.menu.custom').outerWidth('100%');
        } else {
            $(this.el.nativeElement).addClass('visible');
            $(this.sidebarContext + ' > .pusher').outerWidth(window.innerWidth - 260);
            $(this.sidebarContext + ' > .pusher').css({'overflow-y': 'scroll'});
            $(this.sidebarContext + ' > .ui.menu.custom').outerWidth(window.innerWidth - 260);
        }
    }

    ngDoCheck() {
        // do something if visible
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if ($(this.el.nativeElement).get(0).title !== 'login') {
            if (window.innerWidth <= 993) {
                $(this.el.nativeElement).removeClass('visible');
                $(this.sidebarContext + ' > .pusher').outerWidth('100%');
                $(this.sidebarContext + ' > .pusher').css({'overflow-y': 'auto'});
                $(this.sidebarContext + ' > .ui.menu.custom').outerWidth('100%');
            } else {
                $(this.el.nativeElement).addClass('visible');
                $(this.sidebarContext + ' > .pusher').outerWidth(window.innerWidth - 260);
                $(this.sidebarContext + ' > .pusher').css({'overflow-y': 'scroll'});
                $(this.sidebarContext + ' > .ui.menu.custom').outerWidth(window.innerWidth - 260);
            }
        } else {
            this.hide();
        }
    }

    toggle() {
        (<any>$(this.el.nativeElement)).sidebar('toggle');
    }

    hide() {
        $(this.el.nativeElement).get(0).title = 'login';
        $(this.el.nativeElement).removeClass('visible');
        $(this.sidebarContext + ' > .pusher').outerWidth('100%');
        $(this.sidebarContext + ' > .pusher').css({'overflow-y': 'auto'});
        $(this.sidebarContext + ' > .ui.menu.custom').hide();
    }

    show() {
        $(this.el.nativeElement).get(0).title = ''; // Show no popup text when hovering over sidebar links
        if (window.innerWidth <= 993) {
            $(this.el.nativeElement).removeClass('visible');
            $(this.sidebarContext + ' > .pusher').outerWidth('100%');
            $(this.sidebarContext + ' > .pusher').css({'overflow-y': 'auto'});
            $(this.sidebarContext + ' > .ui.menu.custom').show();
        } else {
            $(this.el.nativeElement).addClass('visible');
            $(this.sidebarContext + ' > .pusher').outerWidth(window.innerWidth - 260);
            $(this.sidebarContext + ' > .pusher').css({'overflow-y': 'scroll'});
            $(this.sidebarContext + ' > .ui.menu.custom').show();
        }
    }

    animate() {
        const e = document.createElement('div');
        $(e).html($(this.el.nativeElement).html());
        $(e).addClass(this.el.nativeElement.classList.toString());
        $(e).addClass('old');
        $(e).appendTo('body');
        const newSidebar = $('[appSidebar]:not(.old)');
        newSidebar.css('left', '-260px');
        setTimeout(() => {
            $(e).css('z-index', '-1');
            newSidebar.css('left', '0');
        }, 500);
        setTimeout(() => {
            $(e).remove();
        }, 1000);
    }
}
