import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Organization} from '../../../models/organization';
import {environment} from '../../../environments/environment';
import {BankAccount} from '../../../models/bankAccount';
import { TimeSeries } from 'src/models/timeSeries';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/organization';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getManagementOrganizations(query?: any) {
        const URL = this.URL + '/management';
        return this.http.get<Organization[]>(URL, {headers: this.headers, params: query});
    }

    public getOrganizationsSize(query?: any) {
        const URL = this.URL + '/size';
        return this.http.get<{size: number}>(URL, {headers: this.headers, params: query});
    }

    public getOrganizationsSummary() {
        const URL = this.URL + '/summary';
        return this.http.get<Organization[]>(URL, {headers: this.headers});
    }

    public getOrganizationWithID(id: number) {
        const URL = this.URL + '/' + id;
        return this.http.get<Organization>(URL, {headers: this.headers});
    }

    public getSRDC() {
        return this.getOrganizationWithID(environment.SRDCOrganizationID);
    }

    public addOrganization(organization: Organization) {
        const URL = this.URL + '/add';
        return this.http.post(URL, organization, {headers: this.headers});
    }

    public updateOrganization(organization: Organization) {
        const URL = this.URL + '/' + organization.id;
        return this.http.put(URL, organization, {headers: this.headers});
    }

    public deleteOrganization(id: number) {
        const URL = this.URL + '/' + id;
        return this.http.delete(URL, {headers: this.headers});
    }

    public getBankAccounts(id: number, query?: any) {
        const URL = this.URL + '/' + id + '/bank-accounts';
        return this.http.get<BankAccount[]>(URL, {headers: this.headers, params: query});
    }

    public getTimeSeriesWithAccID(srdcID: number, accountID: string, query?: any) {
        const URL = this.URL + '/' + srdcID + '/bank-accounts/timeSeries/' + accountID;
        return this.http.get<TimeSeries[]>(URL, {headers: this.headers, params: query});
    }

    public getAvailableBalance(srdcID: number, accountID: string, query?: any) {
        const URL = this.URL + '/' + srdcID + '/bank-accounts/' + accountID + '/availBalance';
        return this.http.get<{size: number}>(URL, {headers: this.headers, params: query});
    }

    public getTimeSeriesSizeByAccID(srdcID: number, accountID: string, query?: any) {
        const URL = this.URL + '/' + srdcID + '/bank-accounts/timeSeries/' + accountID + '/size';
        return this.http.get<{size: number}>(URL, {headers: this.headers, params: query});
    }

    public getBankAccountsSize(id: number, query?: any) {
        const URL = this.URL + '/' + id + '/bank-accounts/size';
        return this.http.get<{size: number}>(URL, {headers: this.headers, params: query});
    }

    public saveUpdateBankAccount(organization_id: number, bankAccount: BankAccount, query?: any) {
        const URL = this.URL + '/' + organization_id + '/bank-accounts';
        return this.http.put(URL, bankAccount, {headers: this.headers, params: query});
    }

    public changeBankAccountActiveness(organization_id: number, _id: string, activate: boolean) {
        const URL = this.URL + '/' + organization_id + '/bank-accounts/activation/' + _id;
        return this.http.patch(URL, {}, {headers: this.headers, params: {activate: activate.toString()}});
    }

    public addTimeSeries(organization_id: number, ts: TimeSeries, query?: any) {
        const URL = this.URL + '/' + organization_id + '/bank-accounts';
        return this.http.post(URL, ts , {headers: this.headers, params: query});
    }

    public updateTimeSeries(organization_id: number, ts: TimeSeries, query?: any) {
        const URL = this.URL + '/' + organization_id + '/bank-accounts/' + ts._id;
        return this.http.put(URL, ts , {headers: this.headers, params: query});
    }

    public deleteTimeSeries(organization_id: number, ts: TimeSeries, query?: any) {
        const URL = this.URL + '/' + organization_id + '/bank-accounts/' + ts._id;
        return this.http.delete(URL, {headers: this.headers, params: query});
    }

}




