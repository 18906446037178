import {Injectable} from '@angular/core';
import {Travel} from '../../../models/travel';
import {Invoice} from '../../../models/invoice';
import {Payment} from '../../../models/payment';
import {Prize} from '../../../models/prize';
import {Payroll} from '../../../models/payroll';
import {TimeSeries} from '../../../models/timeSeries';
import {Person} from '../../../models/person';
import {ComponentUtil} from '../../util/ComponentUtil';

declare const $: any;

@Injectable({
    providedIn: 'root'
})

export class UtilsService {

    static NOT_NULL_TOKEN = '*';

    filteredRows = [];
    isAscending = false;
    isSorted = false;
    lastSorted = '';
    sortedTable = '';
    sortNum = -1;

    static deepCopy(object: any) {
        if (object === undefined) {
            return undefined;
        }
        return JSON.parse(JSON.stringify(object));
    }

    getComponentPart(type: string): string {
        return ComponentUtil.MAIN_PARTS[type];
    }

    getComponentName(name: string): string {
        return ComponentUtil.COMPONENT_NAMES[name];
    }

    getPersonFullName(person: Person): string {
        return person.firstName + ' ' + (person.middleName ? (person.middleName + ' ') : '') + person.lastName;
    }

    parseDate(dateStr) {
        if (dateStr.split('/').length === 3) {
            return new Date(dateStr.split('/')[2], dateStr.split('/')[1], dateStr.split('/')[0]).getTime();
        }
        return new Date(dateStr.split('/')[1], dateStr.split('/')[0], 1).getTime();
    }

    parseNumericalData(numStr) {
        let num = '';
        for (let i = 0; i < numStr.length; i++) {
            if (numStr[i] !== ',') {
                num += numStr[i];
            }
        }
        return parseFloat(num);
    }

    formatDouble(num, digitNumber = 2, isTR = true): string {
        const decimalSeparator = isTR ? '.' : ',';
        const hundredSeparator = isTR ? ',' : '.';

        if (num ==  null) {
            num = 0;
        }
        if (typeof num !== 'number') {
            num = parseFloat(num);
        } else {
            num = Number(num);
        }
        num = num.toFixed(digitNumber).replace('.', decimalSeparator);
        num = '' + num;

        const s = num.split(decimalSeparator);
        s[0] = this.reverseString(s[0]);
        let i = 3;
        while (s[0][i]) {
            s[0] = this.insert(s[0], hundredSeparator, i);
            i += 4;
        }
        s[0] = this.reverseString(s[0]);

        if (s[0][0] === '-' && s[0][1] === hundredSeparator) { // negative number
            s[0] = this.removeCharAt(s[0], 1);
        }
        return s[0] + decimalSeparator + s[1];
    }

    reverseString(str) {
        const splitString = str.split('');
        const reverseArray = splitString.reverse();
        return reverseArray.join('');
    }

    insert = function insert(main_string, ins_string, pos) {
        if (typeof (pos) === 'undefined') {
            pos = 0;
        }
        if (typeof (ins_string) === 'undefined') {
            ins_string = '';
        }
        return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
    };

    removeCharAt = function (str, i) {
        const tmp = str.split('');
        tmp.splice(i, 1);
        return tmp.join('');
    };

    trackByFn(index, item) {
        if (item.citizenID) {
            return item.citizenID;
        }
        return item.id ? item.id : item.projectId;
    }

    convertTurkishCharacters = function(str) {
        const translate_re = /[öÖüÜıİğĞçÇşŞ]/g;
        const translate = {
            'ö': 'o', 'Ö': 'O', 'ü': 'u', 'Ü': 'U', 'ı': 'i', 'İ': 'I',
            'ğ': 'g', 'Ğ': 'G', 'ç': 'c', 'Ç': 'C', 'ş': 's', 'Ş': 'S'
        };
        return ( str.replace(translate_re, function(match) {
            return translate[match];
        }) );
    };

    filterFunc(tableID, inputID, column) {
        // Declare variables
        let input, filter, table, tr, td;
        input = document.getElementById(inputID);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableID);
        tr = table.getElementsByTagName('tr');

        // Loop through all table rows, and hide those who don't match the search query
        let index = 0;
        for (const item of tr) {
            td = item.getElementsByTagName('td')[column];
            if (td) {
                if (td.innerText.toUpperCase().indexOf(filter) > -1) {
                    let showRow = true;
                    for (const tuple of this.filteredRows) {
                        if (tuple[0] === index) {
                            if (tuple[1] !== column) {
                                showRow = false;
                            } else {
                                this.removeFromArray(this.filteredRows, tuple);
                            }
                        }
                    }
                    if (showRow) {
                        item.style.display = '';
                    }
                } else {
                    let willBeAdded = true;
                    for (const tuple of this.filteredRows) {
                        if (tuple[0] === index && tuple[1] === column) {
                            willBeAdded = false;
                            break;
                        }
                    }
                    if (willBeAdded) {
                        item.style.display = 'none';
                        this.filteredRows.push([index, column]);
                    }
                }
            }
            index++;
        }
    }

    removeFromArray(data: any[], item: any) {
        const index: number = data.indexOf(item);
        if (index !== -1) {
            data.splice(index, 1);
        }
    }

    sortColumn(tableID, column, isNumeric) {
        const sortedData = [];
        // Declare variables
        this.sortNum = column;
        this.sortedTable = tableID;
        let table, tr;
        table = document.getElementById(tableID);
        tr = table.getElementsByTagName('tr');

        for (let k = 2; k < tr.length; k++) {
            sortedData.push(tr[k]);
        }
        const arrowStatus = {
            LastClickedCol : column,
            Status : {
                Up: false,
                Down: false,
                Flat: true
            }
        };

        if (this.lastSorted !== tableID + column) {
            this.isAscending = false;
            this.isSorted = false;
        }
        let needSwap = true;
        while (needSwap) {
            needSwap = false;
            for (let j = 0; j < sortedData.length - 1; j++) {
                let cmp1 = sortedData[j].getElementsByTagName('td')[column].innerText.toLowerCase();
                let cmp2 = sortedData[j + 1].getElementsByTagName('td')[column].innerText.toLowerCase();
                if (this.executeDateRegex(cmp1, /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/)) {
                    cmp1 = this.parseDate(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/.exec(cmp1)[0]);
                    cmp2 = this.parseDate(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/.exec(cmp2)[0]);
                } else if (this.executeDateRegex(cmp1, /^(((0)[0-9])|((1)[0-2]))(\/)\d{4}/)) {
                    cmp1 = this.parseDate(/^(((0)[0-9])|((1)[0-2]))(\/)\d{4}/.exec(cmp1)[0]);
                    cmp2 = this.parseDate(/^(((0)[0-9])|((1)[0-2]))(\/)\d{4}/.exec(cmp2)[0]);
                } else {
                    if (isNumeric) {
                        cmp1 = this.executeRegex(cmp1, /(\d{1,3},(\d{3},)*\d{3}(\.\d{1,4})?|\d{1,3}(\.\d{1,4}))/);
                        cmp2 = this.executeRegex(cmp2, /(\d{1,3},(\d{3},)*\d{3}(\.\d{1,4})?|\d{1,3}(\.\d{1,4}))/);
                    }
                }
                if (this.isAscending && cmp1 > cmp2) {
                    [sortedData[j], sortedData[j + 1]] = [sortedData[j + 1], sortedData[j]];
                    needSwap = true;
                    arrowStatus.Status.Flat = false;
                    arrowStatus.Status.Down = false;
                    arrowStatus.Status.Up = true;
                } else if (!this.isAscending && cmp1 < cmp2) {
                    [sortedData[j], sortedData[j + 1]] = [sortedData[j + 1], sortedData[j]];
                    needSwap = true;
                    arrowStatus.Status.Flat = false;
                    arrowStatus.Status.Down = true;
                    arrowStatus.Status.Up = false;
                }
            }
        }
        let parent = tr[2].parentNode;
        for (let k = 2; k < tr.length; k++) {
            parent = tr[k].parentNode;
            parent.appendChild(sortedData[k - 2]);
        }

        this.isAscending = !this.isAscending;
        this.isSorted = true;
        this.lastSorted = tableID + column;
        return arrowStatus;
    }

    executeRegex(str, regex) {
        let m;
        if ((m = regex.exec(str)) !== null) {
            return this.parseNumericalData(m[0]);
        }
        return 0;
    }

    executeDateRegex(str, regex) {
        return regex.exec(str) !== null;
    }

    comparebyDate(obj1: Payment | Invoice | Travel | Payroll | Prize | TimeSeries | any, obj2: Payment | Invoice | Travel | Payroll | Prize | TimeSeries | any, ascending: boolean) {
        let comp1 = obj1;
        let comp2 = obj2;
        if (obj1.date) {
            comp1 = new Date(obj1.date).getTime();
            comp2 = new Date(obj2.date).getTime();
        } else if (obj1.startDate) {
            comp1 = new Date(obj1.startDate).getTime();
            comp2 = new Date(obj2.startDate).getTime();
        } else if (obj1.applicationDate) {
            comp1 = new Date(obj1.applicationDate).getTime();
            comp2 = new Date(obj2.applicationDate).getTime();
        }
        if (comp1 < comp2) {
            return ascending ? -1 : 1;
        } else if (comp1 > comp2) {
            return ascending ? 1 : -1;
        }
        return 0;
    }

    sortByAscendingDate(obj1: Payment | Invoice | Travel | Payroll | Prize | TimeSeries | any, obj2: Payment | Invoice | Travel | Payroll | Prize | TimeSeries | any) {
        return this.comparebyDate(obj1, obj2, true);
    }

    sortByDescendingDate(obj1: Payment | Invoice | Travel | Payroll | Prize | TimeSeries | any, obj2: Payment | Invoice | Travel | Payroll | Prize | TimeSeries | any) {
        return this.comparebyDate(obj1, obj2, false);
    }

    groupBy(array, f) {
        const groups = {};
        array.forEach(function (o) {
            const group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        });
    }

    customizeLabelPie(arg) {
        const reverseString = function (str) {
            const splitString = str.split('');
            const reverseArray = splitString.reverse();
            return reverseArray.join('');
        };

        const insert = function (main_string, ins_string, pos) {
            if (typeof (pos) === 'undefined') {
                pos = 0;
            }
            if (typeof (ins_string) === 'undefined') {
                ins_string = '';
            }
            return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
        };

        let num = arg.value.toFixed(2);
        num = '' + num;

        let s = num.split('.');

        s[0] = reverseString(s[0]);
        let i = 3;
        while (s[0][i]) {
            s[0] = insert(s[0], ',', i);
            i += 4;
        }
        s[0] = reverseString(s[0]);
        s = s[0] + '.' + s[1];

        return s + ' (' + (arg.percent * 100).toFixed(2) + '%)';
    }

    configureDropdown(dropdownID: string) {
        $(dropdownID).dropdown({
            onNoResults: function() {
                $('#noResult').show();
            },
            onChange: function () {
                $('#noResult').hide();
            }
        });
        $('input').keypress(() => {
            $('#noResult').hide();
        });
    }
    testFloatText(text: string) {
        const re = /^(-?[0-9]+([.,][0-9]+)$)|^(-?[0-9]+)$/;
        const array = text.match(re);
        if (!array) {
            return false;
        }
        const match = array[0];
        return !isNaN(this.parseFloatText(match));
    }

    getISODate(date: Date) {
        // start converting UTC string to ISO string.
        // the following lines chop the GMT+3 part so that
        // ISO times are not 3 hrs earlier than they are supposed to be.
        const timeZoneDifference = (date .getTimezoneOffset() / 60) * -1;
        date.setTime(date .getTime() + (timeZoneDifference * 60) * 60 * 1000);
        const searchISODate = date.toISOString();
        return searchISODate;
    }

    parseFloatText(text: string) {
        if (text.search(',') != -1) {
            // , found
            const re = /,/;
            const str = text.replace(re, ".");
            return parseFloat(str);
        }
        return parseFloat(text);
    }

    monthDiff(d1, d2) {
        const little = d1 < d2 ? d1 : d2;
        const big = d1 > d2 ? d1 : d2;
        let months;
        months = (big.getFullYear() - little.getFullYear()) * 12;
        months -= little.getMonth();
        months += big.getMonth();
        return months <= 0 ? 0 : months;
    }


}
