import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {UserAuthorization} from '../../../models/userAuthorization';

@Injectable({
    providedIn: 'root'
})
export class UserAuthorizationService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/user-authorization';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    getAllAuthorizations() {
        return this.http.get<UserAuthorization[]>(this.URL, {headers: this.headers});
    }

    getEmployeePermissions(citizenID: string) {
        const URL = this.URL + '/employee/' + citizenID;
        return this.http.get<any>(URL, {headers: this.headers});
    }

    updateAuthorization(auth: UserAuthorization) {
        const URL = this.URL + '/' + auth.component;
        return this.http.put(URL, auth, {headers: this.headers});
    }

}
