import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Fon} from '../../../models/fon';
import { TimeSeries } from 'src/models/timeSeries';

@Injectable({
    providedIn: 'root'
})
export class FonService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/fon';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getFon() {
        return this.http.get<Fon>(this.URL, {headers: this.headers});
    }

    public addFon(fon: Fon) {
        const URL = this.URL + '/add';
        return this.http.post(URL, fon, {headers: this.headers});
    }

    public deleteTS(fonID: string, accTSID, query?: any) {
        const URL = this.URL + '/removeTS/' + fonID + '/' + accTSID;
        return this.http.delete(URL, {headers: this.headers, params: query});
    }

    public addTimeSeries(balance: TimeSeries, fonID: string, query?: any) {
        const URL = this.URL + '/addTS/' + fonID;
        return this.http.post(URL, balance, {headers: this.headers, params: query});
    }

    public updateTS(balance: TimeSeries, fonID: string, query?: any) {
        const URL = this.URL + '/updateTS/' + fonID;
        return this.http.put(URL, balance, {headers: this.headers, params: query});
    }

    public getTSSize(query?: any) {
        const URL = this.URL + '/accTS-size';
        return this.http.get(URL, {headers: this.headers, params: query});
    }

    public getTS(query?: any) {
        const URL = this.URL + '/accTS';
        return this.http.get(URL, {headers: this.headers, params: query});
    }

    public getTotalBalance(fonID?: any, query?: any) {
        const URL = this.URL + '/totalBalance';
        return this.http.get(URL, {headers: this.headers, params: query});
    }
    
    public getTotalBalanceExport(fonID?: any,) {
        const URL = this.URL + '/totalBalanceExport';
        return this.http.get(URL,  {headers: this.headers, responseType: 'arraybuffer'});
    }
}
