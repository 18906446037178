
export class Course {
    courseCode: string;
    startHour: number;
    endHour: number;
    day: number;

    constructor(courseCode: string, startHour: number, endHour: number, day: number) {
        this.courseCode = courseCode;
        this.startHour = startHour;
        this.endHour = endHour;
        this.day = day;
    }
}
