import {PaginatorBuilder} from './paginator-builder';
import {Subscription} from 'rxjs/internal/Subscription';
import * as moment from 'moment';
import {UtilsService} from './services/utils.service';

type FunctionType = () => Subscription;

export class Paginator {
    static SEARCH_QUERY_PREFIX = 'search_';
    static STARTDATE_QUERY_PREFIX = 'start_';
    static ENDDATE_QUERY_PREFIX = 'end_';

    totalSize: number;
    pageCount: number; // Total page count
    refreshFunction: FunctionType;
    resizeFunction: FunctionType;

    selectedPage = 1;

    pageSizeOptions;
    selectedPageSize;

    sortField: string;
    sortType: number;

    searchQueries: Map<string, string> = new Map();
    dateQueries: Map<string, string> = new Map();

    private refreshSubscription: Subscription;
    private resizeSubscription: Subscription;

    constructor(builder: PaginatorBuilder) {
        this.totalSize = builder.totalSize;
        this.pageSizeOptions = builder.pageSizeOptions;
        this.selectedPageSize = builder.selectedPageSize;
        this.selectedPage = builder.selectedPage;
        this.refreshFunction = builder.refreshFunction;
        this.resizeFunction = builder.resizeFunction;

        this.recalculatePageCount();

    }

    /**
     * The following 2 functions are defined to prevent race conditions in GET queries.
     * For example: In search (note that each keyup triggers a GET request), a user might type so fast that
     * multiple GET requests which causes unexpected results to occur.
     */

    callRefreshFunction() {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
        this.refreshSubscription = this.refreshFunction();
    }

    callResizeFunction() {
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
        this.resizeSubscription = this.resizeFunction();
    }

    /**************************************************/

    addFilterQueries(query: any) {
        this.searchQueries.forEach((searchText, searchField) => query[Paginator.SEARCH_QUERY_PREFIX + searchField] = searchText);
        this.dateQueries.forEach((dateText, dateField) => query[dateField] = dateText);
    }


    /**
     * resets search and sort queries.
     */
    resetQueries() {
        this.sortType = this.sortField = undefined;
        this.searchQueries.clear();
        this.dateQueries.clear();
    }

    addPagination(query: any) {
        query['limit'] = this.selectedPageSize;
        query['page'] = this.selectedPage;
        if (this.sortField && this.sortType) {
            query['sort_field'] = this.sortField;
            query['sort_type'] = this.sortType;
        }
    }

    recalculatePageCount() {
        this.pageCount = Math.ceil(this.totalSize / this.selectedPageSize);
    }

    changePage(newPage: number) {
        if (newPage === 0) { // left arrow is clicked
            if (this.selectedPage > 1) {
                this.selectedPage--;
            }
        } else if (newPage === this.pageCount + 1) { // right arrow is clicked
            if (this.selectedPage < this.pageCount) {
                this.selectedPage++;
            }
        } else { // page number is clicked
            this.selectedPage = newPage;
        }
        this.callRefreshFunction();
    }

    /********************* onXXX METHODS **************************/

    /**
     * should be called whenever a price object is created or deleted
     * @param increment true if created false otherwise.
     */
    onItemCountChange(increment: boolean) {
        this.totalSize = increment ? this.totalSize + 1 : this.totalSize - 1;
        const newPageCount = Math.ceil(this.totalSize / this.selectedPageSize);
        if (newPageCount !== this.pageCount) { // Either a new page is created or became empty. <pageNumbers> may be needed to be refreshed.
            this.recalculatePageCount();
            if (!increment && this.selectedPage > this.pageCount && this.selectedPage !== 1) { // Selected page is now empty, reselect the previous one.
                this.selectedPage--;
            }
            this.changePage(this.selectedPage); // Display the page the user is currently on in the page list.
        }
        this.callRefreshFunction();
    }

    onPageSizeChange() {
        this.recalculatePageCount();
        this.selectedPage = 1; // After changing page size there is no point on staying in the current page.
        this.callRefreshFunction();
    }

    onTotalSizeChange(totalSize: number) {
        this.totalSize = totalSize;
        this.changePage(1);
        this.recalculatePageCount();
    }

    onSortClick(fieldName: string, preventRefreshing?: boolean) {
        if (this.sortField === fieldName) {
            this.sortType *= -1;
        } else {
            this.sortField = fieldName;
            this.sortType = -1;
        }
        if (!preventRefreshing) {
            this.callRefreshFunction();
        }
    }

    onSearchChanges(searchField: string, event: any) {
        const val = event.target.value;
        if (!val || val === '') {
            this.searchQueries.delete(searchField);
        } else {
            this.searchQueries.set(searchField, val);
        }
        this.callRefreshFunction();
        this.callResizeFunction();
    }

    onDateChanges(dateField: string, event: any): void {
        this.dateQueries.clear();
        const DATE_FORMAT = 'YYYY-MM-DD';
        if (event.value) {
            const startDate = new Date(UtilsService.deepCopy(event.value.begin));
            const endDate = new Date(UtilsService.deepCopy(event.value.end));

            this.dateQueries.set(Paginator.STARTDATE_QUERY_PREFIX + dateField, moment(startDate).format(DATE_FORMAT));
            this.dateQueries.set(Paginator.ENDDATE_QUERY_PREFIX + dateField, moment(endDate).format(DATE_FORMAT));

        }

        this.callRefreshFunction();
        this.callResizeFunction();
    }

    /******************** END OF onXXX METHODS *********************/


    /************************ GUI METHODS **************************/

    /**
     * used for changing sort icon class to sort icon down or sort icon up.
     * @param fieldName
     */
    getNgClass(fieldName: string) {
        const isASC = fieldName === this.sortField && this.sortType === 1;
        const isDESC = fieldName === this.sortField && this.sortType === -1;
        return {'up': isDESC, 'down': isASC};
    }

    /**
     * Do not show anything related to paginator if totalSize is less than the minimum value in pageSizeOptions.
     */
    isPaginatorVisible() {
        return this.pageSizeOptions.length && this.totalSize > Math.min(...this.pageSizeOptions);
    }

    /**
     * Do not show page menu if there exists only one page.
     */
    isPagesMenuVisible() {
        return this.pageCount > 1;
    }

    /************************ END OF GUI METHODS ****************************/

}
