import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InvoiceReportService = /** @class */ (function () {
    function InvoiceReportService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/monthlyCostTableForAccountantByEmployer';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    InvoiceReportService.prototype.getOpenInvoices = function () {
        var URL = this.URL + '/openInvoices';
        return this.http.get(URL, { headers: this.headers });
    };
    InvoiceReportService.prototype.getPeriodWithYear = function (year) {
        var URL = this.URL + '/' + year;
        return this.http.get(URL, { headers: this.headers });
    };
    InvoiceReportService.prototype.getPeriodWithDate = function (year, month) {
        var URL = this.URL + '/' + year + '/' + month;
        return this.http.get(URL, { headers: this.headers });
    };
    InvoiceReportService.prototype.updatePeriod = function (invoice, index, oldInvoice) {
        var URL = this.URL + '/costAdd';
        return this.http.put(URL, { invoice: invoice, isUpdate: index, oldInvoice: oldInvoice }, { headers: this.headers });
    };
    InvoiceReportService.prototype.openNewPeriod = function () {
        var URL = this.URL + '/openNew';
        return this.http.put(URL, { headers: this.headers });
    };
    InvoiceReportService.prototype.deletePayment = function (id) {
        var URL = this.URL + '/paymentDelete/' + id;
        return this.http.delete(URL, { headers: this.headers });
    };
    InvoiceReportService.prototype.getAllInvoices = function (query) {
        var URL = this.URL + '/all-invoices';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceReportService.prototype.getAllInvoicesSize = function (query) {
        var URL = this.URL + '/all-invoices/size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceReportService.prototype.getAllPeriods = function (query) {
        var URL = this.URL + '/all-periods';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceReportService.ngInjectableDef = i0.defineInjectable({ factory: function InvoiceReportService_Factory() { return new InvoiceReportService(i0.inject(i1.HttpClient)); }, token: InvoiceReportService, providedIn: "root" });
    return InvoiceReportService;
}());
export { InvoiceReportService };
