import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from './shared/services/authentication.service';
import {NavigationEnd, Router} from '@angular/router';
import {AuthorizationService} from './shared/services/authorization.service';
import {SidebarDirective} from './shared/directives/sidebar.directive';
import {Employee} from '../models/employee';
import {EmployeeService} from './shared/services/employee.service';
import {MenuControllerService} from './shared/services/menu-controller.service';

declare const $: any;

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
    @ViewChild(SidebarDirective) sidebar: SidebarDirective;

    loggedIn = false;
    currentUser: Employee;

    constructor(
        private _router: Router,
        public authenticationService: AuthenticationService,
        public authorizationService: AuthorizationService,
        public employeeService: EmployeeService,
        public menuControllerService: MenuControllerService
    ) {}

    ngOnInit() {
        this.menuControllerService.sidebar = this.sidebar;
        this.menuControllerService.setMenuByAuthorization();
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd ) {
                $('body .modals').remove(); // To guarantee modals are destroyed when router navigates
                this.loggedIn = event.url.substring(1, 6) !== 'login' && event.url.substring(1, 7) !== 'forgot';

                if (this.loggedIn && !this.currentUser) {
                    this.employeeService.getCurrentUser().subscribe(
                        employee => {
                            this.currentUser = employee;
                            this.sidebar.show();
                        }
                    );
                } else if (this.loggedIn && this.currentUser) {
                    this.sidebar.show();
                } else {
                    this.sidebar.hide();
                }
            }
        });
    }

    logout() {
      this.sidebar.hide();
      this.loggedIn = false;
      this.authenticationService.logout();
      this._router.navigate(['/login']);
    }

    /*
     * Angular does not rerender when navigated to the current page
     * This is a workaround that first navigates to the '/'(doesn't render '/') then to the current page
     */
    reload(urlToNavigate: string) {
        if (this._router.url === urlToNavigate) {  // Rerender if urlToNavigate is the current url
            this._router.navigate(['/']).then( _ => this._router.navigate([urlToNavigate]));
        }
    }
}
