import { ComponentUtil } from '../../util/ComponentUtil';
import * as i0 from "@angular/core";
var UtilsService = /** @class */ (function () {
    function UtilsService() {
        this.filteredRows = [];
        this.isAscending = false;
        this.isSorted = false;
        this.lastSorted = '';
        this.sortedTable = '';
        this.sortNum = -1;
        this.insert = function insert(main_string, ins_string, pos) {
            if (typeof (pos) === 'undefined') {
                pos = 0;
            }
            if (typeof (ins_string) === 'undefined') {
                ins_string = '';
            }
            return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
        };
        this.removeCharAt = function (str, i) {
            var tmp = str.split('');
            tmp.splice(i, 1);
            return tmp.join('');
        };
        this.convertTurkishCharacters = function (str) {
            var translate_re = /[öÖüÜıİğĞçÇşŞ]/g;
            var translate = {
                'ö': 'o', 'Ö': 'O', 'ü': 'u', 'Ü': 'U', 'ı': 'i', 'İ': 'I',
                'ğ': 'g', 'Ğ': 'G', 'ç': 'c', 'Ç': 'C', 'ş': 's', 'Ş': 'S'
            };
            return (str.replace(translate_re, function (match) {
                return translate[match];
            }));
        };
    }
    UtilsService.deepCopy = function (object) {
        if (object === undefined) {
            return undefined;
        }
        return JSON.parse(JSON.stringify(object));
    };
    UtilsService.prototype.getComponentPart = function (type) {
        return ComponentUtil.MAIN_PARTS[type];
    };
    UtilsService.prototype.getComponentName = function (name) {
        return ComponentUtil.COMPONENT_NAMES[name];
    };
    UtilsService.prototype.getPersonFullName = function (person) {
        return person.firstName + ' ' + (person.middleName ? (person.middleName + ' ') : '') + person.lastName;
    };
    UtilsService.prototype.parseDate = function (dateStr) {
        if (dateStr.split('/').length === 3) {
            return new Date(dateStr.split('/')[2], dateStr.split('/')[1], dateStr.split('/')[0]).getTime();
        }
        return new Date(dateStr.split('/')[1], dateStr.split('/')[0], 1).getTime();
    };
    UtilsService.prototype.parseNumericalData = function (numStr) {
        var num = '';
        for (var i = 0; i < numStr.length; i++) {
            if (numStr[i] !== ',') {
                num += numStr[i];
            }
        }
        return parseFloat(num);
    };
    UtilsService.prototype.formatDouble = function (num, digitNumber, isTR) {
        if (digitNumber === void 0) { digitNumber = 2; }
        if (isTR === void 0) { isTR = true; }
        var decimalSeparator = isTR ? '.' : ',';
        var hundredSeparator = isTR ? ',' : '.';
        if (num == null) {
            num = 0;
        }
        if (typeof num !== 'number') {
            num = parseFloat(num);
        }
        else {
            num = Number(num);
        }
        num = num.toFixed(digitNumber).replace('.', decimalSeparator);
        num = '' + num;
        var s = num.split(decimalSeparator);
        s[0] = this.reverseString(s[0]);
        var i = 3;
        while (s[0][i]) {
            s[0] = this.insert(s[0], hundredSeparator, i);
            i += 4;
        }
        s[0] = this.reverseString(s[0]);
        if (s[0][0] === '-' && s[0][1] === hundredSeparator) {
            s[0] = this.removeCharAt(s[0], 1);
        }
        return s[0] + decimalSeparator + s[1];
    };
    UtilsService.prototype.reverseString = function (str) {
        var splitString = str.split('');
        var reverseArray = splitString.reverse();
        return reverseArray.join('');
    };
    UtilsService.prototype.trackByFn = function (index, item) {
        if (item.citizenID) {
            return item.citizenID;
        }
        return item.id ? item.id : item.projectId;
    };
    UtilsService.prototype.filterFunc = function (tableID, inputID, column) {
        // Declare variables
        var input, filter, table, tr, td;
        input = document.getElementById(inputID);
        filter = input.value.toUpperCase();
        table = document.getElementById(tableID);
        tr = table.getElementsByTagName('tr');
        // Loop through all table rows, and hide those who don't match the search query
        var index = 0;
        for (var _i = 0, tr_1 = tr; _i < tr_1.length; _i++) {
            var item = tr_1[_i];
            td = item.getElementsByTagName('td')[column];
            if (td) {
                if (td.innerText.toUpperCase().indexOf(filter) > -1) {
                    var showRow = true;
                    for (var _a = 0, _b = this.filteredRows; _a < _b.length; _a++) {
                        var tuple = _b[_a];
                        if (tuple[0] === index) {
                            if (tuple[1] !== column) {
                                showRow = false;
                            }
                            else {
                                this.removeFromArray(this.filteredRows, tuple);
                            }
                        }
                    }
                    if (showRow) {
                        item.style.display = '';
                    }
                }
                else {
                    var willBeAdded = true;
                    for (var _c = 0, _d = this.filteredRows; _c < _d.length; _c++) {
                        var tuple = _d[_c];
                        if (tuple[0] === index && tuple[1] === column) {
                            willBeAdded = false;
                            break;
                        }
                    }
                    if (willBeAdded) {
                        item.style.display = 'none';
                        this.filteredRows.push([index, column]);
                    }
                }
            }
            index++;
        }
    };
    UtilsService.prototype.removeFromArray = function (data, item) {
        var index = data.indexOf(item);
        if (index !== -1) {
            data.splice(index, 1);
        }
    };
    UtilsService.prototype.sortColumn = function (tableID, column, isNumeric) {
        var sortedData = [];
        // Declare variables
        this.sortNum = column;
        this.sortedTable = tableID;
        var table, tr;
        table = document.getElementById(tableID);
        tr = table.getElementsByTagName('tr');
        for (var k = 2; k < tr.length; k++) {
            sortedData.push(tr[k]);
        }
        var arrowStatus = {
            LastClickedCol: column,
            Status: {
                Up: false,
                Down: false,
                Flat: true
            }
        };
        if (this.lastSorted !== tableID + column) {
            this.isAscending = false;
            this.isSorted = false;
        }
        var needSwap = true;
        while (needSwap) {
            needSwap = false;
            for (var j = 0; j < sortedData.length - 1; j++) {
                var cmp1 = sortedData[j].getElementsByTagName('td')[column].innerText.toLowerCase();
                var cmp2 = sortedData[j + 1].getElementsByTagName('td')[column].innerText.toLowerCase();
                if (this.executeDateRegex(cmp1, /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/)) {
                    cmp1 = this.parseDate(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/.exec(cmp1)[0]);
                    cmp2 = this.parseDate(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}/.exec(cmp2)[0]);
                }
                else if (this.executeDateRegex(cmp1, /^(((0)[0-9])|((1)[0-2]))(\/)\d{4}/)) {
                    cmp1 = this.parseDate(/^(((0)[0-9])|((1)[0-2]))(\/)\d{4}/.exec(cmp1)[0]);
                    cmp2 = this.parseDate(/^(((0)[0-9])|((1)[0-2]))(\/)\d{4}/.exec(cmp2)[0]);
                }
                else {
                    if (isNumeric) {
                        cmp1 = this.executeRegex(cmp1, /(\d{1,3},(\d{3},)*\d{3}(\.\d{1,4})?|\d{1,3}(\.\d{1,4}))/);
                        cmp2 = this.executeRegex(cmp2, /(\d{1,3},(\d{3},)*\d{3}(\.\d{1,4})?|\d{1,3}(\.\d{1,4}))/);
                    }
                }
                if (this.isAscending && cmp1 > cmp2) {
                    _a = [sortedData[j + 1], sortedData[j]], sortedData[j] = _a[0], sortedData[j + 1] = _a[1];
                    needSwap = true;
                    arrowStatus.Status.Flat = false;
                    arrowStatus.Status.Down = false;
                    arrowStatus.Status.Up = true;
                }
                else if (!this.isAscending && cmp1 < cmp2) {
                    _b = [sortedData[j + 1], sortedData[j]], sortedData[j] = _b[0], sortedData[j + 1] = _b[1];
                    needSwap = true;
                    arrowStatus.Status.Flat = false;
                    arrowStatus.Status.Down = true;
                    arrowStatus.Status.Up = false;
                }
            }
        }
        var parent = tr[2].parentNode;
        for (var k = 2; k < tr.length; k++) {
            parent = tr[k].parentNode;
            parent.appendChild(sortedData[k - 2]);
        }
        this.isAscending = !this.isAscending;
        this.isSorted = true;
        this.lastSorted = tableID + column;
        return arrowStatus;
        var _a, _b;
    };
    UtilsService.prototype.executeRegex = function (str, regex) {
        var m;
        if ((m = regex.exec(str)) !== null) {
            return this.parseNumericalData(m[0]);
        }
        return 0;
    };
    UtilsService.prototype.executeDateRegex = function (str, regex) {
        return regex.exec(str) !== null;
    };
    UtilsService.prototype.comparebyDate = function (obj1, obj2, ascending) {
        var comp1 = obj1;
        var comp2 = obj2;
        if (obj1.date) {
            comp1 = new Date(obj1.date).getTime();
            comp2 = new Date(obj2.date).getTime();
        }
        else if (obj1.startDate) {
            comp1 = new Date(obj1.startDate).getTime();
            comp2 = new Date(obj2.startDate).getTime();
        }
        else if (obj1.applicationDate) {
            comp1 = new Date(obj1.applicationDate).getTime();
            comp2 = new Date(obj2.applicationDate).getTime();
        }
        if (comp1 < comp2) {
            return ascending ? -1 : 1;
        }
        else if (comp1 > comp2) {
            return ascending ? 1 : -1;
        }
        return 0;
    };
    UtilsService.prototype.sortByAscendingDate = function (obj1, obj2) {
        return this.comparebyDate(obj1, obj2, true);
    };
    UtilsService.prototype.sortByDescendingDate = function (obj1, obj2) {
        return this.comparebyDate(obj1, obj2, false);
    };
    UtilsService.prototype.groupBy = function (array, f) {
        var groups = {};
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        });
    };
    UtilsService.prototype.customizeLabelPie = function (arg) {
        var reverseString = function (str) {
            var splitString = str.split('');
            var reverseArray = splitString.reverse();
            return reverseArray.join('');
        };
        var insert = function (main_string, ins_string, pos) {
            if (typeof (pos) === 'undefined') {
                pos = 0;
            }
            if (typeof (ins_string) === 'undefined') {
                ins_string = '';
            }
            return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
        };
        var num = arg.value.toFixed(2);
        num = '' + num;
        var s = num.split('.');
        s[0] = reverseString(s[0]);
        var i = 3;
        while (s[0][i]) {
            s[0] = insert(s[0], ',', i);
            i += 4;
        }
        s[0] = reverseString(s[0]);
        s = s[0] + '.' + s[1];
        return s + ' (' + (arg.percent * 100).toFixed(2) + '%)';
    };
    UtilsService.prototype.configureDropdown = function (dropdownID) {
        $(dropdownID).dropdown({
            onNoResults: function () {
                $('#noResult').show();
            },
            onChange: function () {
                $('#noResult').hide();
            }
        });
        $('input').keypress(function () {
            $('#noResult').hide();
        });
    };
    UtilsService.prototype.testFloatText = function (text) {
        var re = /^(-?[0-9]+([.,][0-9]+)$)|^(-?[0-9]+)$/;
        var array = text.match(re);
        if (!array) {
            return false;
        }
        var match = array[0];
        return !isNaN(this.parseFloatText(match));
    };
    UtilsService.prototype.getISODate = function (date) {
        // start converting UTC string to ISO string.
        // the following lines chop the GMT+3 part so that
        // ISO times are not 3 hrs earlier than they are supposed to be.
        var timeZoneDifference = (date.getTimezoneOffset() / 60) * -1;
        date.setTime(date.getTime() + (timeZoneDifference * 60) * 60 * 1000);
        var searchISODate = date.toISOString();
        return searchISODate;
    };
    UtilsService.prototype.parseFloatText = function (text) {
        if (text.search(',') != -1) {
            // , found
            var re = /,/;
            var str = text.replace(re, ".");
            return parseFloat(str);
        }
        return parseFloat(text);
    };
    UtilsService.prototype.monthDiff = function (d1, d2) {
        var little = d1 < d2 ? d1 : d2;
        var big = d1 > d2 ? d1 : d2;
        var months;
        months = (big.getFullYear() - little.getFullYear()) * 12;
        months -= little.getMonth();
        months += big.getMonth();
        return months <= 0 ? 0 : months;
    };
    UtilsService.NOT_NULL_TOKEN = '*';
    UtilsService.ngInjectableDef = i0.defineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
export { UtilsService };
