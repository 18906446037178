import { Paginator } from './paginator';
var PaginatorBuilder = /** @class */ (function () {
    function PaginatorBuilder() {
        this._pageSizeOptions = [5, 10, 15];
        this._selectedPage = 1; // Initially select the first page
        this._totalSize = 1;
        this._refreshFunction = function () { return undefined; }; // Should be called when elements are needed to be changed.
        this._resizeFunction = function () { return undefined; }; // Should be called when <totalSize> is changed.
    }
    PaginatorBuilder.prototype.withPageSizeOptions = function (options) {
        this._pageSizeOptions = options;
        return this;
    };
    PaginatorBuilder.prototype.withSelectedPageSize = function (selectedPageSize) {
        this._selectedPageSize = selectedPageSize;
        return this;
    };
    PaginatorBuilder.prototype.withSelectedPage = function (selectedPage) {
        this._selectedPage = selectedPage;
        return this;
    };
    PaginatorBuilder.prototype.withRefreshFunction = function (callback) {
        this._refreshFunction = callback;
        return this;
    };
    PaginatorBuilder.prototype.withResizeFunction = function (callback) {
        this._resizeFunction = callback;
        return this;
    };
    PaginatorBuilder.prototype.build = function () {
        if (!this.selectedPageSize) {
            this._selectedPageSize = this._pageSizeOptions[0];
        }
        return new Paginator(this);
    };
    Object.defineProperty(PaginatorBuilder.prototype, "pageSizeOptions", {
        get: function () {
            return this._pageSizeOptions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorBuilder.prototype, "selectedPageSize", {
        get: function () {
            return this._selectedPageSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorBuilder.prototype, "selectedPage", {
        get: function () {
            return this._selectedPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorBuilder.prototype, "totalSize", {
        get: function () {
            return this._totalSize;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorBuilder.prototype, "refreshFunction", {
        get: function () {
            return this._refreshFunction;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PaginatorBuilder.prototype, "resizeFunction", {
        get: function () {
            return this._resizeFunction;
        },
        enumerable: true,
        configurable: true
    });
    return PaginatorBuilder;
}());
export { PaginatorBuilder };
