import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TravelService = /** @class */ (function () {
    function TravelService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/travel';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    TravelService.prototype.getRequestTravels = function (query) {
        var URL = this.URL + '/request';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    TravelService.prototype.getRequestTravelsSize = function (query) {
        var URL = this.URL + '/request/size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    TravelService.prototype.getPeriodicReportTravels = function () {
        var URL = this.URL + '/periodic-report';
        return this.http.get(URL, { headers: this.headers });
    };
    TravelService.prototype.getCostStatementTravels = function (query) {
        var URL = this.URL + '/cost';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    TravelService.prototype.getHomeTravels = function (query) {
        var URL = this.URL + '/home';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    TravelService.prototype.getTravelWithID = function (id) {
        var URL = this.URL + '/' + id;
        return this.http.get(URL, { headers: this.headers });
    };
    TravelService.prototype.addTravel = function (travel) {
        var URL = this.URL + '/add';
        return this.http.post(URL, travel, { headers: this.headers });
    };
    TravelService.prototype.updateCostTable = function (item, isUpdate, value, kdv, kdvExcluded, id) {
        var URL = this.URL + '/costTableUpdate/' + id;
        return this.http.put(URL, { item: item, isUpdate: isUpdate, value: value, kdv: kdv, kdvExcluded: kdvExcluded }, { headers: this.headers });
    };
    TravelService.prototype.updateTravel = function (travel) {
        var URL = this.URL + '/' + travel.id;
        return this.http.put(URL, travel, { headers: this.headers });
    };
    TravelService.prototype.deleteTravel = function (id) {
        var URL = this.URL + '/' + id;
        return this.http.delete(URL, { headers: this.headers });
    };
    TravelService.prototype.getTravelsWithQuery = function (query) {
        return this.http.get(this.URL, { headers: this.headers, params: query });
    };
    TravelService.prototype.makeReport = function (travel) {
        var URL = this.URL + ("/" + travel.id + "/export");
        return this.http.get(URL, { headers: this.headers, responseType: 'blob' });
    };
    TravelService.ngInjectableDef = i0.defineInjectable({ factory: function TravelService_Factory() { return new TravelService(i0.inject(i1.HttpClient)); }, token: TravelService, providedIn: "root" });
    return TravelService;
}());
export { TravelService };
