import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './home/home.component';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {AuthenticationService} from './shared/services/authentication.service';
import {AuthGuard} from './auth/auth.guard';
import {ErrorInterceptor} from './shared/error.interceptor';
import {JwtInterceptor} from './shared/jwt.interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthorizationService} from './shared/services/authorization.service';
import {CanAccessDirective} from './shared/directives/authorization.directive';
import {DxChartModule, DxPieChartModule} from 'devextreme-angular';
import {ProfilePageComponent} from './profile-page/profile-page.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {SharedModule} from './shared/shared/shared.module';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepickerModule} from '@angular/material';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {dateFormat} from '../models/dateFormat';
import {SatDatepickerModule, SatNativeDateModule} from 'saturn-datepicker';
import {HotTableModule} from 'ng2-handsontable';
import {LeaveExcuseManagementModule} from './leave-excuse-management/leave-excuse-management.module';
import {CustomDateAdapter} from './shared/custom-date-adapter';
import {PipesModule} from './shared/pipes/pipes.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        CanAccessDirective,
        ProfilePageComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent
    ],
    imports: [
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        AppRoutingModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        DxChartModule,
        DxPieChartModule,
        MatDatepickerModule,
        SatDatepickerModule,
        SatNativeDateModule,
        HotTableModule,
        LeaveExcuseManagementModule,
        PipesModule
    ],
    providers: [
        AuthGuard,
        AuthenticationService,
        AuthorizationService,
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        {provide: MAT_DATE_FORMATS, useValue: dateFormat},
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

