import {Person} from './person';
import {BankAccount} from './bankAccount';
import {VacationInformation} from './vacationInformation';
import {Course} from './course';
import {WorkDay} from './workDay';
import {EmployeeRates} from './employeeRates';

export class Employee {
    _id: string;
    person: Person;
    password: string;
    userType: Employee.UserType;
    socialSecurityID: string;
    isRetired: boolean;
    isFullTime: boolean;
    profession: string;
    dateOfEmployment_formal: Date;
    dateOfUnemployment_formal: Date;
    dateOfEmployment_informal: Date;
    citizenID: string;
    birthDate: Date;
    bachelorGraduationDate: Date;
    MScGraduationDate: Date;
    PhDGraduationDate: Date;
    bankAccounts: BankAccount[];
    CV: Buffer;
    SSOEmploymentDocument: Buffer;
    SSOUnemploymentDocument: Buffer;
    contracts: Buffer[];
    vacationInformation: VacationInformation;
    courses: Course[];
    workDays: WorkDay[];
    employeeRates: EmployeeRates;

    constructor() {
        this.person = new Person();
        this.userType = Employee.UserType.EMPLOYEE;
        this.isRetired = false;
        this.isFullTime = true;
        this.dateOfEmployment_formal = new Date();
        this.dateOfEmployment_informal = new Date();
        this.bankAccounts = [];
        this.contracts = [];
        this.vacationInformation = new VacationInformation();
        this.courses = [];
        this.workDays = [];
    }
}

export namespace Employee {
    export enum UserType {
        ADMIN = 'admin',
        ACCOUNTANT = 'accountant',
        EMPLOYEE = 'employee'
    }
}
