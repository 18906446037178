import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FonService = /** @class */ (function () {
    function FonService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/fon';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    FonService.prototype.getFon = function () {
        return this.http.get(this.URL, { headers: this.headers });
    };
    FonService.prototype.addFon = function (fon) {
        var URL = this.URL + '/add';
        return this.http.post(URL, fon, { headers: this.headers });
    };
    FonService.prototype.deleteTS = function (fonID, accTSID, query) {
        var URL = this.URL + '/removeTS/' + fonID + '/' + accTSID;
        return this.http.delete(URL, { headers: this.headers, params: query });
    };
    FonService.prototype.addTimeSeries = function (balance, fonID, query) {
        var URL = this.URL + '/addTS/' + fonID;
        return this.http.post(URL, balance, { headers: this.headers, params: query });
    };
    FonService.prototype.updateTS = function (balance, fonID, query) {
        var URL = this.URL + '/updateTS/' + fonID;
        return this.http.put(URL, balance, { headers: this.headers, params: query });
    };
    FonService.prototype.getTSSize = function (query) {
        var URL = this.URL + '/accTS-size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    FonService.prototype.getTS = function (query) {
        var URL = this.URL + '/accTS';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    FonService.prototype.getTotalBalance = function (fonID, query) {
        var URL = this.URL + '/totalBalance';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    FonService.prototype.getTotalBalanceExport = function (fonID) {
        var URL = this.URL + '/totalBalanceExport';
        return this.http.get(URL, { headers: this.headers, responseType: 'arraybuffer' });
    };
    FonService.ngInjectableDef = i0.defineInjectable({ factory: function FonService_Factory() { return new FonService(i0.inject(i1.HttpClient)); }, token: FonService, providedIn: "root" });
    return FonService;
}());
export { FonService };
