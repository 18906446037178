import {NgModule} from '@angular/core';
import {ModalDirective} from '../directives/modal.directive';
import {SidebarDirective} from '../directives/sidebar.directive';
import {CommonModule} from '@angular/common';
import {MatDatepickerModule, MatInputModule} from '@angular/material';
import {BankAccountBalanceComponent} from '../../bank-account-balance/bank-account-balance.component';
import {CostStatementInfoModalComponent} from '../../cost-statement-info-modal/cost-statement-info-modal.component';
import {CustomCostStatementInfoModalComponent} from '../../custom-cost-statement-info-modal/custom-cost-statement-info-modal.component';
import {FormsModule} from '@angular/forms';
import {CurrentExchangeRatesComponent} from '../../current-exchange-rates/current-exchange-rates.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        MatInputModule,
        MatDatepickerModule
    ],
    declarations: [
        ModalDirective,
        SidebarDirective,
        CostStatementInfoModalComponent,
        CustomCostStatementInfoModalComponent,
        BankAccountBalanceComponent,
        CurrentExchangeRatesComponent
    ],
    exports: [
        ModalDirective,
        SidebarDirective,
        CostStatementInfoModalComponent,
        CustomCostStatementInfoModalComponent,
        BankAccountBalanceComponent,
        CurrentExchangeRatesComponent
    ]
})
export class SharedModule {
}
