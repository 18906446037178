
export class WorkDay {
    hours: number[];
    day: number;

    constructor(hours: number[], day: number) {
        this.hours = hours;
        this.day = day;
    }
}
