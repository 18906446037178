import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Travel} from '../../../models/travel';
import {environment} from '../../../environments/environment';
import {TravelCostLineItem} from '../../../models/travelCostLineItem';

@Injectable({
    providedIn: 'root'
})
export class TravelService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/travel';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getRequestTravels(query: any) {
        const URL = this.URL + '/request';
        return this.http.get<Travel[]>(URL, {headers: this.headers, params: query});
    }

    public getRequestTravelsSize(query: any) {
        const URL = this.URL + '/request/size';
        return this.http.get<{size: number}>(URL, {headers: this.headers, params: query});
    }

    public getPeriodicReportTravels() {
        const URL = this.URL + '/periodic-report';
        return this.http.get<Travel[]>(URL, {headers: this.headers});
    }

    public getCostStatementTravels(query: any) {
        const URL = this.URL + '/cost';
        return this.http.get<Travel[]>(URL, {headers: this.headers, params: query});
    }

    public getHomeTravels(query: any) {
        const URL = this.URL + '/home';
        return this.http.get<Travel[]>(URL, {headers: this.headers, params: query});
    }

    public getTravelWithID(id: number) {
        const URL = this.URL + '/' + id;
        return this.http.get<Travel>(URL, {headers: this.headers});
    }

    public addTravel(travel: Travel) {
        const URL = this.URL + '/add';
        return this.http.post(URL, travel, {headers: this.headers});
    }

    public updateCostTable(item: TravelCostLineItem, isUpdate: boolean, value: {value: number, currency: string}, kdv: number, kdvExcluded: number, id: number) {
        const URL = this.URL + '/costTableUpdate/' + id;
        return this.http.put(URL, {item: item, isUpdate: isUpdate, value: value, kdv: kdv, kdvExcluded: kdvExcluded}, {headers: this.headers});
    }

    public updateTravel(travel: Travel) {
        const URL = this.URL + '/' + travel.id;
        return this.http.put(URL, travel, {headers: this.headers});
    }

    public deleteTravel(id: number) {
        const URL = this.URL + '/' + id;
        return this.http.delete(URL, {headers: this.headers});
    }

    public getTravelsWithQuery(query: any) {
        return this.http.get<Travel[]>(this.URL, {headers: this.headers, params: query});
    }

    public makeReport(travel: Travel) {
        const URL = this.URL + `/${travel.id}/export`;
        return this.http.get(URL, {headers: this.headers, responseType: 'blob'});
    }

}




