import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InvoiceService = /** @class */ (function () {
    function InvoiceService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/invoice';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    InvoiceService.prototype.getInvoicesSize = function (query) {
        var URL = this.URL + '/size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceService.prototype.getPeriodicReportInvoices = function () {
        var URL = this.URL + '/periodic-report';
        return this.http.get(URL, { headers: this.headers });
    };
    InvoiceService.prototype.getInvoices = function (query) {
        var URL = this.URL + '/invoice';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceService.prototype.getCostStatementInvoices = function (query) {
        var URL = this.URL + '/cost';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceService.prototype.getHomeInvoices = function (query) {
        var URL = this.URL + '/home';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceService.prototype.getInvoiceWithID = function (id, query) {
        if (query === void 0) { query = {}; }
        var URL = this.URL + '/' + id;
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceService.prototype.getDocumentWithInvoiceID = function (id) {
        var URL = this.URL + '/document/' + id;
        return this.http.get(URL, { headers: this.headers });
    };
    InvoiceService.prototype.addInvoice = function (invoice) {
        var URL = this.URL + '/add';
        return this.http.post(URL, invoice, { headers: this.headers });
    };
    InvoiceService.prototype.updateInvoice = function (invoice) {
        var URL = this.URL + '/' + invoice.id;
        return this.http.put(URL, invoice, { headers: this.headers });
    };
    InvoiceService.prototype.deleteInvoice = function (id) {
        var URL = this.URL + '/' + id;
        return this.http.delete(URL, { headers: this.headers });
    };
    InvoiceService.prototype.getIncomeInvoicesWithQuery = function (query) {
        var URL = this.URL + '/income';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceService.prototype.getOutcomeInvoicesWithQuery = function (query) {
        var URL = this.URL + '/outcome';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceService.prototype.pay = function (invoice, talimatFormatSelector) {
        var URL = this.URL + '/pay';
        var query = { 'form': talimatFormatSelector };
        return this.http.post(URL, invoice, { headers: this.headers, params: query, responseType: 'blob' });
    };
    InvoiceService.prototype.payAllInHouses = function (period) {
        var URL = this.URL + '/payAllInHouse';
        return this.http.post(URL, period, { headers: this.headers, responseType: 'blob' });
    };
    InvoiceService.prototype.prepareReport = function (talimat) {
        var URL = this.URL + '/prepareReport';
        return this.http.post(URL, talimat, { headers: this.headers, responseType: 'blob' });
    };
    InvoiceService.prototype.getLatestInvoice = function (query) {
        var URL = this.URL + '/latest';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    InvoiceService.ngInjectableDef = i0.defineInjectable({ factory: function InvoiceService_Factory() { return new InvoiceService(i0.inject(i1.HttpClient)); }, token: InvoiceService, providedIn: "root" });
    return InvoiceService;
}());
export { InvoiceService };
