import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ExchangeRateService} from '../shared/services/exchange-rate.service';
import * as moment from 'moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UtilsService} from '../shared/services/utils.service';

@Component({
    selector: 'app-current-exchange-rates',
    templateUrl: './current-exchange-rates.component.html',
    styleUrls: ['./current-exchange-rates.component.css'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({opacity: 1})),
            transition(':enter', [
                style({opacity: 0, background: '#fbfbfb'}),
                animate(750)
            ])
        ])
    ]
})

export class CurrentExchangeRatesComponent implements OnInit, OnDestroy {
    _destroyed$ = new Subject();
    currentExchangeRatesGUI: CurrentExchangeRatesGUI[];
    accountToShow: CurrentExchangeRatesGUI;
    exchangeRates: {EURO: number, USD: number} = {EURO: 0, USD: 0};
    loading = true;

    constructor(private exchangeRateService: ExchangeRateService, private utilsService: UtilsService) {
    }

    ngOnInit() {
        const promises = [this.getExchangeRates()];
        Promise.all(promises).then(() => {
            this.initGUI();
            this.accountToShow = this.currentExchangeRatesGUI[0];
            this.loading = false;
        });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
    }

    getExchangeRates(): Promise<any> {
        const date = moment(new Date()).format('YYYY-MM-DD');
        const promises = ['EURO', 'USD'].map(currency => {
            return this.exchangeRateService.getRate(date, currency)
                .pipe(takeUntil(this._destroyed$))
                .toPromise()
                .then(rate => {
                    this.exchangeRates[currency] = rate.exchangeRate;
                });
        });
        return Promise.all(promises);
    }

    initGUI() {
        this.currentExchangeRatesGUI = [];
        this.currentExchangeRatesGUI.push(new CurrentExchangeRatesGUI('EURO', this.exchangeRates.EURO));
        this.currentExchangeRatesGUI.push(new CurrentExchangeRatesGUI('USD', this.exchangeRates.USD));
    }

}

export class CurrentExchangeRatesGUI {
    currency: string;
    rate: number;

    constructor(currency: string, rate: number) {
        this.currency = currency;
        this.rate = rate;
    }

}
