import { OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { EmployeeService } from '../shared/services/employee.service';
import { AlertUtil } from '../util/AlertUtil';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(formBuilder, route, router, authenticationService, employeeService) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.employeeService = employeeService;
        this.submitted = false;
        this.wrongEmail = false;
        this._destroyed$ = new Subject();
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.forgotPasswordForm = this.formBuilder.group({
            workEmail: ['', Validators.required],
        });
        // reset login status
        this.authenticationService.logout();
        // get return url from route parameters or default to '/'
    };
    Object.defineProperty(ForgotPasswordComponent.prototype, "f", {
        // convenience getter for easy access to form fields
        get: function () {
            return this.forgotPasswordForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    ForgotPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        this.submitted = true;
        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            return;
        }
        this.employeeService.forgotPassword(this.f.workEmail).pipe(first()).subscribe(function (response) {
            if (response.success) {
                AlertUtil.showInfo(_this.f.workEmail.value + ' adresine şifre yenileme maili gönderildi', 'Mail Gönderildi');
                _this.router.navigate(['/login']);
            }
        });
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
