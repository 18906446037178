import {TimeSeries} from './timeSeries';

export class BankAccount {
    _id: string;
    iban: string;
    currency: BankAccount.Currency;
    type: string;
    isInterest: boolean;
    bank: string;
    branch: string;
    accountNo: string;
    accountBalanceTimeSeries: TimeSeries[];
    interestDate: Date;
    active?: boolean;
    bankRefCode: string;

    constructor() {
        this.currency = BankAccount.Currency.TL;
        this.type = 'Genel';
        this.isInterest = false;
        this.accountBalanceTimeSeries = [];
    }
}

export namespace BankAccount {
    export enum Currency {
        TL = 'TL',
        EURO = 'EURO',
        USD = 'USD'
    }
}
