import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Employee} from '../../../models/employee';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Person} from '../../../models/person';
import {VacationInformation} from '../../../models/vacationInformation';
import { TimeSeries } from 'src/models/timeSeries';
import { BankAccount } from 'src/models/bankAccount';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/employee';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getEmployeesSize(query: any) {
        const URL = this.URL + '/size';
        return this.http.get<{ size: number }>(URL, {headers: this.headers, params: query});
    }

    public getActiveEmployees() {
        const URL = this.URL + '/active';
        return this.http.get<Employee[]>(URL, {headers: this.headers});
    }

    public getEmployeesWithBankAccount() {
        const URL = this.URL + '/bank';
        return this.http.get<Employee[]>(URL, {headers: this.headers});
    }

    public getEmployeesSummary() {
        const URL = this.URL + '/summary';
        return this.http.get<Employee[]>(URL, {headers: this.headers});
    }

    public getStudentEmployees() {
        const URL = this.URL + '/student';
        return this.http.get<Employee[]>(URL, {headers: this.headers});
    }

    public getManagementEmployees(query: any) {
        const URL = this.URL + '/management';
        return this.http.get<Employee[]>(URL, {headers: this.headers, params: query});
    }

    public getAuthEmployees(query: any) {
        const URL = this.URL + '/auth';
        return this.http.get<Employee[]>(URL, {headers: this.headers, params: query});
    }

    public getExcuseEmployees(query: any) {
        const URL = this.URL + '/excuse';
        return this.http.get<Employee[]>(URL, {headers: this.headers, params: query});
    }

    public getSalaryEmployees() {
        const URL = this.URL + '/salary';
        return this.http.get<Employee[]>(URL, {headers: this.headers});
    }

    public getSalaryEmployeesAtMonth(query: any) {
        const URL = this.URL + '/salary-at-month';
        return this.http.get<Employee[]>(URL, {headers: this.headers, params: query});
    }

    public getEstimationEmployees() {
        const URL = this.serverApi + this.serverPart + '/estimation';
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get<Employee[]>(URL, {headers: headers});
    }

    public getEmployeeSummaryWithID(citizenID: string) {
        const URL = this.URL + '/summary/' + citizenID;
        return this.http.get<Employee>(URL, {headers: this.headers});
    }

    public getExcusesOfEmployee(employeeObjId: string) {
        const URL = this.URL + '/excuse/' + employeeObjId;
        return this.http.get<{
            _id: String, person: Person, citizenID: String, vacationInformation: VacationInformation
        }>(URL, {headers: this.headers});
    }

    public getEmployeeWithID(citizenID: string) {
        const URL = this.URL + '/' + citizenID;
        return this.http.get<Employee>(URL, {headers: this.headers});
    }

    public getCurrentUserSummary() {
        return this.getEmployeeSummaryWithID(localStorage.getItem('currentUserID'));
    }

    public getCurrentUser() {
        return this.getEmployeeWithID(localStorage.getItem('currentUserID'));
    }

    public deleteTimeSeries(citizenID: string, ts: TimeSeries, query?: any) {
        const tsID  = ts._id ;
        const URL = this.URL + '/' + citizenID + '/deleteTS/' + tsID;
        return this.http.delete(URL, {headers: this.headers, params: query});
    }

    public updateTimeSeries(citizenID: string, ts: TimeSeries, query?: any) {
        const tsID  = ts._id ;
        const URL = this.URL + '/' + citizenID + '/updateTS/' + tsID;
        return this.http.put(URL, ts, {headers: this.headers, params: query});
    }

    public addTimeSeries(citizenID: string, ts: TimeSeries, query?: any) {
        const URL = this.URL + '/' + citizenID + '/addTS' ;
        return this.http.post(URL, ts, {headers: this.headers, params: query});
    }

    forgotPassword(workEmail: any) {
        const URL = this.URL + '/forgot';
        return this.http.post<any>(URL, {workEmail: workEmail.value})
            .pipe(map(response => {
                if (response && response.token) {
                }
                return response;
            }));
    }

    public addEmployee(employee: Employee) {
        const URL = this.URL + '/add';
        return this.http.post(URL, employee, {headers: this.headers});
    }

    public updateEmployee(employee: Employee, citizenID: string) {
        const URL = this.URL + '/' + citizenID;
        return this.http.put(URL, employee, {headers: this.headers});
    }

    public updateBankAccount(bankAccount: BankAccount, citizenID: string) {
        const URL = this.URL + '/' + citizenID + '/bank-accounts';
        return this.http.put(URL, bankAccount, {headers: this.headers});
    }

    public addBankAccount(bankAccount: BankAccount, citizenID: string) {
        const URL = this.URL + '/' + citizenID + '/bank-accounts';
        return this.http.post(URL, bankAccount, {headers: this.headers});
    }

    public deleteBankAccount(iban: string, citizenID: string) {
        const URL = this.URL + '/' + citizenID + '/bank-accounts/' + iban;
        return this.http.delete(URL, {headers: this.headers});
    }

    public deleteEmployee(citizenID: string) {
        const URL = this.URL + '/' + citizenID;
        return this.http.delete(URL, {headers: this.headers});
    }

    public getBankAccounts(citizenID: string, query?: any) {
        const URL = this.URL + '/' + citizenID + '/bank-accounts';
        return this.http.get<BankAccount[]>(URL, {headers: this.headers, params: query});
    }

    public getBankAccountsSize(citizenID: string) {
        const URL = this.URL + '/' + citizenID + '/bank-accounts/size';
        return this.http.get(URL, {headers: this.headers});
    }

    public getAdmins() {
        const URL = this.URL + '/admin';
        return this.http.get<Employee[]>(URL, {headers: this.headers});
    }

    public getTimeSeriesSizeByAccID(citizenID: string, accountID: string, query?: any) {
        const URL = this.URL + '/' + citizenID + '/bank-accounts/timeSeries/' + accountID + '/size';
        return this.http.get<{size: number}>(URL, {headers: this.headers, params: query});
    }

    public getTimeSeriesByAccID(citizenID: string, accountID: string, query?: any) {
        const URL = this.URL + '/' + citizenID + '/bank-accounts/timeSeries/' + accountID;
        return this.http.get<TimeSeries[]>(URL, {headers: this.headers, params: query});
    }

    public getPersonnelExcel(exportRetired: boolean, exportOldWorkers: boolean, isActive: boolean, query?: any) {
        const URL = this.URL + '/personnel-list/export';
        query.isActive = isActive; // get either current or old employee excel list
        query.exportRetired = exportRetired; // decide if we want to export retired ppl
        query.exportOldWorkers = exportOldWorkers;
        return this.http.get(URL, {headers: this.headers, params: query, responseType: 'blob'});
    }

    public getEmployeesInProject(projectID: string, startDate?: string, endDate?: string) {
        const URL = this.URL + '/in-project/' + projectID;
        if (startDate && endDate) {
            const params: { endDate: string; startDate: string } = {'startDate': startDate, 'endDate': endDate};
            return this.http.get<Employee[]>(URL, {headers: this.headers, params: params});
        }
        return this.http.get<Employee[]>(URL, {headers: this.headers});
    }
}
