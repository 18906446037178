import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrganizationService} from '../shared/services/organization.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BankAccount} from '../../models/bankAccount';
import {ExchangeRateService} from '../shared/services/exchange-rate.service';
import * as moment from 'moment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UtilsService} from '../shared/services/utils.service';
import {environment} from '../../environments/environment';
import { TimeSeries } from 'src/models/timeSeries';
import { Amount } from 'src/models/amount';
import {AlertUtil} from '../util/AlertUtil';

@Component({
    selector: 'app-bank-account-balance',
    templateUrl: './bank-account-balance.component.html',
    styleUrls: ['./bank-account-balance.component.css'],
    animations: [
        trigger('simpleFadeAnimation', [
            state('in', style({opacity: 1})),
            transition(':enter', [
                style({opacity: 0, background: '#fbfbfb'}),
                animate(750)
            ])
        ])
    ]
})
export class BankAccountBalanceComponent implements OnInit, OnDestroy {
    _destroyed$ = new Subject();
    VALUE_CHANGE_TIME = 4000; // in ms
    TOTAL_CURRENCIES_TO_SHOW = ['EURO', 'TL']; // Which currencies should be shown in the total column
    bankAccounts: BankAccount[];
    // Holds total amount for EURO, USD and TL bank accounts in 3 different objects.
    bankAccountBalancesGUI: BankAccountBalanceGUI[];
    // Holds absolute total amount, but in 3 different currencies.
    // For example: [6000 TL, 1000 EURO, 1200 USD]. Note that, their value are the same and determined by today's exchange rates.
    bankAccountTotalsGUI: BankAccountBalanceGUI[];
    accountToShow: BankAccountBalanceGUI;

    // Holds exchange rates as triplets (from, to, rate). Example object: ['EURO', 'TL', 6.512]
    exchangeRates: [string, string, number][] = [];

    totalBalanceIndex = 0;
    maxDate: Date; // Max date of bank-account timesheet entry.
    loading = true;

    constructor(private organizationService: OrganizationService, private exchangeRateService: ExchangeRateService,
                private utilsService: UtilsService) {
    }

    ngOnInit() {
        console.log("ASdsad");
        Promise
            .all([this.getBankAccounts(), this.getExchangeRates()])
            .then(_ => {
                const promises = [];
                for (const i in this.bankAccounts) {
                    this.bankAccounts[i].accountBalanceTimeSeries = [];
                    promises.push(this.getLastTimeSeriesDataByAccID(this.bankAccounts[i]));
                }
                Promise.all(promises).then(_=>{
                    this.initGUI();
                    this.accountToShow = this.bankAccountTotalsGUI[0];
                    this.loading = false;
                    setInterval(() => {
                        this.totalBalanceIndex++;
                        this.accountToShow = this.bankAccountTotalsGUI[this.totalBalanceIndex % this.bankAccountTotalsGUI.length];
                    }, this.VALUE_CHANGE_TIME);
                });
            });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
    }

    public refresh() {
        this.getBankAccounts().then(_ => {
            this.initGUI();
            this.accountToShow = this.bankAccountTotalsGUI[this.totalBalanceIndex % this.bankAccountTotalsGUI.length];
        });
    }

    /**
     * uses TL as base currency and makes conversion accordingly.
     */
    getExchangeRates(): Promise<any> {
        const date = moment(new Date()).format('YYYY-MM-DD');
        const promises = ['EURO', 'USD'].map(currency => {
            return this.exchangeRateService.getRate(date, currency)
                .pipe(takeUntil(this._destroyed$))
                .toPromise()
                .then(rate => {
                    const exchangeRate = rate.exchangeRate;
                    this.exchangeRates.push([currency, 'TL', exchangeRate]);
                    this.exchangeRates.push(['TL', currency, 1 / exchangeRate]);
                });
        });
        // Now, we know how to convert TL -> EURO, EURO -> TL, TL -> USD, USD -> TL
        // Also add, conversions for EURO -> USD and USD -> EURO
        return Promise.all(promises).then(_ => {
            const euroToTL = this.getExchangeRate('EURO', 'TL');
            const usdToTL = this.getExchangeRate('USD', 'TL');
            this.exchangeRates.push(['EURO', 'USD', euroToTL / usdToTL]);
            this.exchangeRates.push(['USD', 'EURO', usdToTL / euroToTL]);
        });

    }

    /**
     * @example: from: EURO, to: TL returns 6, from: EURO, to: EURO returns 1
     * @return exchange rate.
     */
    getExchangeRate(from: string, to: string) {
        const exchangeRate = this.exchangeRates.find(elem => {
            return elem[0] === from && elem[1] === to;
        });
        return exchangeRate ? exchangeRate[2] : 1; // return 1 as default for exchange rate.
    }

    getBankAccounts(): Promise<any> {
        const query = {preventPagination: true, 'bankAccounts.active': true};
        return this.organizationService
            .getBankAccounts(environment.SRDCOrganizationID, query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(bankAccounts => {
                this.bankAccounts = bankAccounts;

            });
    }

    getLastTimeSeriesDataByAccID(account: BankAccount) {
        const query = {'lastItem': true};
        return this.organizationService.getAvailableBalance(environment.SRDCOrganizationID, account._id, query)
            .pipe(takeUntil(this._destroyed$))
            .toPromise()
            .then(response => {
                const ts = new TimeSeries();
                ts.amount = new Amount(response[0].amount.currency, response[0].amount.value);
                account.accountBalanceTimeSeries.push(ts);
            }, error => {
                AlertUtil.showError('Hata', error);
            });
    }


    initGUI() {
        // First, find total for each currency
        this.bankAccountBalancesGUI = [];
        this.bankAccounts.forEach(bankAccount => {
            if (bankAccount.accountBalanceTimeSeries === undefined || !bankAccount.accountBalanceTimeSeries.length) { // Balance info does not exist
                return;
            }
            const currency = bankAccount.currency.toString();
            // Since timeseries are sorted by date, the last one gives us the balance.
            const currentTimeSeries = bankAccount.accountBalanceTimeSeries[0];
            const balance = <number>currentTimeSeries.amount.value;
            this.updateMaxDate(currentTimeSeries.date);
            const relatedGUIElem = this.bankAccountBalancesGUI.find(elem => elem.currency === currency);
            if (relatedGUIElem) {
                relatedGUIElem.amount += balance;
            } else {
                this.bankAccountBalancesGUI.push(new BankAccountBalanceGUI(currency, balance));
            }
        });
        // Now, we should find total in 3 different currencies by using exchangeRates.
        this.bankAccountTotalsGUI = [];
        this.TOTAL_CURRENCIES_TO_SHOW.forEach(currency => {
            const total = this.bankAccountBalancesGUI.map(totalBalance => {
                const exchangeRate = this.getExchangeRate(totalBalance.currency, currency);
                return exchangeRate * totalBalance.amount;
            }).reduce((a, b) => a + b, 0); // get total
            // Push total in that currency
            this.bankAccountTotalsGUI.push(new BankAccountBalanceGUI(currency, total));
        });

    }

    updateMaxDate(date: Date) {
        if (!this.maxDate || date > this.maxDate) {
            this.maxDate = date;
        }
    }

}

export class BankAccountBalanceGUI {
    currency: string;
    amount: number;

    constructor(currency: string, amount: number) {
        this.currency = currency;
        this.amount = amount;
    }
}
