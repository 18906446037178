declare const $: any;

export class AlertUtil {

    static TYPE_SUCCESS = 'success';
    static TYPE_INFO = 'info';
    static TYPE_WARNING = 'warning';
    static TYPE_ERROR = 'negative';

    static TIME_SHORT = 2;
    static TIME_NORMAL = 4;
    static TIME_LONG = 6;

    static DEFAULT_TITLE = '';
    static DEFAULT_CONTENT = 'Unknown Error';


    static showAlert(content: string, type: string, title: string, time?: number) {

        if (!time) {
            time = AlertUtil.TIME_NORMAL;
        }

        const ui_alert = 'ui-alert-content';

        if (!$('body > .' + ui_alert).length) {
            $('body').append('<div class="ui-alert-content ' + ui_alert + '" style="width: calc(20%); min-width: 250px;"></div>');
        }

        const message = $('<div id="messages" class="ui ' + type + ' message"><div class="header">' + (title ? title : AlertUtil.DEFAULT_TITLE)
            + '</div><i id="messageclose" class="close icon"></i><p>' + (content ? content : AlertUtil.DEFAULT_CONTENT) + '</p></div>');
        $('.' + ui_alert).prepend(message);

        message.animate({
            opacity: '1',
        }, 300);


        let timer: any = 0;
        $(message).mouseenter(() => {
            clearTimeout(timer);
        });
        $(message).mouseleave(() => {
            setTimeout(() => {
                message.animate({
                    opacity: '0',
                }, 300, () => {
                    message.remove();
                });
            }, (time * 1000));
        });

        timer = setTimeout(() => {
            message.animate({
                opacity: '0',
            }, 300, () => {
                message.remove();
            });
        }, (time * 1000));


        $('#messageclose')
            .on('click', function () {
                $(this)
                    .closest('#messages')
                    .transition('fade');
            });
    }

    static showSuccess(content?: string, title?: string, time?: number) {
        AlertUtil.showAlert(content, AlertUtil.TYPE_SUCCESS, title, time);
    }

    static showInfo(content?: string, title?: string, time?: number) {
        AlertUtil.showAlert(content, AlertUtil.TYPE_INFO, title, time);
    }

    static showWarning(content?: string, title?: string, time?: number) {
        AlertUtil.showAlert(content, AlertUtil.TYPE_WARNING, title, time);
    }

    static showError(content?: string, title?: string, time?: number) {
        AlertUtil.showAlert(content, AlertUtil.TYPE_ERROR, title, time);
    }


}
