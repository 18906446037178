import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Invoice} from '../../../models/invoice';
import {environment} from '../../../environments/environment';
import {MonthlyCostTableForAccountantByEmployee} from '../../../models/monthlyCostTableForAccountantByEmployee';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/invoice';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getInvoicesSize(query?: any) {
        const URL = this.URL + '/size';
        return this.http.get<{size: number}>(URL, {headers: this.headers, params: query});
    }

    public getPeriodicReportInvoices() {
        const URL = this.URL + '/periodic-report';
        return this.http.get<Invoice[]>(URL, {headers: this.headers});
    }

    public getInvoices(query?: any) {
        const URL = this.URL + '/invoice';
        return this.http.get<Invoice[]>(URL, {headers: this.headers, params: query});
    }

    public getCostStatementInvoices(query: any) {
        const URL = this.URL + '/cost';
        return this.http.get<Invoice[]>(URL, {headers: this.headers, params: query});
    }

    public getHomeInvoices(query: any) {
        const URL = this.URL + '/home';
        return this.http.get<Invoice[]>(URL, {headers: this.headers, params: query});
    }

    public getInvoiceWithID(id: number, query: any = {}) {
        const URL = this.URL + '/' + id;
        return this.http.get<Invoice>(URL, {headers: this.headers, params: query});
    }

    public getDocumentWithInvoiceID(id: number) {
        const URL = this.URL + '/document/' + id;
        return this.http.get(URL, {headers: this.headers});
    }

    public addInvoice(invoice: Invoice) {
        const URL = this.URL + '/add';
        return this.http.post(URL, invoice, {headers: this.headers});
    }

    public updateInvoice(invoice: Invoice) {
        const URL = this.URL + '/' + invoice.id;
        return this.http.put(URL, invoice, {headers: this.headers});
    }

    public deleteInvoice(id: number) {
        const URL = this.URL + '/' + id;
        return this.http.delete(URL, {headers: this.headers});
    }

    public getIncomeInvoicesWithQuery(query: any) {
        const URL = this.URL + '/income';
        return this.http.get<Invoice[]>(URL, {headers: this.headers, params: query});
    }

    public getOutcomeInvoicesWithQuery(query: any) {
        const URL = this.URL + '/outcome';
        return this.http.get<Invoice[]>(URL, {headers: this.headers, params: query});
    }

    public pay(invoice: Invoice, talimatFormatSelector: string) {
        const URL = this.URL + '/pay';
        const query = {'form': talimatFormatSelector};
        return this.http.post(URL, invoice, {headers: this.headers, params: query, responseType: 'blob'});
    }

    public payAllInHouses(period: MonthlyCostTableForAccountantByEmployee) {
        const URL = this.URL + '/payAllInHouse';
        return this.http.post(URL, period, {headers: this.headers, responseType: 'blob'});
    }

    public prepareReport(talimat: any) {
        const URL = this.URL + '/prepareReport';
        return this.http.post(URL, talimat, {headers: this.headers, responseType: 'blob'});
    }

    public getLatestInvoice(query: any) {
        const URL = this.URL + '/latest';
        return this.http.get<Invoice>(URL, {headers: this.headers, params: query});
    }

}
