import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OrganizationService = /** @class */ (function () {
    function OrganizationService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/organization';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    OrganizationService.prototype.getManagementOrganizations = function (query) {
        var URL = this.URL + '/management';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.getOrganizationsSize = function (query) {
        var URL = this.URL + '/size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.getOrganizationsSummary = function () {
        var URL = this.URL + '/summary';
        return this.http.get(URL, { headers: this.headers });
    };
    OrganizationService.prototype.getOrganizationWithID = function (id) {
        var URL = this.URL + '/' + id;
        return this.http.get(URL, { headers: this.headers });
    };
    OrganizationService.prototype.getSRDC = function () {
        return this.getOrganizationWithID(environment.SRDCOrganizationID);
    };
    OrganizationService.prototype.addOrganization = function (organization) {
        var URL = this.URL + '/add';
        return this.http.post(URL, organization, { headers: this.headers });
    };
    OrganizationService.prototype.updateOrganization = function (organization) {
        var URL = this.URL + '/' + organization.id;
        return this.http.put(URL, organization, { headers: this.headers });
    };
    OrganizationService.prototype.deleteOrganization = function (id) {
        var URL = this.URL + '/' + id;
        return this.http.delete(URL, { headers: this.headers });
    };
    OrganizationService.prototype.getBankAccounts = function (id, query) {
        var URL = this.URL + '/' + id + '/bank-accounts';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.getTimeSeriesWithAccID = function (srdcID, accountID, query) {
        var URL = this.URL + '/' + srdcID + '/bank-accounts/timeSeries/' + accountID;
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.getAvailableBalance = function (srdcID, accountID, query) {
        var URL = this.URL + '/' + srdcID + '/bank-accounts/' + accountID + '/availBalance';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.getTimeSeriesSizeByAccID = function (srdcID, accountID, query) {
        var URL = this.URL + '/' + srdcID + '/bank-accounts/timeSeries/' + accountID + '/size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.getBankAccountsSize = function (id, query) {
        var URL = this.URL + '/' + id + '/bank-accounts/size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.saveUpdateBankAccount = function (organization_id, bankAccount, query) {
        var URL = this.URL + '/' + organization_id + '/bank-accounts';
        return this.http.put(URL, bankAccount, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.changeBankAccountActiveness = function (organization_id, _id, activate) {
        var URL = this.URL + '/' + organization_id + '/bank-accounts/activation/' + _id;
        return this.http.patch(URL, {}, { headers: this.headers, params: { activate: activate.toString() } });
    };
    OrganizationService.prototype.addTimeSeries = function (organization_id, ts, query) {
        var URL = this.URL + '/' + organization_id + '/bank-accounts';
        return this.http.post(URL, ts, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.updateTimeSeries = function (organization_id, ts, query) {
        var URL = this.URL + '/' + organization_id + '/bank-accounts/' + ts._id;
        return this.http.put(URL, ts, { headers: this.headers, params: query });
    };
    OrganizationService.prototype.deleteTimeSeries = function (organization_id, ts, query) {
        var URL = this.URL + '/' + organization_id + '/bank-accounts/' + ts._id;
        return this.http.delete(URL, { headers: this.headers, params: query });
    };
    OrganizationService.ngInjectableDef = i0.defineInjectable({ factory: function OrganizationService_Factory() { return new OrganizationService(i0.inject(i1.HttpClient)); }, token: OrganizationService, providedIn: "root" });
    return OrganizationService;
}());
export { OrganizationService };
