<span style="font-weight: bold">{{maxDate | date: 'dd/MM/yyyy'}}</span> tarihi itibariyle banka hesaplarındaki toplam tutarlar:
<table class="ui very basic collapsing celled table" style="margin: 5px auto auto auto">
    <tbody>
    <tr *ngIf="loading">
        <div class="ui active centered inline loader"></div>
    </tr>
    <tr *ngIf="!loading">
        <ng-container *ngFor="let account of bankAccountBalancesGUI">
            <td>
                <h4 class="ui header">
                    <div class="content">
                        {{account.currency}}
                    </div>
                </h4>
                <span>{{utilsService.formatDouble(account.amount, 2)}}</span>
                <i class="sign icon"
                   [ngClass]="{
                        'lira': account.currency === 'TL',
                        'euro': account.currency === 'EURO',
                        'usd': account.currency === 'USD'
                    }"></i>
            </td>
        </ng-container>
        <td *ngIf="accountToShow">
            <h4 class="ui header">
                <div class="content">
                    TOPLAM
                </div>
            </h4>
            <ng-container *ngFor="let account of [accountToShow]">
                <span [@simpleFadeAnimation]="'in'">{{utilsService.formatDouble(account.amount, 2)}}</span>
                <i class="sign icon"
                   [ngClass]="{
                        'lira': account.currency === 'TL',
                        'euro': account.currency === 'EURO',
                        'usd': account.currency === 'USD'
                    }"></i>
            </ng-container>
        </td>
    </tr>
    </tbody>
</table>

