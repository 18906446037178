import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../shared/services/authentication.service';
import {AlertUtil} from '../util/AlertUtil';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    password = {
        'new': '',
        'verify': ''
    };
    differentPassword = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {
    }

    ngOnInit() {
        this.authenticationService.logout();
        this.authenticationService.verifyToken(this.route.snapshot.params.id).subscribe(
            response => {
                if (response.success === false) {
                    AlertUtil.showError('Bu bağlantı geçersiz veya süresi dolmuş', 'Bağlantı Hatası');
                    this.router.navigate(['/login']);
                }
            }
        );

    }

    onSubmit() {
        if (this.password.new !== this.password.verify) {
            this.differentPassword = true;
        } else {
            this.differentPassword = false;
            this.authenticationService.resetPassword(this.route.snapshot.params.id, this.password.new).subscribe(
                response => {
                    if (response.success) {
                        this.router.navigate(['/login']);
                    }
                }
            );
        }
    }
}
