import {VacationDaysEntry} from './vacationDaysEntry';
import {ExcuseLeaveHoursEntry} from './excuseLeaveHoursEntry';

export class VacationInformation {
    numberOfVacationDaysRights: number;
    vacationDaysRemainingFromLastYear: number;
    vacationDaysUsedInThisYear: number;
    numberOfExcuseLeaveHoursRights: number;
    excuseLeaveHoursRemainingFromLastYear: number;
    excuseLeaveHoursUsedInThisYear: number;
    vacationDaysEntries: VacationDaysEntry[];
    excuseLeaveHoursEntries: ExcuseLeaveHoursEntry[];

    constructor() {
        this.numberOfVacationDaysRights = 0;
        this.vacationDaysRemainingFromLastYear = 0;
        this.vacationDaysUsedInThisYear = 0;
        this.numberOfExcuseLeaveHoursRights = 0;
        this.excuseLeaveHoursRemainingFromLastYear = 0;
        this.excuseLeaveHoursUsedInThisYear = 0;
        this.vacationDaysEntries = [];
        this.excuseLeaveHoursEntries = [];
    }
}
