import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EnvironmentVariableService = /** @class */ (function () {
    function EnvironmentVariableService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/environment';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    EnvironmentVariableService.prototype.getEnvVariables = function (query) {
        var URL = this.URL + '/variables';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    EnvironmentVariableService.prototype.putUpdateEnvVariables = function (data) {
        var URL = this.URL + '/variables/' + data._id;
        return this.http.put(URL, data, { headers: this.headers });
    };
    EnvironmentVariableService.ngInjectableDef = i0.defineInjectable({ factory: function EnvironmentVariableService_Factory() { return new EnvironmentVariableService(i0.inject(i1.HttpClient)); }, token: EnvironmentVariableService, providedIn: "root" });
    return EnvironmentVariableService;
}());
export { EnvironmentVariableService };
