import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BankAccountFilterPipe} from './bank-account-filter.pipe';
import {MakeupDatePipe} from './makeup-date.pipe';
import {ProjectFilterPipe} from './project-filter.pipe';
import {SearchPipe} from './search.pipe';
import {SortPipe} from './sort.pipe';
import {VacationDateFilterPipe} from './vacation-date-filter.pipe';
import {SafeUrlPipe} from './safe-url.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [BankAccountFilterPipe, MakeupDatePipe, ProjectFilterPipe, SearchPipe, SortPipe, VacationDateFilterPipe, SafeUrlPipe],
    exports: [BankAccountFilterPipe, MakeupDatePipe, ProjectFilterPipe, SearchPipe, SortPipe, VacationDateFilterPipe, SafeUrlPipe]
})
export class PipesModule { }
