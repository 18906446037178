<!-- DELETE FILE MODAL -->
<div appModal #deleteFileModal=modal class="ui mini modal">
    <div class="header">
        <b>Dosya Sil</b>
        <i (click)="deleteFileModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        Silmek istediğinizden emin misiniz?
    </div>
    <div class="actions">
        <button [disabled]="clicked" class="ui negative button" (click)="clicked=true; deleteFile()">Sil</button>
    </div>
</div>

<!-- DELETE BANK ACCOUNT MODAL -->
<div appModal #deleteBankAccountModal=modal class="ui mini modal">
    <div class="header">
        <b>Hesap Sil</b>
        <i (click)="deleteBankAccountModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        Silmek istediğinizden emin misiniz?
    </div>
    <div class="actions">
        <button [disabled]="clicked" class="ui negative button" (click)="clicked=true; deleteBankAccount()">Sil</button>
    </div>
</div>

<!-- DELETE  TIME SERIES MODAL -->
<div appModal #deleteTimeSeriesModal=modal class="ui mini modal">
    <div class="header">
        <b>Çizelge Sil</b>
        <i (click)="deleteTimeSeriesModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        Silmek istediğinizden emin misiniz?
    </div>
    <div class="actions">
        <button [disabled]="clicked" class="ui negative button" (click)="clicked=true; removeTimeSeries()">Sil</button>
    </div>
</div>

<!-- EDIT BANK ACCOUNT VIEW -->
<div *ngIf="menuControllerService.profilePageView==='bankInputView'" id="bankInputView" >
    <div class="column" style="margin-top: 51px;"></div>
    <div *ngIf="bankAccount" class="container padded">
        <div class="row">
            <div class="ui grid one column padded">
                <div class="column">
                    <form class="ui form" xmlns="">
                        <div class="ui fluid card">
                            <div class="content">
                                <div class="ui header">
                                    <ng-container *ngIf="isNewAccount">Yeni Banka Hesabı</ng-container>
                                    <ng-container *ngIf="!isNewAccount">Banka Hesabı Güncelleme</ng-container>
                                </div>
                            </div>
                            <div class="content">
                                <div *ngIf="wrongBankAccount" class="field"><i class="exclamation icon reqIcon">=gerekli</i></div>
                                <div class="field">
                                    <label>IBAN<span *ngIf="wrongBankAccount && !bankAccount.iban"><i class="exclamation icon reqIcon"></i></span></label>
                                    <input type="text" [(ngModel)]="bankAccount.iban" name="iban" placeholder="IBAN">
                                </div>
                                <div class="two fields">
                                    <div class="field">
                                        <label>Banka<span *ngIf="wrongBankAccount && !bankAccount.bank"><i class="exclamation icon reqIcon"></i></span></label>
                                        <input type="text" [(ngModel)]="bankAccount.bank" name="bank" placeholder="Banka">
                                    </div>
                                    <div class="field">
                                        <label>Şube<span *ngIf="wrongBankAccount && !bankAccount.branch"><i class="exclamation icon reqIcon"></i></span></label>
                                        <input type="text" [(ngModel)]="bankAccount.branch" name="branch" placeholder="Şube">
                                    </div>
                                </div>
                                <div class="two fields">
                                    <div class="field">
                                        <label>Hesap no<span *ngIf="wrongBankAccount && !bankAccount.accountNo"><i class="exclamation icon reqIcon"></i></span></label>
                                        <input type="text" [(ngModel)]="bankAccount.accountNo" name="accountNo" placeholder="Hesap no">
                                    </div>
                                    <div class="field">
                                        <label>Hesap türü<span *ngIf="wrongBankAccount && !bankAccount.type"><i class="exclamation icon reqIcon"></i></span></label>
                                        <input type="text" [(ngModel)]="bankAccount.type" name="type" placeholder="Hesap türü">
                                    </div>
                                </div>
                                <div class="three fields">
                                    <div class="centeredField">
                                        <div class="ui form">
                                            <div class="field">
                                                <label>Para Birimi</label>
                                            </div>
                                            <div class="inline fields">
                                                <div class="field">
                                                    <div class="ui radio checkbox">
                                                        <input [(ngModel)]="bankAccount.currency" value='TL' type="radio" name="currency">
                                                        <label>TL</label>
                                                    </div>
                                                </div>
                                                <div class="field">
                                                    <div class="ui radio checkbox">
                                                        <input [(ngModel)]="bankAccount.currency" value='EURO' type="radio" name="currency">
                                                        <label>EURO</label>
                                                    </div>
                                                </div>
                                                <div class="field">
                                                    <div class="ui radio checkbox">
                                                        <input [(ngModel)]="bankAccount.currency" value='USD' type="radio" name="currency">
                                                        <label>USD</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="centeredField">
                                        <div class="ui form">
                                            <div class="field">
                                                <label>Vadeli mi?</label>
                                            </div>
                                            <div class="inline fields">
                                                <div class="field">
                                                    <div class="ui radio checkbox">
                                                        <input [(ngModel)]="bankAccount.isInterest" [value]=true type="radio" name="isInterest">
                                                        <label>Evet</label>
                                                    </div>
                                                </div>
                                                <div class="field">
                                                    <div class="ui radio checkbox">
                                                        <input [(ngModel)]="bankAccount.isInterest" [value]=false type="radio" name="isInterest">
                                                        <label>Hayır</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui fluid card">
                            <div class="content">
                                <div class="ui header">
                                    Zaman Çizelgeleri
                                </div>
                            </div>
                            <div class="content">
                                <table *ngIf="timeSeriesRequestCame || editing"  class="ui compact celled table">
                                    <thead>
                                    <tr>
                                        <th>Tarih</th>
                                        <th>Tutar</th>
                                        <th>Açıklama</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let ts of requestedTimeSeries">
                                        <td>{{ts.date | date: 'dd/MM/yyyy'}}</td>
                                        <td>{{utilsService.formatDouble(ts.amount.value,2)}} {{ts.amount.currency}}</td>
                                        <td>{{ts.description}}</td>
                                        <td class="center aligned">
                                            <div class="ui icon buttons" style="box-sizing: border-box">
                                                <button [disabled]="clicked" class="ui button" data-tooltip="Timeseries güncelle" (click)="clicked=true; updateTimeSeries(ts)" tabindex="0"><i class="edit icon"></i></button>
                                                <button [disabled]="clicked" class="ui negative button" data-tooltip="Timeseries sil" (click)="clicked=true; removeTimeSeriesF(ts); clicked=false">
                                                    <i class="trash alternate outline icon"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot class="full-width">
                                    <tr>
                                        <th colspan="4">
                                            <button [disabled]="clicked" class="ui right floated primary labeled icon button" (click)="clicked=true; addTimeSeriesModalF()" style="margin-right: 1%">
                                                <i class="add icon"></i> Zaman Çizelgesi Ekle
                                            </button>
                                        </th>
                                    </tr>
                                    </tfoot>
                                     <!-- Paginator For Time Series Starts -->
                                    <tfoot class="full-width" *ngIf="!editing && paginatorTimeSeries.isPaginatorVisible() ">
                                        <tr>
                                            <th colspan="1" style="border-right: none">
                                                <label>Gösterim: </label>
                                                <select (change)="paginatorTimeSeries.onPageSizeChange()" [(ngModel)]="paginatorTimeSeries.selectedPageSize" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                    <option *ngFor="let pageSize of paginatorTimeSeries.pageSizeOptions" [ngValue]=pageSize name="pageSize">{{pageSize}}</option>
                                                </select>
                                            </th>
                                            <th colspan="2" style="border-left: none" class="center aligned">
                                                <div class="ui menu"
                                                     *ngIf="paginatorTimeSeries.isPagesMenuVisible()"
                                                     style="width: fit-content; display: inline-flex">
                                                    <ng-container *ngIf="paginatorTimeSeries.selectedPage !== 1">
                                                        <div class="icon item" (click)="paginatorTimeSeries.changePage(1)" style="cursor: pointer">
                                                            <i class="large angle double left icon" ></i>
                                                        </div>
                                                        <div class="icon item" (click)="paginatorTimeSeries.changePage(0)" style="cursor: pointer">
                                                            <i class="large angle left icon" ></i>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngFor="let number of [4,3,2,1]">
                                                        <a *ngIf="paginatorTimeSeries.selectedPage > number && paginatorTimeSeries.selectedPage > paginatorTimeSeries.pageCount - (5 - number)" class="item" (click)="paginatorTimeSeries.changePage(paginatorTimeSeries.selectedPage - number)">
                                                            {{paginatorTimeSeries.selectedPage - number}}
                                                        </a>
                                                    </ng-container>
                                                    <a class="active item">{{paginatorTimeSeries.selectedPage}}</a>
                                                    <ng-container *ngFor="let number of [1,2,3]">
                                                        <a *ngIf="paginatorTimeSeries.selectedPage < paginatorTimeSeries.pageCount - number" class="item" (click)="paginatorTimeSeries.changePage(paginatorTimeSeries.selectedPage + number)">
                                                            {{paginatorTimeSeries.selectedPage + number}}
                                                        </a>
                                                    </ng-container>
                                                    <div *ngIf="paginatorTimeSeries.selectedPage  < paginatorTimeSeries.pageCount - 4" class="disabled item">...</div>
                                                    <a *ngIf="paginatorTimeSeries.pageCount !== 0 &&  paginatorTimeSeries.selectedPage !== paginatorTimeSeries.pageCount" class="item" (click)="paginatorTimeSeries.changePage(paginatorTimeSeries.pageCount)">
                                                        {{paginatorTimeSeries.pageCount}}
                                                    </a>
                                                    <ng-container *ngIf="paginatorTimeSeries.selectedPage !== paginatorTimeSeries.pageCount && paginatorTimeSeries.pageCount !== 0">
                                                        <div class="icon item" (click)="paginatorTimeSeries.changePage(paginatorTimeSeries.pageCount + 1)" style="cursor: pointer">
                                                            <i class="large angle right icon" ></i>
                                                        </div>
                                                        <div class="icon item" (click)="paginatorTimeSeries.changePage(paginatorTimeSeries.pageCount)" style="cursor: pointer">
                                                            <i class="large angle double right icon" ></i>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </th>
                                            <th colspan="1" style="border-left: none"></th>
                                        </tr>
                                    </tfoot>
                                    <!-- Paginator for Time Series Ends -->
                                </table>
                            </div>
                            <div class="extra content">
                                <button class="ui left labeled icon button" (click)="goBackButton()" tabindex="0"><i class="left arrow icon"></i>Geri</button>
                                <button *ngIf="isNewAccount" [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; addBankAccount()">
                                    Ekle
                                    <i class="checkmark icon"></i>
                                </button>
                                <button *ngIf="!isNewAccount" [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; updateBankAccount()">
                                    Güncelle
                                    <i class="checkmark icon"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- EDIT TIME SERIES MODAL -->
<div appModal #editTimeSeriesModal=modal class="ui modal">
    <div class="header">
        <b *ngIf="isNewTimeSeries">Yeni Zaman Çizelgesi</b>
        <b *ngIf="!isNewTimeSeries">Zaman Çizelgesi Güncelleme</b>
        <i (click)="editTimeSeriesModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <form class="ui form" xmlns="">
            <div *ngIf="wrongTimeSeries" class="field"><i class="exclamation icon reqIcon">=gerekli</i></div>
            <div class="two fields">
                <div class="field">
                    <label>Tarih<span *ngIf="wrongTimeSeries && !timeSeries.date"><i class="exclamation icon reqIcon"></i></span></label>
                    <div class="ui icon input">
                        <i>
                            <mat-datepicker #timeSeriesDateDP></mat-datepicker>
                        </i>
                        <input [(ngModel)]="timeSeries.date" name="date"
                               [ngModelOptions]="{standalone: true}" [matDatepicker]="timeSeriesDateDP">
                        <mat-datepicker-toggle matSuffix [for]="timeSeriesDateDP"></mat-datepicker-toggle>
                    </div>
                </div>
                <div class="field">
                    <label>Miktar<span *ngIf="wrongTimeSeries && !timeSeries.amount.value"><i class="exclamation icon reqIcon"></i></span></label>
                    <input [(ngModel)]="timeSeries.amount.value" type="number" name="amount" placeholder="Miktar">
                </div>
            </div>
            <div class="field">
                <label>Açıklama</label>
                <input [(ngModel)]="timeSeries.description" type="text"  name="description" placeholder="Açıklama">
            </div>
        </form>
    </div>
    <div class="actions">
        <button [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; timeSeriesToAccount()">
            <ng-container *ngIf="isNewTimeSeries">Zaman Çizelgesi Ekle</ng-container>
            <ng-container *ngIf="!isNewTimeSeries">Zaman Çizelgesi Güncelle</ng-container>
            <i class="checkmark icon"></i>
        </button>
    </div>
</div>

<!-- TIME SERIES CHART MODAL -->
<div appModal #showTimeSeriesModal=modal class="ui modal">
    <div class="header">
        <b>Zaman Çizelgesi</b>
        <i (click)="showTimeSeriesModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <dx-chart
            id="chart"
            [dataSource]="dataSource">
            <dxi-series
                argumentField="date"
                valueField="amount"
                [name]="chartName"
                type="line"
                color="teal">
            </dxi-series>
            <dxo-tooltip
                [enabled]="true"
                [zIndex]="10000"
                [customizeTooltip]="customizeTooltip">
            </dxo-tooltip>
        </dx-chart>
    </div>
</div>

<!-- UPDATE FILE MODAL -->
<div appModal #updateFileModal=modal class="ui mini modal">
    <div class="header">
        <b *ngIf="fileType==='CV'">{{getFileName('cv')}}</b>
        <b *ngIf="fileType==='SSOEmp'">{{getFileName('sgk')}}</b>
        <b *ngIf="fileType==='contracts'">{{getFileName('contracts')}}</b>
        <i (click)="updateFileModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <form class="ui form" xmlns="">
            <div *ngIf="fileType==='CV'" class="field">
                <div class="field">
                    <ng-container *ngIf="isCVSelected">
                        <span style="font-size: larger">{{CV?.name}} </span>
                        <button [disabled]="clicked" class="ui small primary icon button" (click)="clicked=true; cvChange(false, null)">
                            <i class="delete icon"></i>
                        </button>
                    </ng-container>
                    <input type="file" name="CV" accept="application/pdf" (change)="cvChange(true, $event)">
                </div>
            </div>
            <div *ngIf="fileType==='SSOEmp'" class="field">
                <div class="field">
                    <ng-container *ngIf="isSSOEmpSelected">
                        <span style="font-size: larger">{{SSOEmp?.name}} </span>
                        <button [disabled]="clicked" class="ui small primary icon button" (click)="clicked=true; ssoEmpChange(false, null)">
                            <i class="delete icon"></i>
                        </button>
                    </ng-container>
                    <input type="file" name="CV" accept="application/pdf" (change)="ssoEmpChange(true, $event)">
                </div>
            </div>
            <div *ngIf="fileType==='contracts'" class="field">
                <div class="field">
                    <ng-container *ngIf="isNewContractSelected">
                        <span style="font-size: larger">{{newContract?.name}} </span>
                        <button [disabled]="clicked" class="ui small primary icon button" (click)="clicked=true; contractsChange(false, null)">
                            <i class="delete icon"></i>
                        </button>
                    </ng-container>
                    <input type="file" name="CV" accept="application/pdf" (change)="contractsChange(true, $event)">
                </div>
            </div>
        </form>
    </div>
    <div class="actions">
        <button [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; saveFile()">
            <ng-container *ngIf="fileType==='contracts'">Ekle</ng-container>
            <ng-container *ngIf="fileType!=='contracts'">Güncelle</ng-container>
            <i class="checkmark icon"></i>
        </button>
    </div>
</div>

<!-- CHANGE PASSWORD MODAL -->
<div appModal #changePasswordModal=modal class="ui mini modal">
    <div class="header">
        <b>Şifre Güncelleme</b>
        <i (click)="changePasswordModal.close()" class="close icon" style="float: right; cursor: pointer; font-size: 1em"></i>
    </div>
    <div class="content">
        <form class="ui form" xmlns="">
            <div *ngIf="wrongPassword" class="field"><i class="exclamation icon reqIcon">=gerekli</i></div>
            <div *ngIf="differentPassword" class="field"><span style="color: red"><i class="exclamation icon reqIcon"></i>Yeni şifrenizi onaylayamadınız.</span></div>
            <div *ngIf="wrongCurrentPassword" class="field"><span style="color: red"><i class="exclamation icon reqIcon"></i>Mevcut şifrenizi yanlış girdiniz.</span></div>
            <div class="field">
                <label>Şifre<span *ngIf="wrongPassword && !password.current"><i class="exclamation icon reqIcon"></i></span></label>
                <input [(ngModel)]="password.current" type="password" name="password" placeholder="Mevcut şifre">
            </div>
            <div class="field">
                <label>Yeni Şifre<span *ngIf="wrongPassword && (!password.new || !password.verify)"><i class="exclamation icon reqIcon"></i></span></label>
                <div class="field">
                    <div class="field">
                        <input type="password" [(ngModel)]="password.new" name="newPassword" placeholder="Yeni şifre">
                    </div>
                    <div class="field">
                        <input type="password" [(ngModel)]="password.verify" name="newPasswordVerify" placeholder="Yeni şifre (tekrar)">
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="actions">
        <button [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; updatePassword()">
            Güncelle
            <i class="checkmark icon"></i>
        </button>
    </div>
</div>

<!-- PROFILE VIEW -->
<div id="overview" *ngIf="menuControllerService.profilePageView==='overview'">
    <div class="container padded" style="margin-top: 51px;">
        <div class="row">
            <div class="ui grid one column padded">
                <div *ngIf="user" class="column">

                    <!--  INFO CARD  -->
                    <div *ngIf="menuControllerService.selectedTab===0" class="ui fluid card">
                        <div class="content">
                            <h2 class="ui header">
                                {{utilsService.getPersonFullName(user.person)}}
                                <button [disabled]="clicked" class="ui right floated button" style="margin: 0 0 0 1%;" (click)="clicked=true; showChangePasswordModal()" tabindex="0">Şifre Değiştir</button>
                                <button [disabled]="clicked" class="ui right floated button" style="margin: 0" (click)="clicked=true; showEditProfileModal()" tabindex="0">Profili Düzenle</button>
                            </h2>
                        </div>
                        <div class="content">
                            <table class="ui very basic collapsing celled table" style="margin-left: 1%">
                                <tbody>
                                <tr>
                                    <td>TC Kimlik No</td>
                                    <td>{{user.citizenID}}</td>
                                </tr>
                                <tr>
                                    <td>SGK no</td>
                                    <td>{{user.socialSecurityID}}</td>
                                </tr>
                                <tr>
                                    <td>Doğum Tarihi</td>
                                    <td>{{user.birthDate | date: 'dd/MM/yyyy'}}</td>
                                </tr>
                                <tr>
                                    <td>Çalışma Türü</td>
                                    <td>
                                        <ng-container *ngIf="user.isFullTime">Tam zamanlı</ng-container><ng-container *ngIf="!user.isFullTime">Yarı zamanlı</ng-container>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Meslek</td>
                                    <td>{{user.profession}}</td>
                                </tr>
                                <tr *ngIf="user.person.description">
                                    <td>Açıklama</td>
                                    <td>{{user.person.description}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="menuControllerService.selectedTab===0" class="ui fluid card">
                        <div class="content">
                            <h2 class="ui header">
                                İletişim
                            </h2>
                        </div>
                        <div class="content">
                            <table class="ui very basic collapsing celled table" style="margin-left: 1%">
                                <tbody>
                                <tr>
                                    <td>Cep telefonu</td>
                                    <td>{{user.person.contact.mobileNumber}}</td>
                                </tr>
                                <tr>
                                    <td>Kişisel e-posta adresi</td>
                                    <td>{{user.person.contact.personalEmail}}</td>
                                </tr>
                                <tr>
                                    <td>İş e-posta adresi</td>
                                    <td>{{user.person.contact.workEmail}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="menuControllerService.selectedTab===0" class="ui fluid card">
                        <div class="content">
                            <h2 class="ui header">
                                Tarihler
                            </h2>
                        </div>
                        <div class="content">
                            <table class="ui very basic collapsing celled table" style="margin-left: 1%">
                                <tbody>
                                <tr>
                                    <td>İşe Başlama (Resmi)</td>
                                    <td>{{user.dateOfEmployment_formal | date: 'dd/MM/yyyy'}}</td>
                                </tr>
                                <tr>
                                    <td>İşe Başlama (Resmi Olmayan)</td>
                                    <td>{{user.dateOfEmployment_informal | date: 'dd/MM/yyyy'}}</td>
                                </tr>
                                <tr *ngIf="user.bachelorGraduationDate">
                                    <td>Lisans Mezuniyet</td>
                                    <td>{{user.bachelorGraduationDate | date: 'dd/MM/yyyy'}}</td>
                                </tr>
                                <tr *ngIf="user.MScGraduationDate">
                                    <td>Yüksek Lisans Mezuniyet</td>
                                    <td>{{user.MScGraduationDate | date: 'dd/MM/yyyy'}}</td>
                                </tr>
                                <tr *ngIf="user.PhDGraduationDate">
                                    <td>Doktora Mezuniyet</td>
                                    <td>{{user.PhDGraduationDate | date: 'dd/MM/yyyy'}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!--  BANK ACCOUNTS CARD  -->
                    <div *ngIf="menuControllerService.selectedTab===1" class="ui fluid card">
                        <div class="content">
                            <h2 class="ui header">
                                Maaş: {{utilsService.formatDouble(user.netSalaryAmount.value,2)}} {{user.netSalaryAmount.currency}}
                            </h2>
                        </div>
                        <div class="content">
                            <table *ngIf="bankAccountsArrived && user.bankAccounts && user.bankAccounts.length" class="ui celled table">
                                <thead>
                                <tr>
                                    <th>IBAN</th>
                                    <th>Şube</th>
                                    <th>Hesap no</th>
                                    <th>Para birimi</th>
                                    <th>Hesap türü</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let acc of user.bankAccounts">
                                    <td>{{acc.iban}}</td>
                                    <td>{{acc.bank}} / {{acc.branch}}</td>
                                    <td>{{acc.accountNo}}</td>
                                    <td>{{acc.currency}}</td>
                                    <td>{{acc.type}} / <div *ngIf="acc.isInterest">Vadeli</div><div *ngIf="!acc.isInterest">Vadesiz</div></td>
                                    <td class="center aligned">
                                        <div class="ui icon buttons">
                                            <button [disabled]="clicked" class="ui button" data-tooltip="Timeseries göster" (click)="clicked=true; showTimeSeriesModalF(acc)" tabindex="0"t><i class="chart bar icon"></i></button>
                                            <button [disabled]="clicked" class="ui button" data-tooltip="Banka hesabı güncelle" (click)="clicked=true; bankAccountModalF(acc)" tabindex="0"><i class="edit icon"></i></button>
                                            <button [disabled]="clicked" id="delButton" class="ui button" data-tooltip="Banka hesabı sil" (click)="clicked=true; deleteBankAccountModalF(acc.iban)"><i class="delete icon"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot class="full-width" *ngIf="paginator.isPaginatorVisible()">
                                    <tr>
                                        <th colspan="1" style="border-right: none">
                                            <label>Gösterim: </label>
                                            <select (change)="paginator.onPageSizeChange()" [(ngModel)]="paginator.selectedPageSize" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                                <option *ngFor="let pageSize of paginator.pageSizeOptions" [ngValue]=pageSize name="pageSize">{{pageSize}}</option>
                                            </select>
                                        </th>
                                        <th colspan="2" style="border-left: none" class="center aligned">
                                            <div class="ui menu"
                                                 *ngIf="paginator.isPagesMenuVisible()"
                                                 style="width: fit-content; display: inline-flex">
                                                <ng-container *ngIf="paginator.selectedPage !== 1">
                                                    <div class="icon item" (click)="paginator.changePage(1)" style="cursor: pointer">
                                                        <i class="large angle double left icon" ></i>
                                                    </div>
                                                    <div class="icon item" (click)="paginator.changePage(0)" style="cursor: pointer">
                                                        <i class="large angle left icon" ></i>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngFor="let number of [4,3,2,1]">
                                                    <a *ngIf="paginator.selectedPage > number && paginator.selectedPage > paginator.pageCount - (5 - number)" class="item" (click)="paginator.changePage(paginator.selectedPage - number)">
                                                        {{paginator.selectedPage - number}}
                                                    </a>
                                                </ng-container>
                                                <a class="active item">{{paginator.selectedPage}}</a>
                                                <ng-container *ngFor="let number of [1,2,3]">
                                                    <a *ngIf="paginator.selectedPage < paginator.pageCount - number" class="item" (click)="paginator.changePage(paginator.selectedPage + number)">
                                                        {{paginator.selectedPage + number}}
                                                    </a>
                                                </ng-container>
                                                <div *ngIf="paginator.selectedPage  < paginator.pageCount - 4" class="disabled item">...</div>
                                                <a *ngIf="paginator.pageCount !== 0 &&  paginator.selectedPage !== paginator.pageCount" class="item" (click)="paginator.changePage(paginator.pageCount)">
                                                    {{paginator.pageCount}}
                                                </a>
                                                <ng-container *ngIf="paginator.selectedPage !== paginator.pageCount && paginator.pageCount !== 0">
                                                    <div class="icon item" (click)="paginator.changePage(paginator.pageCount + 1)" style="cursor: pointer">
                                                        <i class="large angle right icon" ></i>
                                                    </div>
                                                    <div class="icon item" (click)="paginator.changePage(paginator.pageCount)" style="cursor: pointer">
                                                        <i class="large angle double right icon" ></i>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </th>
                                        <th colspan="1" style="border-left: none"></th>
                                    </tr>
                                    </tfoot>
                            </table>
                            <div *ngIf="bankAccountsArrived && user.bankAccounts && !user.bankAccounts.length" class="ui info message">
                                <div class="ui header">
                                    Banka Hesabı Bulunmamaktadır
                                </div>
                            </div>
                            <div *ngIf="!bankAccountsArrived" class="ui info message">
                                <div class="content">
                                    <div class="ui text active centered inline loader">
                                        Yükleniyor...
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <button [disabled]="clicked" class="ui right floated primary labeled icon button" (click)="clicked=true; addNewBankAccount()" style="margin-right: 1%">
                                <i class="plus icon"></i> Hesap Ekle
                            </button>
                        </div>
                    </div>

                    <!--  VACATION CARD  -->
                    <div *ngIf="menuControllerService.selectedTab===2" class="ui fluid card">
                        <div class="content">
                            <div class="ui four statistics">
                                <div class="statistic">
                                    <div class="value">
                                        {{user?.vacationInformation.numberOfVacationDaysRights}}
                                    </div>
                                    <div class="label">
                                        İZİN HAKKI (GÜN)
                                    </div>
                                </div>
                                <div class="statistic">
                                    <div class="value">
                                        {{user?.vacationInformation.vacationDaysRemainingFromLastYear}}
                                    </div>
                                    <div class="label">
                                        İZİN HAKKI <br>
                                        (GEÇEN SENEDEN)
                                    </div>
                                </div>
                                <div class="statistic">
                                    <div class="value">
                                        {{user?.vacationInformation.vacationDaysUsedInThisYear}}
                                    </div>
                                    <div class="label">
                                        KULLANILAN İZİN
                                    </div>
                                </div>
                                <div class="statistic">
                                    <div class="value">
                                        {{user?.vacationInformation.numberOfVacationDaysRights + user?.vacationInformation.vacationDaysRemainingFromLastYear - user?.vacationInformation.vacationDaysUsedInThisYear}}
                                    </div>
                                    <div class="label">
                                        KALAN İZİN
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="menuControllerService.selectedTab===2" class="ui fluid card">
                        <div class="content">
                            <h2 class="ui header">
                                İzin Günleri
                            </h2>
                            <div>
                                <select [(ngModel)]="selectedYear" [ngModelOptions]="{standalone: true}" class="ui search dropdown">
                                    <option *ngFor="let year of years" [ngValue]=year>{{year}}</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="user.vacationInformation.vacationDaysEntries.length" class="content">
                            <table class="ui celled table">
                                <thead>
                                <tr>
                                    <th>Başlangıç Tarihi</th>
                                    <th>Bitiş Tarihi</th>
                                    <th>Gün Sayısı</th>
                                    <th>Açıklama</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let vde of user.vacationInformation.vacationDaysEntries | vacationDateFilter: selectedYear">
                                    <td>{{vde.startDate | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{vde.endDate | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{vde.numberOfDays}}</td>
                                    <td>{{vde.description}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="!user.vacationInformation.vacationDaysEntries.length" class="content">
                            <div class="ui info message">
                                <div class="ui header">
                                    İzin Bilgisi Bulunmamaktadır
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--  LESSON CARD  -->
                    <div *ngIf="menuControllerService.selectedTab===3" class="ui fluid card">
                        <div class="content">
                            <h2 class="ui header">
                                {{educationTerm}} {{user.isFullTime ? 'Ders Programı' : 'Çalışma Saatleri'}}
                            </h2>
                        </div>
                        <div class="content">
                            <hot-table id="hotTableInstance"
                                       [data]="excelData"
                                       [colHeaders]="colHeaders"
                                       [columns]="columns"
                                       [options]="options"
                                       (afterChange)="excelDataChanged($event)"></hot-table>
                        </div>
                        <div class="content">
                            <button [disabled]="clicked" class="ui right floated primary labeled icon button" (click)="clicked=true; saveCourseSchedule()" style="margin-right: 1%">
                                <i class="save icon"></i> Kaydet
                            </button>
                        </div>
                    </div>

                    <!--  CV CARD  -->
                    <div *ngIf="menuControllerService.selectedTab===4" class="ui fluid card">
                        <div class="content">
                            <h2 class="ui header">
                                {{getFileName('cv')}}
                                <button *ngIf="CV_url" [disabled]="clicked" class="ui negative right floated button" style="margin: 0 0 0 1%;" (click)="clicked=true; deleteFileModalF('CV')" tabindex="0">Sil</button>
                                <button [disabled]="clicked" class="ui right floated button" style="margin: 0" (click)="clicked=true; updateFile('CV')" tabindex="0">Güncelle</button>
                            </h2>
                        </div>
                        <div class="content">
                            <div id="cvFrame" class="frame">
                                <div [hidden]="CV_url" class="ui info message">
                                    <div class="ui header">
                                        CV Bulunamadı.
                                    </div>
                                </div>
                                <iframe [hidden]="!CV_url" width="100%" height="700" style="resize: vertical" [attr.src]="CV_url" type="application/pdf"></iframe>
                            </div>
                        </div>
                    </div>

                    <!--  SSO CARD  -->
                    <div *ngIf="menuControllerService.selectedTab===5" class="ui fluid card">
                        <div class="content">
                            <h2 class="ui header">
                                {{getFileName('sgk')}}
                                <button *ngIf="SSO_url" [disabled]="clicked" class="ui negative right floated button" style="margin: 0 0 0 1%;" (click)="clicked=true; deleteFileModalF('SSOEmp')" tabindex="0">Sil</button>
                                <button [disabled]="clicked" class="ui right floated button" style="margin: 0" (click)="clicked=true; updateFile('SSOEmp')" tabindex="0">Güncelle</button>
                            </h2>
                        </div>
                        <div class="content">
                            <div id="ssoFrame" class="frame">
                                <div [hidden]="SSO_url" class="ui info message">
                                    <div class="ui header">
                                        Belge Bulunamadı.
                                    </div>
                                </div>
                                <iframe [hidden]="!SSO_url" width="100%" height="700" style="resize: vertical" [attr.src]="SSO_url" type="application/pdf"></iframe>
                            </div>
                        </div>
                    </div>

                    <!--  CONTRACTS CARD  -->
                    <div *ngIf="menuControllerService.selectedTab===6" class="ui fluid card">
                        <div class="content">
                            <h2 class="ui header">
                                {{getFileName('contracts')}}
                                <button [disabled]="clicked" class="ui right floated button" style="margin: 0" (click)="clicked=true; updateFile('contracts')" tabindex="0">Sözleşme Ekle</button>
                            </h2>
                        </div>
                        <div class="content">
                            <div id="contractFrame" class="frame">
                                <div [hidden]="contract_urls.length" class="ui info message">
                                    <div class="ui header">
                                        Sözleşme Bulunamadı.
                                    </div>
                                </div>
                                <div *ngFor="let cont of contract_urls, let i = index">
                                    <div *ngIf="i!==0" class="ui divider"></div>
                                    <h4 class="ui header">
                                        {{i+1}}. Sözleşme
                                        <button [disabled]="clicked" class="ui primary icon button" data-tooltip="Sil" (click)="clicked=true; removeContract(i)" tabindex="0"><i class="delete icon"></i></button>
                                    </h4>
                                    <iframe  width="100%" height="700" style="resize: vertical" [attr.src]="cont" type="application/pdf"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- EDIT EMPLOYEE VIEW -->
<div id="inputView" *ngIf="menuControllerService.profilePageView==='inputView'">
    <div class="column" style="margin-top: 51px;"></div>
    <div *ngIf="user" class="container padded">
        <div class="row">
            <div class="ui grid one column padded">
                <div class="column">
                    <form class="ui form" xmlns="">
                        <div class="ui fluid card">
                            <div class="content">
                                <div class="ui header">
                                    Temel Bilgiler
                                </div>
                            </div>
                            <div class="content">
                                <div *ngIf="wrongInput" class="field"><i class="exclamation icon reqIcon">=gerekli</i></div>
                                <div class="field">
                                    <label>İsim<span *ngIf="wrongInput && (!user.person.firstName || !user.person.lastName)"><i class="exclamation icon reqIcon"></i></span></label>
                                    <div class="three fields">
                                        <div class="field">
                                            <input type="text" [(ngModel)]="user.person.firstName" name="firstName" placeholder="Ad">
                                        </div>
                                        <div class="field">
                                            <input type="text" [(ngModel)]="user.person.middleName" name="middleName" placeholder="Diğer ad">
                                        </div>
                                        <div class="field">
                                            <input type="text" [(ngModel)]="user.person.lastName" name="lastName" placeholder="Soyad">
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="two fields">
                                        <div class="field">
                                            <label>TC kimlik no<span *ngIf="wrongInput && !user.citizenID"><i class="exclamation icon reqIcon"></i></span></label>
                                            <input type="text" [(ngModel)]="user.citizenID" name="citizenID" placeholder="TC kimlik no">
                                        </div>
                                        <div class="field">
                                            <label>SGK no<span *ngIf="wrongInput && !user.socialSecurityID"><i class="exclamation icon reqIcon"></i></span></label>
                                            <input type="text" [(ngModel)]="user.socialSecurityID" name="socSecID" placeholder="SGK no">
                                        </div>
                                    </div>
                                </div>
                                <div class="field">
                                    <div class="two fields">
                                        <div class="five wide field">
                                            <label>Doğum tarihi<span *ngIf="wrongInput && !user.birthDate"><i class="exclamation icon reqIcon"></i></span></label>
                                            <div class="ui calendar" id="birthdate">
                                                <div class="ui icon input">
                                                    <i>
                                                        <mat-datepicker #birthDateDP></mat-datepicker>
                                                    </i>
                                                    <input [(ngModel)]="user.birthDate" name="birthDate"
                                                           [ngModelOptions]="{standalone: true}" [matDatepicker]="birthDateDP">
                                                    <mat-datepicker-toggle matSuffix [for]="birthDateDP"></mat-datepicker-toggle>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="eleven wide field">
                                            <label>Açıklama</label>
                                            <input type="text" [(ngModel)]="user.person.description" name="description" placeholder="Açıklama">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui fluid card">
                            <div class="content">
                                <div class="ui header">
                                    Tarihler
                                </div>
                            </div>
                            <div class="content">
                                <div class="two fields">
                                    <div class="field">
                                        <label>İşe başlama tarihi (resmi)<span *ngIf="wrongInput && !user.dateOfEmployment_formal"><i class="exclamation icon reqIcon"></i></span></label>
                                        <div class="ui calendar" id="start_formal">
                                            <div class="ui icon input">
                                                <i>
                                                    <mat-datepicker #employmentDateFormalDP></mat-datepicker>
                                                </i>
                                                <input [(ngModel)]="user.dateOfEmployment_formal" name="startDate"
                                                       [ngModelOptions]="{standalone: true}" [matDatepicker]="employmentDateFormalDP">
                                                <mat-datepicker-toggle matSuffix [for]="employmentDateFormalDP"></mat-datepicker-toggle>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label>İşe başlama tarihi (resmi olmayan)<span *ngIf="wrongInput && !user.dateOfEmployment_informal"><i class="exclamation icon reqIcon"></i></span></label>
                                        <div class="ui calendar" id="start_informal">
                                            <div class="ui icon input">
                                                <i>
                                                    <mat-datepicker #employmentDateInformalDP></mat-datepicker>
                                                </i>
                                                <input [(ngModel)]="user.dateOfEmployment_informal" name="endDate"
                                                       [ngModelOptions]="{standalone: true}" [matDatepicker]="employmentDateInformalDP">
                                                <mat-datepicker-toggle matSuffix [for]="employmentDateInformalDP"></mat-datepicker-toggle>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="three fields">
                                    <div class="field">
                                        <label>Mezuniyet tarihi</label>
                                        <div class="ui calendar">
                                            <div class="ui icon input">
                                                <i>
                                                    <mat-datepicker #bachelorGraduationDateDP></mat-datepicker>
                                                </i>
                                                <input [(ngModel)]="user.bachelorGraduationDate" name="grad"
                                                       [ngModelOptions]="{standalone: true}" [matDatepicker]="bachelorGraduationDateDP">
                                                <mat-datepicker-toggle matSuffix [for]="bachelorGraduationDateDP"></mat-datepicker-toggle>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label>Yüksek lisans mezuniyet tarihi</label>
                                        <div class="ui calendar">
                                            <div class="ui icon input">
                                                <i>
                                                    <mat-datepicker #MScGraduationDateDP></mat-datepicker>
                                                </i>
                                                <input [(ngModel)]="user.MScGraduationDate"  name="MSgrad"
                                                       [ngModelOptions]="{standalone: true}" [matDatepicker]="MScGraduationDateDP">
                                                <mat-datepicker-toggle matSuffix [for]="MScGraduationDateDP"></mat-datepicker-toggle>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="field">
                                        <label>Doktora mezuniyet tarihi</label>
                                        <div class="ui calendar">
                                            <div class="ui icon input">
                                                <i>
                                                    <mat-datepicker #PhDGraduationDateDP></mat-datepicker>
                                                </i>
                                                <input [(ngModel)]="user.PhDGraduationDate" name="PhDgrad"
                                                       [ngModelOptions]="{standalone: true}" [matDatepicker]="PhDGraduationDateDP">
                                                <mat-datepicker-toggle matSuffix [for]="PhDGraduationDateDP"></mat-datepicker-toggle>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ui fluid card">
                            <div class="content">
                                <div class="ui header">
                                    İletişim Bilgileri
                                </div>
                            </div>
                            <div class="content">
                                <div class="field">
                                    <label>Cep telefonu<span *ngIf="wrongInput && !user.person.contact.mobileNumber"><i class="exclamation icon reqIcon"></i></span></label>
                                    <div class="field">
                                        <input type="text" [(ngModel)]="user.person.contact.mobileNumber" name="MobileNumber" placeholder="Cep telefonu">
                                    </div>
                                    <label>Kişisel e-posta adresi<span *ngIf="wrongInput && !user.person.contact.personalEmail"><i class="exclamation icon reqIcon"></i></span></label>
                                    <div class="field">
                                        <input type="email" [(ngModel)]="user.person.contact.personalEmail" name="personalMail" placeholder="Kişisel e-posta adresi">
                                    </div>
                                    <label>İş e-posta adresi<span *ngIf="wrongInput && !user.person.contact.workEmail"><i class="exclamation icon reqIcon"></i></span></label>
                                    <div class="field">
                                        <input type="email" [(ngModel)]="user.person.contact.workEmail" name="workMail" placeholder="İş e-posta adresi">
                                    </div>
                                </div>
                            </div>
                            <div class="extra content">
                                <button class="ui left labeled icon button" (click)="menuControllerService.profilePageView='overview'" tabindex="0"><i class="left arrow icon"></i>Geri</button>
                                <button [disabled]="clicked" class="ui primary right labeled icon button" (click)="clicked=true; updateUser()">
                                    Güncelle
                                    <i class="checkmark icon"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
