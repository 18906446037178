import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EmployeeService = /** @class */ (function () {
    function EmployeeService(http) {
        this.http = http;
        this.serverApi = environment.apiUrl;
        this.serverPart = '/employee';
        this.URL = this.serverApi + this.serverPart;
        this.headers = new HttpHeaders().append('Content-Type', 'application/json');
    }
    EmployeeService.prototype.getEmployeesSize = function (query) {
        var URL = this.URL + '/size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.getActiveEmployees = function () {
        var URL = this.URL + '/active';
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getEmployeesWithBankAccount = function () {
        var URL = this.URL + '/bank';
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getEmployeesSummary = function () {
        var URL = this.URL + '/summary';
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getStudentEmployees = function () {
        var URL = this.URL + '/student';
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getManagementEmployees = function (query) {
        var URL = this.URL + '/management';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.getAuthEmployees = function (query) {
        var URL = this.URL + '/auth';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.getExcuseEmployees = function (query) {
        var URL = this.URL + '/excuse';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.getSalaryEmployees = function () {
        var URL = this.URL + '/salary';
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getSalaryEmployeesAtMonth = function (query) {
        var URL = this.URL + '/salary-at-month';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.getEstimationEmployees = function () {
        var URL = this.serverApi + this.serverPart + '/estimation';
        var headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        return this.http.get(URL, { headers: headers });
    };
    EmployeeService.prototype.getEmployeeSummaryWithID = function (citizenID) {
        var URL = this.URL + '/summary/' + citizenID;
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getExcusesOfEmployee = function (employeeObjId) {
        var URL = this.URL + '/excuse/' + employeeObjId;
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getEmployeeWithID = function (citizenID) {
        var URL = this.URL + '/' + citizenID;
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getCurrentUserSummary = function () {
        return this.getEmployeeSummaryWithID(localStorage.getItem('currentUserID'));
    };
    EmployeeService.prototype.getCurrentUser = function () {
        return this.getEmployeeWithID(localStorage.getItem('currentUserID'));
    };
    EmployeeService.prototype.deleteTimeSeries = function (citizenID, ts, query) {
        var tsID = ts._id;
        var URL = this.URL + '/' + citizenID + '/deleteTS/' + tsID;
        return this.http.delete(URL, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.updateTimeSeries = function (citizenID, ts, query) {
        var tsID = ts._id;
        var URL = this.URL + '/' + citizenID + '/updateTS/' + tsID;
        return this.http.put(URL, ts, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.addTimeSeries = function (citizenID, ts, query) {
        var URL = this.URL + '/' + citizenID + '/addTS';
        return this.http.post(URL, ts, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.forgotPassword = function (workEmail) {
        var URL = this.URL + '/forgot';
        return this.http.post(URL, { workEmail: workEmail.value })
            .pipe(map(function (response) {
            if (response && response.token) {
            }
            return response;
        }));
    };
    EmployeeService.prototype.addEmployee = function (employee) {
        var URL = this.URL + '/add';
        return this.http.post(URL, employee, { headers: this.headers });
    };
    EmployeeService.prototype.updateEmployee = function (employee, citizenID) {
        var URL = this.URL + '/' + citizenID;
        return this.http.put(URL, employee, { headers: this.headers });
    };
    EmployeeService.prototype.updateBankAccount = function (bankAccount, citizenID) {
        var URL = this.URL + '/' + citizenID + '/bank-accounts';
        return this.http.put(URL, bankAccount, { headers: this.headers });
    };
    EmployeeService.prototype.addBankAccount = function (bankAccount, citizenID) {
        var URL = this.URL + '/' + citizenID + '/bank-accounts';
        return this.http.post(URL, bankAccount, { headers: this.headers });
    };
    EmployeeService.prototype.deleteBankAccount = function (iban, citizenID) {
        var URL = this.URL + '/' + citizenID + '/bank-accounts/' + iban;
        return this.http.delete(URL, { headers: this.headers });
    };
    EmployeeService.prototype.deleteEmployee = function (citizenID) {
        var URL = this.URL + '/' + citizenID;
        return this.http.delete(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getBankAccounts = function (citizenID, query) {
        var URL = this.URL + '/' + citizenID + '/bank-accounts';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.getBankAccountsSize = function (citizenID) {
        var URL = this.URL + '/' + citizenID + '/bank-accounts/size';
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getAdmins = function () {
        var URL = this.URL + '/admin';
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.prototype.getTimeSeriesSizeByAccID = function (citizenID, accountID, query) {
        var URL = this.URL + '/' + citizenID + '/bank-accounts/timeSeries/' + accountID + '/size';
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.getTimeSeriesByAccID = function (citizenID, accountID, query) {
        var URL = this.URL + '/' + citizenID + '/bank-accounts/timeSeries/' + accountID;
        return this.http.get(URL, { headers: this.headers, params: query });
    };
    EmployeeService.prototype.getPersonnelExcel = function (exportRetired, exportOldWorkers, isActive, query) {
        var URL = this.URL + '/personnel-list/export';
        query.isActive = isActive; // get either current or old employee excel list
        query.exportRetired = exportRetired; // decide if we want to export retired ppl
        query.exportOldWorkers = exportOldWorkers;
        return this.http.get(URL, { headers: this.headers, params: query, responseType: 'blob' });
    };
    EmployeeService.prototype.getEmployeesInProject = function (projectID, startDate, endDate) {
        var URL = this.URL + '/in-project/' + projectID;
        if (startDate && endDate) {
            var params = { 'startDate': startDate, 'endDate': endDate };
            return this.http.get(URL, { headers: this.headers, params: params });
        }
        return this.http.get(URL, { headers: this.headers });
    };
    EmployeeService.ngInjectableDef = i0.defineInjectable({ factory: function EmployeeService_Factory() { return new EmployeeService(i0.inject(i1.HttpClient)); }, token: EmployeeService, providedIn: "root" });
    return EmployeeService;
}());
export { EmployeeService };
