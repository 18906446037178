import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MonthlyCostTableForAccountantByEmployer} from '../../../models/monthlyCostTableForAccountantByEmployer';
import {environment} from '../../../environments/environment';
import {Invoice} from '../../../models/invoice';

@Injectable({
    providedIn: 'root'
})
export class InvoiceReportService {

    constructor(private http: HttpClient) {
    }

    private serverApi = environment.apiUrl;
    private serverPart = '/monthlyCostTableForAccountantByEmployer';
    private URL = this.serverApi + this.serverPart;
    private headers = new HttpHeaders().append('Content-Type', 'application/json');

    public getOpenInvoices() {
        const URL = this.URL + '/openInvoices';
        return this.http.get<MonthlyCostTableForAccountantByEmployer>(URL, {headers: this.headers});
    }

    public getPeriodWithYear(year: any) {
        const URL = this.URL + '/' + year;
        return this.http.get<MonthlyCostTableForAccountantByEmployer[]>(URL, {headers: this.headers});
    }

    public getPeriodWithDate(year: any, month: any) {
        const URL = this.URL + '/' + year + '/' + month;
        return this.http.get<MonthlyCostTableForAccountantByEmployer>(URL, {headers: this.headers});
    }

    public updatePeriod(invoice: any, index: any, oldInvoice: any) {
        const URL = this.URL + '/costAdd';
        return this.http.put(URL, {invoice: invoice, isUpdate: index, oldInvoice: oldInvoice}, {headers: this.headers});
    }

    public openNewPeriod() {
        const URL = this.URL + '/openNew';
        return this.http.put(URL, {headers: this.headers});
    }

    public deletePayment(id: any) {
        const URL = this.URL + '/paymentDelete/' + id;
        return this.http.delete(URL, {headers: this.headers});
    }

    public getAllInvoices(query: any) {
        const URL = this.URL + '/all-invoices';
        return this.http.get<Invoice[]>(URL, {headers: this.headers, params: query});
    }

    public getAllInvoicesSize(query: any) {
        const URL = this.URL + '/all-invoices/size';
        return this.http.get<{size: number}>(URL, {headers: this.headers, params: query});
    }

    public getAllPeriods(query: any) {
        const URL = this.URL + '/all-periods';
        return this.http.get<MonthlyCostTableForAccountantByEmployer[]>(URL, {headers: this.headers, params: query});
    }

}



